<template>
	<div>
		<v-snackbar v-model="snackbarState" :multi-line="true">
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="blue"
					text
					v-bind="attrs"
					@click="$emit('close')"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
export default {
	name: "NotificationComponent",
	props: { showSnackbar: Boolean, text: {}, timeout: {} },
	data: () => ({
		snackbarState: false,
	}),
	created() {},
	watch: {
		showSnackbar() {
			this.snackbarState = this.showSnackbar;
			if (this.timeout) {
				setTimeout(() => {
					this.$emit("close");
				}, this.timeout);
			}
		},
	},
};
</script>
<style lang="scss" scoped></style>

<template>
  <div
    class="padding-adjust"
    :class="{ fullScreen: getIsMobile, removeOverflow: currentStep !== 2 }"
  >
    <div class="d-flex align-center">
      <div class="arrow-left-wrap mr-5" @click="goBack">
        <v-img
          max-width="8"
          :src="require('../assets/img/ArrowLeft.svg')"
        ></v-img>
      </div>
      <div class="d-flex flex-wrap">
        <span
          class="text-h5 primaryA--text font-weight-bold"
          :class="{ 'text-subtitle-1': getIsMobile }"
          >{{ $t("newOrder.createOrder", { name: getStationName }) }}</span
        >
        <div
          v-if="currentStep > 0"
          class="header-title d-flex align-start justify-center"
        >
          <span
            class="text-h5 ml-1 primaryA--text font-weight-bold"
            :class="{ 'text-subtitle-1': getIsMobile }"
            >{{
              $t("newOrder.articleList", {
                supplier: order.supplier && order.supplier.name,
              })
            }}</span
          >
          <!-- <v-btn
            v-if="allSupplierNotes"
            class="mx-2"
            fab
            dark
            color="accent"
            height="20px"
            width="20px"
            @click="previewSupplieNotes"
          >
            <v-icon dark> mdi-information-outline </v-icon>
          </v-btn> -->
        </div>
      </div>
    </div>
    <div>
      <div>
        <stepper-component
          :step="currentStep"
          :allSteps="getAllSteps"
        ></stepper-component>
      </div>
      <div>
        <step-one-date-supplier
          v-if="currentStep === 0"
          @onStepChange="handleStepChange"
          :suppliers="suppliers"
          :step="currentStep"
          :allSteps="allSteps"
          :order="order"
          @onOrderUpdate="handleOrderUpdate"
          :isBasicPlan="isBasicPlan"
          :isTrialEnd="isTrialEnd"
          :isProOrFreeTrial="isProOrFreeTrial"
        ></step-one-date-supplier>
        <step-two-list
          v-if="currentStep === 1"
          @onStepChange="handleStepChange"
          :step="currentStep"
          :allSteps="allSteps"
          :order="order"
          @onOrderUpdate="handleOrderUpdate"
          @onSaveOrder="handleOnSaveOrder"
          :isBasicPlan="isBasicPlan"
          :isTrialEnd="isTrialEnd"
          :isProOrFreeTrial="isProOrFreeTrial"
          :station="station"
          @onAmountChange="handleOnAmountChange"
          :productRemovedFromCart="productRemovedFromCart"
          :productAmountAdjusted="productAmountAdjusted"
        ></step-two-list>
        <step-three-preview
          v-if="currentStep === 2"
          @onStepChange="handleStepChange"
          :step="currentStep"
          :allSteps="allSteps"
          :order="order"
          :venue="getVenue"
          @onOrderUpdate="handleOrderUpdate"
          @onSaveOrder="handleOnSaveOrder"
          :user="user"
          :isBasicPlan="isBasicPlan"
          :isTrialEnd="isTrialEnd"
          :isProOrFreeTrial="isProOrFreeTrial"
          :isSupplierMarket="isSupplierMarket"
          :station="station"
        >
        </step-three-preview>
        <cart-view-sheet
          v-if="!showWarningModal"
          :numberOfItemsInCart="numberOfItemsInCart"
          @onRemoveItemFromCart="handleOnAmountChange"
          @onAmountChangeInCart="handleOnAmountChangeInCart"
          :currentStep="currentStep"
        ></cart-view-sheet>
      </div>
    </div>
    <warning-modal
      :dialog="showWarningModal"
      @onClose="handleOnCloseWarningModal"
      :warninModalType="warninModalType"
      @onSavingOrder="handleSavingOrder"
      :departmentID="depID"
      :noValidOrder="noValidOrder"
    ></warning-modal>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
//components
import StepOneDateSupplier from "./NewOrderPages/StepOneDateSupplier.vue";
import StepTwoList from "./NewOrderPages/StepTwoList.vue";
import StepThreePreview from "./NewOrderPages/StepThreePreview.vue";
import StepperComponent from "../components/StepperComponent.vue";
import WarningModal from "../views/NewOrderPages/WarningModal.vue";
import CartViewSheet from "./NewOrderPages/components/CartViewSheet.vue";
//services
import LocalStorageService from "../services/local-storage.service";
import moment from "moment";
import { orderStatuses } from "../shared/statuses";

export default {
  name: "NewOrder",
  components: {
    StepOneDateSupplier,
    StepperComponent,
    StepTwoList,
    StepThreePreview,
    WarningModal,
    CartViewSheet,
  },
  props: {
    isBasicPlan: {},
    isTrialEnd: {},
    isProOrFreeTrial: {},
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    selectedSupplier: "",
    selectedDate: "",
    currentStep: 0,
    allSteps: [],
    order: {},
    selectedArticles: [],
    stationID: null,
    showWarningModal: false,
    warninModalType: "",
    depID: null,
    noValidOrder: false,
    numberOfItemsInCart: 0,
    showCartViewModal: false,
    productRemovedFromCart: null,
    productAmountAdjusted: null,
  }),
  created() {
    this.stationID = this.$route.params?.departmentID;
    this.$store.dispatch("supplier/getSuppliersForDepartment", {
      departmentId: this.stationID,
    });
    this.$store.dispatch("orders/getAllRegularOrders", {
      departmentId: this.stationID,
      page: 1,
      pageSize: 30,
      status: orderStatuses.IN_PROGRESS,
    });
    this.processDataFromLocalStorage();
  },
  mounted() {
    // this.$analytics.logEvent("page_view", { page_title: "New Order Page" });
    this.allSteps = [
      {
        id: 0,
        name: "date-supplier",
        active: true,
        finished: true,
      },
      {
        id: 1,
        name: "list",
        active: false,
        finished: false,
      },
      {
        id: 2,
        name: "finish",
        active: false,
        finished: false,
      },
    ];
    this.scrollToTop();
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.order?.supplier?.id &&
        this.$store.dispatch("userProfile/getSupplierNote", {
          supplierId: this.order?.supplier.id,
          venueId: this.getVenue.id,
        });
    });
  },
  beforeDestroy() {
    LocalStorageService.deleteLocalStorageItem("newOrderDraft");
    LocalStorageService.deleteLocalStorageItem("orderChanged");
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    goBackToDashboard() {
      this.warninModalType = "";
      this.depID = this.$route.params && this.$route.params.departmentID;
      this.noValidOrder =
        Object.keys(this.order).length === 0 || !this.order.articles;
      this.showWarningModal = true;
    },
    goBack() {
      if (this.currentStep === 0) {
        this.goBackToDashboard();
      } else {
        this.$set(this.allSteps[this.currentStep], "finished", false);
        this.currentStep -= 1;
      }
    },
    handleSelectedSupplier(value) {
      this.selectedSupplier = value;
    },
    handleSelectedDate(value) {
      this.selectedDate = value;
    },
    handleStepChange(step, goingBack) {
      this.currentStep = step;
      this.allSteps.forEach((s, index) => {
        if (this.currentStep >= index) {
          this.$set(s, "finished", true);
        }
      });
      if (goingBack) {
        this.$set(this.allSteps[step + 1], "finished", false);
      }
      this.$forceUpdate();
    },
    handleOrderUpdate(data) {
      Object.assign(this.order, data);
    },
    processDataFromLocalStorage() {
      let data = LocalStorageService.getLocalStorageData("newOrderDraft");
      if (data) {
        this.order = data.orderObj;
        this.allSteps = data.allSteps;
        this.currentStep = data.currentStep;
        this.numberOfItemsInCart = data.orderObj.articles?.length;
      }
      this.$forceUpdate();
    },
    handleSavingOrder() {
      let departmentID = this.$route.params && this.$route.params.departmentID;
      let utcOrderDate = new moment.utc(this.order.date).format();
      this.order.date = utcOrderDate;
      this.$store
        .dispatch(
          this.isSupplierMarket
            ? "orders/createCustomOrder"
            : "orders/createNewOrder",
          {
            departmentID: departmentID,
            order: this.order,
            orderNote: this.order.orderNote,
            paymentMethod: this.order.paymentMethod,
          }
        )
        .then(() => {
          LocalStorageService.deleteLocalStorageItem("newOrderDraft");

          this.isSupplierMarket
            ? this.$router.push(
                `/current-orders/${this.$route.params.departmentID}/custom-orders`
              )
            : this.$router.push(
                `/current-orders/${this.$route.params.departmentID}/orders`
              );
        });
    },
    handleOnCloseWarningModal() {
      this.showWarningModal = false;
    },
    handleOnSaveOrder() {
      this.warninModalType = "saving";
      this.showWarningModal = true;
    },
    handleOnAmountChange(product) {
      this.processDataFromLocalStorage();
      this.productRemovedFromCart = product;
    },
    onShowCartViewModal() {
      this.showCartViewModal = true;
    },
    handleOnAmountChangeInCart(product) {
      this.productAmountAdjusted = product;
    },
  },
  computed: {
    ...mapGetters({
      getSuppliers: "supplier/getDepartmentSuppliers",
      getVenue: "userProfile/getVenue",
      getUser: "auth/getUser",
      areSelectedArticlesCleared: "orders/getAreSelectedArticlesCleared",
    }),
    getAllSteps() {
      return this.allSteps;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    suppliers() {
      return this.getSuppliers;
    },
    user() {
      return this.getUser;
    },
    isSupplierMarket() {
      return this.order?.supplier.isMarket;
    },
    station() {
      return (
        this.stationID &&
        this.$attrs.userProfile?.departments?.find(
          (d) => d.id === this.stationID
        )
      );
    },
    getStationName() {
      return this.station?.name;
    },
    allSupplierNotes() {
      return this.getSelectedSupplierNote;
    },
    getAreSelectedArticlesCleared() {
      return this.areSelectedArticlesCleared;
    },
  },
  watch: {
    getAreSelectedArticlesCleared() {
      if (this.getAreSelectedArticlesCleared) {
        this.numberOfItemsInCart = 0;
        this.order.articles = [];
        this.$store.dispatch("orders/clearSelectedArticles", false);
      }
    },
    currentStep() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.arrow-left-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 40px;
  cursor: pointer;
}
.fullScreen {
  // position: absolute;
  top: 0;
  // height: 100vh;
  background: #ffffff;
  z-index: 99;
  width: 100vw;
  padding: 24px !important;
  max-height: 100vh;
}
.removeOverflow {
  overflow-y: hidden;
}
.hideOnMobile {
  @media screen and (max-width: 960px) {
    display: none !important;
  }
}
.hideOnBigScreens {
  @media screen and (min-width: 961px) {
    display: none !important;
  }
}

.close-icon {
  top: 12px;
  right: 12px;
}
.padding-adjust {
  padding: 0 32px !important;
  @media screen and (max-width: 959px) {
    padding: 8px !important;
  }
}
.cart-button {
  position: fixed;
  top: 90%;
}
.text-subtitle-1 {
  white-space: break-spaces;
}
</style>

<template>
  <div>
    <div class="pt-2">
      <div>
        <v-row v-for="station in stations" :key="station.id" width="100%">
          <v-col cols="12" sm="6" md="4">
            <v-card v-if="!station.isEdit" class="mx-auto" elevation="5">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    station.department.name
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex">
                    <v-btn icon @click="onEditStation(station)">
                      <v-icon color="primary lighten-1">mdi-pen</v-icon>
                    </v-btn>
                    <v-btn icon @click="onDeleteStation(station)">
                      <v-icon color="error lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-card>
            <v-text-field
              v-else
              v-model="station.department.name"
              :label="$t('dashboard.enterNewStationName')"
              filled
              dense
              :rules="rules.stationName"
              type="text"
              color="accent"
              requred
              @keyup="onCheckStationNameUp"
              @keydown="onCheckStationNameDown"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div v-if="station.isEdit">
              <v-progress-circular
                v-if="editStatus === 'REQUEST_PENDING'"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <div v-else>
                <v-btn
                  small
                  elevation="2"
                  color="primary"
                  :disabled="
                    sameStationNameViolated || !station.department.name
                  "
                  @click="updateStation(station)"
                  >{{ $t("common.save") }}</v-btn
                >
                <v-btn
                  class="ml-2"
                  small
                  elevation="2"
                  @click="cancelUpdateStation(station)"
                  >{{ $t("common.cancel") }}</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row class="mt-4">
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="stationName"
          :label="$t('dashboard.enterNewStationName')"
          filled
          dense
          :rules="rules.stationName"
          type="text"
          color="accent"
          requred
          @keyup="onCheckStationNameUp"
          @keydown="onCheckStationNameDown"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="save-button-wrap">
        <v-progress-circular
          v-if="createStationStatus === 'REQUEST_PENDING'"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn
          small
          v-else
          color="primary"
          :disabled="sameStationNameViolated || !stationName"
          @click="saveStation"
          >{{ $t("common.save") }}</v-btn
        >
      </v-col>
    </v-row>
    <simple-warning-modal
      :dialog="showDeleteWarningModal"
      @onClose="showDeleteWarningModal = false"
      @onConfirm="onConfirmDeleteStation"
      @onCancel="showDeleteWarningModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimpleWarningModal from "../../../components/SimpleWarningModal.vue";
export default {
  name: "WizardStepOneCreateStations",
  components: { SimpleWarningModal },
  data() {
    return {
      stationName: "",
      rules: {
        stationName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      typingTimer: null,
      doneTypingInterval: 500,
      sameStationNameViolated: false,
      showDeleteWarningModal: false,
      stationToDelete: null,
      warningModalTitle: this.$t("adminStations.deleteWarningModalMessage"),
    };
  },
  created() {},
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.$store.dispatch("stations/getAllStations", this.venueId);
    });
  },
  methods: {
    saveStation() {
      console.log("Station name: ", this.stationName);
      const stationData = {
        venueID: this.venueId,
        stationName: this.stationName,
      };
      this.$store.dispatch("stations/createStation", stationData).then(() => {
        this.$store.dispatch("stations/getAllStations", this.venueId);
        this.stationName = "";
        this.rules.stationName = [];
      });
    },
    updateStation(station) {
      const stationData = {
        departmentId: station.department.id,
        editStationName: station.department.name,
      };
      this.$store.dispatch("stations/editStation", stationData).then(() => {
        this.$store.dispatch("stations/getAllStations", this.venueId);
      });
    },
    cancelUpdateStation(stations) {
      this.$set(stations, "isEdit", false);
    },
    onCheckStationNameUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkStationNameNew,
        this.doneTypingInterval
      );
    },
    onCheckStationNameDown() {
      clearTimeout(this.typingTimer);
    },
    checkStationNameNew() {
      this.sameStationNameViolated =
        this.stations &&
        this.stations.filter(
          (f) =>
            f.department.name.toLowerCase() === this.stationName.toLowerCase()
        ).length > 0;
    },
    onEditStation(station) {
      this.$set(station, "isEdit", true);
    },
    onConfirmDeleteStation() {
      this.$store
        .dispatch("stations/deleteStation", this.stationToDelete)
        .then(() => {
          this.showDeleteWarningModal = false;
          this.$store.dispatch("stations/getAllStations", this.venueId);
          this.stationName = "";
          this.rules.stationName = [];
          this.stationToDelete = null;
          this.showDeleteWarningModal = false;
        });
    },
    onDeleteStation(station) {
      this.stationToDelete = station.department;
      this.showDeleteWarningModal = true;
    },
  },
  computed: {
    ...mapGetters({
      getStationsStatus: "stations/getCreateNewStationStatus",
      allVenueStations: "stations/getAllVenueStations",
      getVenue: "userProfile/getVenue",
      editStationStatus: "stations/getEditStationStatus",
    }),
    stations() {
      return this.allVenueStations;
    },
    venueId() {
      return this.getVenue.id;
    },
    createStationStatus() {
      return this.getStationsStatus;
    },
    editStatus() {
      return this.editStationStatus;
    },
  },
  watch: {
    sameStationNameViolated() {
      if (this.sameStationNameViolated) {
        this.rules.stationName = [this.$t("errors.sameStations")];
      } else {
        this.rules.stationName = [];
      }
    },
    stations() {
      if (this.stations.length > 0) {
        this.$emit("onStepIsValid", 1, true);
      } else {
        this.$emit("onStepIsValid", 1, false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.save-button-wrap {
  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}
</style>

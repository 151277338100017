<template>
  <div class="root-noresults d-flex align-items-custom justify-center">
    <div class="d-flex align-center justify-center flex-column" style="text-align: center;">
      <span class="text-subtitle-1 primaryDark--text mt-7">{{
        getNoText
      }}</span>
      <div
        v-if="isArticlesPage && isAdmin"
        class="d-flex flex-column justify-center"
      >
        <span>{{ $t("articles.requestForArticleCreation") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NoResultsContent",
  components: {},
  props: {
    tabName: {},
    noResultsText: {},
    page: {},
    supplierId: {},
    departmentId: {},
    requestedArticleName: {},
    isFavoriteOnlySearch: {},
    isSearchInitiated: {},
    isMarketSupplier: {},
    isAdmin: {},
  },
  data: () => ({}),
  created() {},
  methods: {},
  computed: {
    getNoText() {
      switch (this.page) {
        case "orders":
          return this.$t("currentOrders.noOrders");

        case "articles":
        case "stepTwoList":
          return this.isSearchInitiated
            ? this.$t("articles.noArticles")
            : this.$t("articles.noArticlesCreated");
        default:
          return this.noResultsText;
      }
    },
    isArticlesPage() {
      return (
        (this.page === "articles" || this.page === "stepTwoList") &&
        !this.isFavoriteOnlySearch &&
        !this.isMarketSupplier
      );
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.root-noresults {
  // height: calc(100vh - 550px);
  justify-content: center;
  // position: absolute;
  width: 100%;
}
.align-items-custom {
  // align-items: center;
  // @media screen and (max-width: 959px) {
  //   align-items: flex-start !important;
  // }
}
</style>

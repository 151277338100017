<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">{{
            $t("common.createSupplier")
          }}</span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pt-6 create-supplier-modal-wizard">
        <v-stepper v-model="step" style="box-shadow: none !important">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              {{ $t("supplierCreationWizard.createSupplier") }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2" :editable="step > 2">
              {{ $t("supplierCreationWizard.linkSupplierWithStation") }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" :editable="step > 3">
              {{ $t("supplierCreationWizard.createArticlesForSupplier") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" class="stepper-content">
              <!-- create supplier step -->
              <div class="mt-4 stepper-list">
                <v-text-field
                  v-model="supplierName"
                  :label="$t('dashboard.enterSupplierName')"
                  outlined
                  dense
                  :rules="rules.supplierName"
                  type="text"
                  color="accent"
                  requred
                ></v-text-field>
                <v-text-field
                  class="mt-2"
                  v-model="supplierPib"
                  :label="$t('dashboard.enterSupplierPib')"
                  outlined
                  dense
                  color="accent"
                  :rules="rules.supplierPib"
                  :type="getIsMobile ? 'tel' : 'number'"
                  hide-spin-buttons
                ></v-text-field>
              </div>
              <v-progress-circular
                v-if="createUnverifiedSupplierStatus === 'REQUEST_PENDING'"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-btn
                v-else
                color="primary"
                @click="onCreateSupplierStepContinue"
                :disabled="isCreateNewSupplierActionDisabled"
              >
                {{ $t("common.save") }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2" class="stepper-content">
              <!-- linking supplier with stations -->
              <v-alert
                dense
                border="top"
                colored-border
                type="info"
                elevation="2"
                style="text-wrap: pretty"
              >
                {{
                  $t("unverifiedCreateArticle.linkingSupplierWithStationAlert")
                }}
              </v-alert>
              <v-list-item class="mt-4">
                <v-list-item-content>
                  <span class="font-weight-bold"
                    >{{ $t("common.stations") }}:</span
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="stepper-list">
                <v-list-item-content>
                  <v-list two-line>
                    <v-list-item-group multiple>
                      <template v-for="department in allDepartments">
                        <v-list-item
                          :class="{
                            'supplier-selected': isSupplierSelected(
                              createdSupplier.id,
                              department
                            ),
                            'supplier-unselected': !isSupplierSelected(
                              createdSupplier.id,
                              department
                            ),
                          }"
                          :key="department.name"
                          @change="
                            onSupplierSelected(createdSupplier.id, department)
                          "
                        >
                          <template>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="department.department.name"
                              ></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-icon
                                v-if="
                                  !isSupplierSelected(
                                    createdSupplier.id,
                                    department
                                  )
                                "
                                color="grey lighten-1"
                              >
                                mdi-star-outline
                              </v-icon>

                              <v-icon v-else color="yellow darken-3">
                                mdi-star
                              </v-icon>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-list-item-content>
              </v-list-item>

              <v-btn
                color="primary"
                @click="step = 3"
                :disabled="!isAnyStationLinkedWithAddedSupplier()"
              >
                {{ $t("common.next") }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3" class="stepper-content">
              <!-- article adding part -->
              <div class="d-flex justify-center width-100">
                <v-tabs centered @change="onTabChange" height="30px">
                  <v-tab>{{
                    $t("unverifiedCreateArticle.createArticles")
                  }}</v-tab>
                  <v-tab>{{
                    $t("unverifiedCreateArticle.importArticles")
                  }}</v-tab>
                </v-tabs>
              </div>

              <div v-if="selectedTab === 0">
                <v-alert
                  dense
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                  class="mt-4"
                >
                  {{
                    $t("unverifiedCreateArticle.createArticleModalAlertInfo")
                  }}
                </v-alert>
                <div v-if="supplierWithArticles" class="stepper-list mt-4">
                  <div>
                    <p class="font-weight-bold">
                      {{ $t("common.listOfArticles") }}
                      {{ supplierWithArticles.name }}
                    </p>
                  </div>
                  <div>
                    <div class="articles-list">
                      <v-data-table
                        mobile-breakpoint="400"
                        hide-default-footer
                        :headers="headers"
                        :items="supplierWithArticles.articles"
                        class="elevation-5"
                        no-data-text=""
                      >
                        <template v-slot:item.article_name="{ item }">
                          <span v-if="!item.isEdit">{{
                            item.articleName
                          }}</span>
                          <v-text-field
                            v-else
                            dense
                            name="name"
                            id="name"
                            type="text"
                            :placeholder="$t('editArticle.name')"
                            :label="$t('editArticle.name')"
                            color="accent"
                            required
                            :rules="rules.articleNameUpdate"
                            v-model="item.updatedArticleName"
                            @keyup="
                              onCheckUpdatedArticleNameUp(
                                supplierWithArticles,
                                item
                              )
                            "
                            @keydown="onCheckUpdatedArticleNameDown"
                          ></v-text-field>
                        </template>
                        <template v-slot:item.article_category="{ item }">
                          <span v-if="!item.isEdit">
                            {{ item.category && item.category.name }}
                          </span>
                          <v-select
                            v-else
                            :label="$t('common.category')"
                            v-model="item.category"
                            :items="allArticlesCategories"
                            :rules="rules.articleCategory"
                            item-text="category"
                            item-value="id"
                            dense
                            color="accent"
                            required
                          ></v-select>
                        </template>
                        <template v-slot:item.article_actions="{ item }">
                          <div v-if="!item.isEdit">
                            <v-btn
                              icon
                              @click="onEditArticle(supplierWithArticles, item)"
                            >
                              <v-icon color="primary lighten-1">mdi-pen</v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              @click="
                                onDeleteArticle(supplierWithArticles, item)
                              "
                            >
                              <v-icon color="error lighten-1"
                                >mdi-delete</v-icon
                              >
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn
                              icon
                              :disabled="
                                sameArticleNameViolatedForUpdate ||
                                !item.articleName ||
                                !item.category
                              "
                              @click="updateArticle(item, supplierWithArticles)"
                            >
                              <v-icon color="primary lighten-1"
                                >mdi-check</v-icon
                              >
                            </v-btn>
                            <v-btn
                              icon
                              @click="
                                onCancelUpdateArticle(
                                  supplierWithArticles,
                                  item
                                )
                              "
                            >
                              <v-icon color="error lighten-1">mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                    <v-divider
                      v-if="supplierWithArticles.articles.length"
                    ></v-divider>
                    <div class="add-new-article-row">
                      <div
                        class="d-flex width-100 justify-between align-center"
                      >
                        <v-text-field
                          full-width
                          v-model="newArticleName"
                          :label="$t('dashboard.addArticleName')"
                          dense
                          :rules="rules.articleName"
                          type="text"
                          color="accent"
                          requred
                          class="mr-2"
                        ></v-text-field>
                        <v-select
                          full-width
                          :label="$t('common.category')"
                          v-model="newArticleCategory"
                          :items="allArticlesCategories"
                          item-text="category"
                          item-value="id"
                          :rules="rules.articleCategory"
                          dense
                          color="accent"
                          required
                        ></v-select>
                        <v-progress-circular
                          v-if="createArticleStatus === 'REQUEST_PENDING'"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                        <v-btn
                          v-else
                          icon
                          @click="saveSupplerArticle()"
                          :disabled="
                            sameArticleNameViolated ||
                            newArticleName === '' ||
                            newArticleCategory === null
                          "
                        >
                          <v-icon color="primary lighten-1"
                            >mdi-plus-box</v-icon
                          >
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <import-articles
                v-else
                :selectedSupplierId="createdSupplier.id"
              ></import-articles>
              <div>
                <v-btn class="mt-6" color="primary" @click="onComplete">
                  {{ $t("common.complete") }}
                </v-btn>

                <v-btn class="mt-6" text @click="goToStep(2)">
                  {{ $t("common.back") }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
    <simple-warning-modal
      :dialog="showDeleteWarningModal"
      @onClose="showDeleteWarningModal = false"
      @onConfirm="onConfirmDeleteArticle"
      @onCancel="showDeleteWarningModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import SimpleWarningModal from "@/components/SimpleWarningModal.vue";
import ImportArticles from "./components/ImportArticles.vue";

export default {
  name: "CreateSupplierUnverifiedModal",
  components: { SimpleWarningModal, ImportArticles },
  props: {
    dialog: {},
  },
  data() {
    return {
      showDialog: false,
      step: 1,
      supplierName: "",
      supplierPib: "",
      createdSupplier: {
        id: "068627a2-24dc-426a-a75e-f57ead8f1cf4",
        name: "AAaAA",
      },
      sameSupplierNameViolated: false,
      sameSupplierPibViolated: false,
      rules: {
        supplierName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
          (v) =>
            !this.isExistingSupplierName(v) || this.$t("errors.sameSupplier"),
        ],
        supplierPib: [
          (v) =>
            v === "" ||
            (v.length >= 9 && v.length <= 13) ||
            this.$t("common.pibConstraint"),
          (v) =>
            v === "" ||
            !this.isExistingSupplierPib(v) ||
            this.$t("errors.sameSupplierPib"),
        ],
        articleName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        articleNameUpdate: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        articleCategory: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      typingTimer: null,
      doneTypingInterval: 500,
      articleToDelete: null,
      supplierArticleToDelete: null,
      showDeleteWarningModal: false,
      warningModalTitle: this.$t(
        "adminStations.deleteArticleWarningModalMessage"
      ),
      sameArticleNameViolated: false,
      sameArticleNameViolatedForUpdate: false,
      newArticleName: "",
      newArticleCategory: null,
      selectedTab: 0,
      headers: [
        {
          text: this.$t("articles.name"),
          value: "article_name",
          sortable: false,
        },
        {
          text: this.$t("articles.category"),
          value: "article_category",
          sortable: false,
        },
        {
          text: this.$t("common.actions"),
          value: "article_actions",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.showDialog = this.dialog;
  },
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
      this.$store.dispatch(
        "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
      );
      this.$store.dispatch("unverifiedSupplier/getAllArticleCategories");
    });
  },
  methods: {
    onTabChange(event) {
      this.selectedTab = event;
    },
    isExistingSupplierPib(pib) {
      return this.suppliers.some((supplier) => supplier.pib === pib);
    },
    isExistingSupplierName(supplierName) {
      return this.suppliers.some(
        (supplier) => supplier.name.toLowerCase() === supplierName.toLowerCase()
      );
    },
    closeDialog() {
      this.$emit("onClose");
    },
    goToStep(stepNumber) {
      this.step = stepNumber;
    },
    onCreateSupplierStepContinue() {
      this.$store
        .dispatch("unverifiedSupplier/createUnverifiedSupplier", {
          name: this.supplierName,
          pib: this.supplierPib,
        })
        .then((response) => {
          console.log("12312321132", response);
          this.createdSupplier = response.data;
          this.supplierName = "";
          this.supplierPib = "";
          this.goToStep(2);
        });
    },
    onSupplierSelected(supplierId, department) {
      if (this.isSupplierSelected(supplierId, department)) {
        this.$store
          .dispatch("unverifiedSupplier/unlinkUnverifiedSupplier", {
            supplierId: supplierId,
            departmentId: department.department.id,
          })
          .then(() => {
            this.$store.dispatch(
              "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
            );
          });
        return;
      }

      this.$store
        .dispatch("unverifiedSupplier/linkUnverifiedSupplier", {
          supplierId: supplierId,
          departmentId: department.department.id,
        })
        .then(() => {
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
        });
    },
    isSupplierSelected(supplierId, departmentWithSupplier) {
      return departmentWithSupplier.suppliers.some(
        (supplier) => supplier.id === supplierId
      );
    },
    onCheckArticleNameUp(articleName) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkArticleName(articleName),
        this.doneTypingInterval
      );
    },
    onCheckArticleNameDown() {
      clearTimeout(this.typingTimer);
    },
    checkArticleName(articleName) {
      this.sameArticleNameViolated = this.supplierWithArticles.articles.some(
        (supplierArticle) => {
          return (
            supplierArticle.articleName.toLowerCase() ===
            articleName.toLowerCase()
          );
        }
      );
    },
    onCheckUpdatedArticleNameUp(supplier, article) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkUpdatedArticleName(supplier, article),
        this.doneTypingInterval
      );
    },
    onCheckUpdatedArticleNameDown() {
      clearTimeout(this.typingTimer);
    },
    checkUpdatedArticleName(supplier, article) {
      this.sameArticleNameViolatedForUpdate = supplier.articles.some(
        (supplierArticle) => {
          return (
            supplierArticle.articleName.toLowerCase() ===
            article.updatedArticleName.toLowerCase()
          );
        }
      );
    },
    saveSupplerArticle() {
      const category = this.allArticlesCategories.find(
        (category) => category.id === this.newArticleCategory
      );
      this.$store
        .dispatch("unverifiedSupplier/createArticleForSupplier", {
          supplierId: this.supplierWithArticles.id,
          articleName: this.newArticleName,
          articleCategory: category,
        })
        .then(() => {
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
          this.newArticleCategory = null;
          this.newArticleName = "";
          this.rules.articleName = [];
          this.rules.articleCategory = [];
        });
    },
    onEditArticle(supplier, article) {
      this.$set(article, "isEdit", true);
      this.$set(article, "fallBackName", article.articleName);
      this.$set(article, "updatedArticleName", article.articleName);
      supplier.articles.forEach((supplierArticle) => {
        if (supplierArticle.id !== article.id) {
          this.$set(supplierArticle, "isEdit", false);
        }
      });
    },
    onCancelUpdateArticle(supplier, article) {
      this.$set(article, "articleName", article.fallBackName);
      this.$set(article, "isEdit", false);
    },
    onDeleteArticle(supplier, article) {
      this.articleToDelete = article;
      this.supplierArticleToDelete = supplier;
      this.showDeleteWarningModal = true;
    },
    isAnyStationLinkedWithAddedSupplier() {
      return this.allDepartmentsWithSuppliers.some((department) =>
        department.suppliers.some(
          (supplier) => supplier.id === this.createdSupplier.id
        )
      );
    },
    onConfirmDeleteArticle() {
      this.$store
        .dispatch("unverifiedSupplier/deleteSupplierArticle", {
          supplierId: this.supplierArticleToDelete.id,
          articleId: this.articleToDelete.id,
        })
        .then(() => {
          this.showDeleteWarningModal = false;
          this.articleToDelete = null;
          this.supplierArticleToDelete = null;
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
        });
    },
    updateArticle(article, supplier) {
      const categoryId = article.category.id
        ? article.category.id
        : article.category;
      const category = this.allArticlesCategories.find(
        (category) => category.id === categoryId
      );
      this.$store
        .dispatch("unverifiedSupplier/updateArticleForSupplier", {
          supplierId: supplier.id,
          articleId: article.id,
          name: article.updatedArticleName,
          category: category,
        })
        .then(() => {
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
          this.$set(article, "isEdit", false);
        });
    },
    onComplete() {
      this.$emit("onCompleteSupplierCreation");
    },
  },
  computed: {
    ...mapGetters({
      allUnverifiedSuppliers: "unverifiedSupplier/getUnverifiedSuppliers",
      createUnverifiedSupplierStatus:
        "unverifiedSupplier/createUnverifiedSupplierStatus",
      allDepartmentsWithSuppliers:
        "unverifiedSupplier/getAllDepartmentsWithSuppliers",
      getAllCategories: "unverifiedSupplier/getAllCategories",
      createArticleStatus:
        "unverifiedSupplier/getCreateArticleForSupplierStatus",
    }),
    allDepartments() {
      return this.allDepartmentsWithSuppliers;
    },
    suppliers() {
      return this.allUnverifiedSuppliers;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    supplierWithArticles() {
      const allSuppliers = this.allDepartmentsWithSuppliers.flatMap(
        (department) => department.suppliers
      );
      return allSuppliers.find((sup) => sup.id === this.createdSupplier.id);
    },
    allArticlesCategories() {
      return this.getAllCategories;
    },
    isCreateNewSupplierActionDisabled() {
      return (
        !this.supplierName ||
        this.sameSupplierNameViolated ||
        (this.supplierPib.length
          ? this.sameSupplierPibViolated ||
            this.supplierPib.length < 9 ||
            this.supplierPib.length > 13
          : false)
      );
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },

    step() {
      if (this.step === 2) {
        this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
      }
    },
    supplierName() {
      this.sameSupplierNameViolated = this.isExistingSupplierName(
        this.supplierName
      );
    },
    supplierPib() {
      this.sameSupplierPibViolated = this.isExistingSupplierPib(
        this.supplierPib
      );
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  // padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.close-icon {
  cursor: pointer;
}
.stepper-content {
  width: 100%;
  margin-top: 8px;
}

.supplier-selected {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
  background-color: #1976d232;
}
.supplier-selected::before {
  opacity: 0;
}

.supplier-unselected {
  color: #000000 !important;
  caret-color: #000000 !important;
  background-color: unset;
}

.supplier-unselected::before {
  opacity: 0;
}

.stepper-list {
  // min-height: 250px;
}
.articles-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPage from "../views/DashboardPage.vue";
import LoginPage from "../views/LoginPage.vue";
import SignUpPage from "../views/SignUpPage.vue";
import ProfilePage from "../views/UserProfile/ProfilePage.vue";
import SuppliersPage from "../views/SuppliersPage.vue";
// import ArticlesPage from "../views/Articles/ArticlesPage.vue";
import ArticlesDashboard from "../views/Articles/ArticlesDashboard.vue";
import ArchivedOrders from "../views/ArchivedOrders.vue";
import NewOrder from "../views/NewOrder.vue";
import EditOrder from "../views/EditOrder.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
//admin
import AdminDashboard from "../views/Admin/AdminDashboard.vue";
import AdminUsers from "../views/Admin/AdminUsers.vue";
import AdminRestaurants from "../views/Admin/AdminRestaurants.vue";
import AdminRestaurant from "../views/Admin/AdminRestaurant.vue";
import AdminSuppliers from "../views/Admin/AdminSuppliers.vue";
import AdminStations from "../views/Admin/AdminStations.vue";
import AdminArticles from "../views/Admin/AdminArticles.vue";
import AdminReports from "../views/Admin/AdminReports.vue";
//Suppier
import SupplierDashboard from "../views/Supplier/SupplierDashboard.vue";
import SupplierSingleOrder from "../views/Supplier/SupplierSingleOrder.vue";
//store
import store from "../store/index";
//services
import TokenService from "../services/token.service";
//invites
import AcceptInvite from "../views/Invites/AcceptInvite";
//verify
import VerifyEmail from "../views/Verify/VerifyEmail";
import { REQUEST_SUCCESS } from "../shared/statuses";
//help
import HelpPage from "../views/Help/HelpPage";
import SingleHelpArticle from "../views/Help/SingleHelpArticle";
import HelpSearchAndCategories from "../views/Help/HelpSearchAndCategories";
//orders
import CurrentOrdersContainer from "../views/CurrentOrders/CurrentOrdersContainer";
import CurrentRegularOrders from "../views/CurrentOrders/CurrentRegularOrders";
import CurrentCustomOrders from "../views/CurrentOrders/CurrentCustomOrders";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "LoginPage",
      component: LoginPage,
    },
    {
      path: "/sign-up",
      name: "SignUpPage",
      component: SignUpPage,
    },
    {
      path: "/",
      name: "DashboardPage",
      component: DashboardPage,
    },
    {
      path: "/profile",
      name: "ProfilePage",
      component: ProfilePage,
    },
    {
      path: "/current-orders",
      name: "CurrentOrdersContainer",
      component: CurrentOrdersContainer,
      children: [
        {
          path: ":departmentID/orders",
          name: "CurrentRegularOrders",
          component: CurrentRegularOrders,
        },
        {
          path: ":departmentID/custom-orders",
          name: "CurrentCustomOrders",
          component: CurrentCustomOrders,
        },
      ],
    },
    {
      path: "/suppliers",
      name: "SuppliersPage",
      component: SuppliersPage,
    },
    {
      path: "/archived",
      name: "ArchivedOrders",
      component: ArchivedOrders,
    },
    {
      path: "/:departmentID/new-order",
      name: "NewOrder",
      component: NewOrder,
    },
    {
      path: "/:departmentID/new-custom-order",
      name: "NewOrder",
      component: NewOrder,
    },
    {
      path: "/:departmentID/edit-order/:orderID",
      name: "EditOrder",
      component: EditOrder,
    },
    {
      path: "/articles",
      name: "ArticlesDashboard",
      component: ArticlesDashboard,
    },
    // {
    //   path: "/articles",
    //   name: "ArticlesPage",
    //   component: ArticlesPage,
    // },
    {
      path: "/admin",
      name: "AdminDashboard",
      component: AdminDashboard,
      children: [
        {
          path: "users",
          name: "AdminUsers",
          component: AdminUsers,
        },
        {
          path: "restaurants",
          name: "AdminRestaurants",
          component: AdminRestaurants,
        },
        {
          path: ":id/restaurant",
          name: "AdminRestaurant",
          component: AdminRestaurant,
        },
        {
          path: "suppliers",
          name: "AdminSuppliers",
          component: AdminSuppliers,
        },
        {
          path: ":id/stations",
          name: "AdminStations",
          component: AdminStations,
        },
        {
          path: "articles",
          name: "AdminArticles",
          component: AdminArticles,
        },
        {
          path: "reports",
          name: "AdminReports",
          component: AdminReports,
        },
      ],
    },
    {
      path: "/:restaurantID/supplier",
      name: "SupplierDashboard",
      component: SupplierDashboard,
    },
    {
      path: "/:restaurantId/supplier-demo/:orderId",
      name: "SupplierSingleOrder",
      component: SupplierSingleOrder,
    },
    {
      path: "/resetPassword",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/acceptInvite",
      name: "AcceptInvite",
      component: AcceptInvite,
    },
    {
      path: "/verify/email",
      name: "VerifyEmail",
      component: VerifyEmail,
    },
    {
      path: "/help",
      name: "Help",
      component: HelpPage,
      children: [
        {
          path: "/",
          name: "HelpSearchAndCategories",
          component: HelpSearchAndCategories,
        },
        {
          path: "/help/:id",
          name: "SingleHelpArticle",
          component: SingleHelpArticle,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let userLoggedIn = store.getters["auth/isUserAuthenticated"];
  let userSuperAdmin = store.getters["auth/isUserSuperAdmin"];
  let userAdmin = store.getters["auth/isUserAdmin"];
  let isUserVerified;
  let venueDisabled;
  let userProfileStatus;

  setTimeout(() => {
    venueDisabled = store.getters["userProfile/getIsVenueDisabled"];
    isUserVerified = store.getters["userProfile/getIsUserVerified"];
    userProfileStatus = store.getters["userProfile/getUserProfileStatus"];
    const isAcceptInvite = to.path == "/acceptInvite";
    const isTrialPeriodEnded = store.getters["subscription/trialPlanEnded"];
    const isFreePlan = store.getters["subscription/isFreePlan"];

    if (
      userProfileStatus === REQUEST_SUCCESS &&
      (venueDisabled || (!isUserVerified && !isAcceptInvite)) &&
      to &&
      to.path !== "/sign-up" &&
      !to.path.includes("verify") &&
      to.path !== "/resetPassword"
    ) {
      store.dispatch("auth/logout");
      TokenService.removeUser();
      localStorage.setItem("venueDisabled", true);
      next({ name: "LoginPage" });
    }
    if (to && to.path && isFreePlan && isTrialPeriodEnded && !userSuperAdmin) {
      next({ name: "DashboardPage" });
    }
  }, 500);

  if (to && to.path === "/logout") {
    TokenService.removeUser();
    next({ name: "LoginPage" });
    window.location.reload();
  }

  if (
    to &&
    to.path !== "/login" &&
    to.path !== "/sign-up" &&
    !to.path.includes("supplier-demo") &&
    !to.path.includes("verify/email") &&
    to.path !== "/resetPassword" &&
    to.path !== "/acceptInvite" &&
    !userLoggedIn
  ) {
    next("/login");
  }
  //ovo je kad se super admin loguje da ga baci automatski na admin restorani stranu
  if (
    to &&
    to.path &&
    !to.path.includes("admin") &&
    userLoggedIn &&
    userSuperAdmin
  ) {
    next("/admin/restaurants");
  }
  if (
    to &&
    to.path &&
    to.path.includes("admin") &&
    userLoggedIn &&
    !userSuperAdmin &&
    !userAdmin
  ) {
    next("/");
  }

  next();
});

router.afterEach((to, from) => {
  // This will be called after each route change
  window.scrollTo(0, 0); // Scroll to top of page
  Vue.nextTick(() => {
    document.title = to.meta.title || "SupplyMe"; // Set title
    // Vue.$ga.page({
    //   page: to.path,
    //   title: to.meta.title || "SupplyMe",
    // });
  });
});

export default router;

<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="626"
      class="dialog-style"
      persistent
      :class="{ 'full-height-dialog': getIsMobile }"
      :fullscreen="getIsMobile"
    >
      <div class="dialog-style">
        <div class="header">
          <div class="d-flex justify-space-between align-center">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("receiveOrderDialog.title", {
                category: orderReceiveObject.category,
                supplier: orderReceiveObject.isCustomOrder
                  ? $t("articles.market")
                  : orderReceiveObject.supplier
                  ? orderReceiveObject.supplier.name
                  : $t("articles.market"),
              })
            }}</span>
            <v-img
              @click="closeDialog"
              class="close-icon"
              max-width="20px"
              :src="require('../assets/img/CloseIcon.svg')"
            >
            </v-img>
          </div>
          <div class="d-flex justify-space-between align-center flex-wrap mb-6">
            <div>
              <span class="accent--text text-subtitle-1 font-weight-bold"
                >{{ $t("receiveOrderDialog.expectedOrderDate") }}:</span
              >
              <span class="accent--text text-subtitle-1 font-weight-bold">
                {{ orderReceiveObject.deadline | moment("DD.MM.YYYY") }}
              </span>
            </div>
            <div>
              <span class="accent--text text-subtitle-1 font-weight-bold"
                >{{ $t("common.orderNumber") }}:</span
              >
              <span class="accent--text text-subtitle-1 font-weight-bold">
                {{
                  orderReceiveObject.order_number &&
                  orderReceiveObject.order_number.toUpperCase()
                }}
              </span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <div class="pt-5" v-for="article in orderProducts" :key="article.id">
            <div
              v-if="!article.receiveNoteActive"
              class="d-flex align-center"
              style="cursor: pointer"
              @click="onArticleReceiveNoteSelected(article)"
            >
              <span
                class="text-subtitle-1 primaryA--text font-weight-bold mr-2"
                >{{ article.name }}</span
              >

              <span class="ml-1 text-subtitle-2 secondaryDark--text"
                >x {{ article.amount }}</span
              >
              <div class="mr-5">
                <v-icon v-if="article.receiveNote">mdi mdi-note</v-icon>
              </div>
              <tooltip-component
                v-if="!article.receiveNote"
                :icon="'info'"
                :color="'black'"
                :text="$t('receiveOrderDialog.receiveNoteInfo')"
                :width="'150px'"
              ></tooltip-component>
            </div>

            <div v-else-if="article.receiveNoteActive">
              <v-text-field
                class="item-note-width"
                :id="`note-${article.id}`"
                name="note"
                type="text"
                v-model="article.receiveNote"
                :label="$t('receiveOrderDialog.receivingItemNote')"
                dense
                :append-outer-icon="
                  article.receiveNote ? 'mdi-content-save' : 'mdi-content-save'
                "
                :append-icon="article.receiveNote ? 'mdi-close' : ''"
                @click:append-outer="onCloseEditReceiveNote(article)"
                @click:append="clearItemReceiveNote(article)"
                @keyup="onItemReceiveNoteChangeUp($event, article)"
                @keydown="onItemNoteChangeDown"
                :placeholder="$t('receiveOrderDialog.enterReceivingItemNote')"
              ></v-text-field>
            </div>

            <span
              v-if="article.note"
              :class="{ 'dim-content': article.receiveNoteActive }"
            >
              {{ $t("currentOrders.orderNote") }}: {{ article.note }}</span
            >
            <div :class="{ 'dim-content': article.receiveNoteActive }">
              <!-- for regular screens -->
              <v-radio-group
                class="d-flex"
                v-model="article.status"
                row
                @change="resolutionChange($event, article)"
                :disabled="article.move_to_order_id !== null"
              >
                <v-radio
                  :label="$t('receiveOrderDialog.arrived')"
                  value="DELIVERED"
                ></v-radio>
                <v-radio
                  :label="$t('receiveOrderDialog.notArrived')"
                  value="NOT_DELIVERED"
                ></v-radio>
                <v-radio
                  :label="$t('receiveOrderDialog.partiallyArrived')"
                  value="PARTIALLY_ARRIVED"
                ></v-radio>
              </v-radio-group>
            </div>
            <div
              v-if="
                article.orderResolutionStatus === 'NOT_DELIVERED' &&
                !orderReceiveObject.isCustomOrder
              "
              class="d-flex"
            >
              <v-select
                :value="article.notDeliveredReason"
                class="select-width"
                :items="resolutionOptions"
                item-value="value"
                item-text="text"
                outlined
                filled
                color="accent"
                :label="$t('receiveOrderDialog.chooseOption')"
                persistent-hint
                dense
                @change="onResolutionSelected($event, article)"
                :disabled="article.move_to_order_id !== null || isBasicPlan"
              ></v-select>
              <tooltip-component
                v-if="isBasicPlan"
                :icon="'info'"
                :color="'accent'"
                :text="$t('subscriptions.onlyProPackageConstraint')"
                :className="'mt-2 ml-2'"
              ></tooltip-component>
            </div>
            <div
              v-if="article.orderResolutionStatus === 'PARTIALLY_ARRIVED'"
              class="d-flex"
            >
              <v-text-field
                class="select-width"
                name="qtyArrived"
                :label="$t('receiveOrderDialog.amountDelivered')"
                id="qtyArrived"
                type="number"
                :placeholder="$t('receiveOrderDialog.enterDeliveredAmount')"
                outlined
                dense
                color="accent"
                v-model="article.deliveredAmount"
                @keydown="nameKeydown($event)"
                @input="validateInput($event, article)"
                :rules="article.amountError"
                width="200px"
              ></v-text-field>
              <tooltip-component
                :icon="'info'"
                :color="'accent'"
                :text="$t('receiveOrderDialog.deliveredAmountTooltip')"
                :className="'mt-2 ml-2'"
              ></tooltip-component>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
        <div
          v-if="orderReceiveObject.orderNote"
          class="d-flex flex-column align-start mt-3"
        >
          <span class="text-subtitle-2 primaryA--text"
            >{{ $t("previewSupplierNotesModal.additionalNote") }}:</span
          >
          <span class="text-subtitle-2 secondaryDark2--text">{{
            orderReceiveObject.orderNote
          }}</span>
        </div>
        <v-divider
          class="mt-4 mb-4 border-opacity-100"
          v-if="orderReceiveObject.orderNote"
        ></v-divider>
        <v-checkbox
          v-model="orderReceiveObject.showOrderRecevingNote"
          :label="$t('receiveOrderDialog.receivingOrderNote')"
        ></v-checkbox>
        <v-textarea
          v-if="orderReceiveObject.showOrderRecevingNote"
          id="orderRecevingNote"
          no-resize
          filled
          name="text-msg"
          :placeholder="$t('receiveOrderDialog.enterReceivingOrderNote')"
          v-model="orderReceiveObject.orderRecevingNote"
          rows="2"
        ></v-textarea>
        <div>
          <div class="d-flex mt-4 justify-space-between align-center flex-wrap">
            <div class="d-flex mobile-arrange-buttons">
              <v-btn
                color="tertiaryDark"
                @click="onAllArticlesArrived"
                :disabled="
                  isAnyArticlePartiallyArrived || isAnyArticleNotDelivered
                "
                >{{ $t("receiveOrderDialog.allArticlesArrived") }}</v-btn
              >
              <v-btn
                class="ml-4"
                :disabled="
                  !isFormValid && !allItemsPartiallyArrivedHaveReceivedAmount
                "
                :color="someOrdersNotArriveOrArriveLater ? 'warning' : 'accent'"
                @click="resolveOrder"
                >{{ $t("receiveOrderDialog.acceptOrder") }}</v-btn
              >
            </div>
            <div class="d-flex mobile-arrange-buttons mobile-margin-adjust">
              <v-btn @click="onCancel">{{
                $t("receiveOrderDialog.cancel")
              }}</v-btn>
            </div>
          </div>
          <div class="info-text mt-2">
            <span
              class="text-subtitle-2 secondaryDark--text"
              v-if="allOrdersArrived"
            >
              {{ $t("receiveOrderDialog.allOrdersArrived") }}
            </span>
            <span
              class="text-subtitle-2 secondaryDark--text"
              v-if="someOrdersNotArriveOrArriveLater"
            >
              {{ $t("receiveOrderDialog.orderNotComplete") }}
            </span>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="showWarningModal"
      persistent
      :fullscreen="getIsMobile"
      width="33vw"
      class="dialog-style-warning"
    >
      <div class="dialog-style-warning">
        <span
          >{{ $t("receiveOrderDialog.allProductsArrivedWarning") }}
          <strong>{{ $t("receiveOrderDialog.arrivedWarning") }}</strong></span
        >
        <div class="d-flex mt-7 justify-center">
          <v-btn color="accent" @click="onConfirmWarning">{{
            $t("common.confirm")
          }}</v-btn>
          <v-btn class="ml-4" @click="onCancelWarning">{{
            $t("common.cancel")
          }}</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
// constants
import moment from "moment";
import { orderStatuses } from "../shared/statuses";
import TooltipComponent from "../components/TooltipComponent.vue";
export default {
  name: "ReceiveOrderDialog",
  components: { TooltipComponent },
  props: { dialog: {}, orderProp: {}, venueId: {}, isCustomOrder: {} },
  data: () => ({
    showDialog: false,
    resolutionOptionsSelect: [],
    showWarningModal: false,
    resolutionOptions: [],
    todayDate: "",
    orderProducts: [],
    rules: [],
    invalidInputForm: false,
    typingTimer: null,
    doneTypingInterval: 1000,
    allItemsPartiallyArrivedHaveReceivedAmount: false,
  }),
  created() {
    this.todayDate = new Date().getTime();
    this.todayDate = new Date(this.todayDate).toISOString();
    this.showDialog = this.dialog;
    this.resolutionOptionsSelect = [
      {
        id: 0,
        value: orderStatuses.DELIVERED,
        text: this.$t("receiveOrderDialog.arrived"),
      },
      {
        id: 1,
        value: orderStatuses.NOT_DELIVERED,
        text: this.$t("receiveOrderDialog.notArrived"),
      },
    ];
    this.resolutionOptions = [
      {
        id: 0,
        value: orderStatuses.MOVE_TO_NEW,
        text: this.$t("receiveOrderDialog.moveToNewOrder"),
      },
      {
        id: 1,
        value: "NOT_AVAILABLE",
        text: this.$t("receiveOrderDialog.ignoreOrder"),
      },
      {
        id: 2,
        value: "ARRIVE_LATER",
        text: this.$t("receiveOrderDialog.arriveLater"),
      },
    ];
  },
  watch: {
    orderProducts: {
      handler() {
        const allPartiallyArrivedStatusLength = this.orderProducts?.filter(
          (product) => product.status === orderStatuses.PARTIALLY_ARRIVED
        ).length;
        const allPartiallyArrivedAmountLength = this.orderProducts?.filter(
          (product) => product.deliveredAmount
        ).length;

        this.allItemsPartiallyArrivedHaveReceivedAmount =
          allPartiallyArrivedStatusLength === allPartiallyArrivedAmountLength;
      },
      deep: true,
    },
    dialog() {
      this.showDialog = this.dialog;
      const products = _.cloneDeep(this.orderReceiveObject.products);
      this.orderProducts = products;
    },
    orderReceiveObject: {
      handler() {
        if (this.orderReceiveObject) {
          this.orderProducts.forEach((product) => {
            if (product.status === orderStatuses.NOT_DELIVERED) {
              product.orderResolutionStatus = orderStatuses.NOT_DELIVERED;
            }
            if (product.status === orderStatuses.DELIVERED) {
              product.orderResolutionStatus = orderStatuses.DELIVERED;
            }
            if (product.status === orderStatuses.INCOMPLETE) {
              product.orderResolutionStatus = orderStatuses.NOT_DELIVERED;
              product.status = orderStatuses.NOT_DELIVERED;
            }
            if (product.status === orderStatuses.MOVE_TO_NEW) {
              product.orderResolutionStatus = orderStatuses.NOT_DELIVERED;
              product.status = orderStatuses.NOT_DELIVERED;
            }
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.orderReceiveObject.products) {
      const products = _.cloneDeep(this.orderReceiveObject.products);
      this.orderProducts = products;
    }
  },
  computed: {
    isAnyArticlePartiallyArrived() {
      return (
        this.orderProducts?.filter(
          (f) => f.orderResolutionStatus === orderStatuses.PARTIALLY_ARRIVED
        ).length > 0
      );
    },
    isAnyArticleNotDelivered() {
      return (
        this.orderProducts?.filter(
          (f) => f.orderResolutionStatus === orderStatuses.NOT_DELIVERED
        ).length > 0
      );
    },
    isFormValid() {
      return (
        this.orderProducts?.filter((f) => f.orderResolutionStatus).length ===
          this.orderProducts.length && !this.invalidInputForm
      );
    },
    allOrdersArrived() {
      return (
        this.orderProducts?.filter((f) => f.status === orderStatuses.DELIVERED)
          .length === this.orderProducts.length
      );
    },
    someOrdersNotArriveOrArriveLater() {
      let temp = false;
      this.orderProducts?.forEach((val) => {
        if (val.status === orderStatuses.NOT_DELIVERED) {
          temp = true;
        }
      });
      return temp;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    isBasicPlan() {
      return this.$attrs.isBasicPlan;
    },
    someItemsArriveLAter() {
      return this.orderProducts?.find(
        (product) =>
          product.notDeliveredReason === "ARRIVE_LATER" &&
          product.status !== orderStatuses.DELIVERED
      );
    },
    getIsInputInvalid() {
      return this.invalidInputForm;
    },
    orderReceiveObject() {
      return this.orderProp;
    },
  },
  methods: {
    onCloseEditReceiveNote(article) {
      this.$set(article, "receiveNoteActive", false);
    },
    clearItemReceiveNote(article) {
      this.$set(article, "receiveNote", "");
      // this.selectedArticles.forEach((article) => {
      //   if (article.id === article.id) {
      //     this.$set(article, "note", "");
      //   }
      // });
      // this.setLocalStorage();
    },
    onItemReceiveNoteChangeUp(event, item) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.setArticleReceiveNote(event, item),
        this.doneTypingInterval
      );
    },
    onItemNoteChangeDown() {
      clearTimeout(this.typingTimer);
    },
    setArticleReceiveNote(event, item) {
      let valueEnterred = event?.target.value;
      this.$set(item, "receiveNote", valueEnterred);
      // this.selectedArticles?.forEach((article) => {
      //   if (article.id === item.id) {
      //     article.note = valueEnterred;
      //   }
      // });
      // this.setLocalStorage();
    },
    onArticleReceiveNoteSelected(article) {
      this.$set(article, "receiveNoteActive", true);
    },
    nameKeydown(e) {
      if (e.key === ",") {
        e.preventDefault();
      }
    },
    validateInput(event, article) {
      if (this.orderProducts?.length > 0) {
        this.orderProducts.forEach((product) => {
          if (
            product.deliveredAmount &&
            product.deliveredAmount.startsWith(".")
          ) {
            product.deliveredAmount = "0" + product.deliveredAmount;
          }
          const valueValidated =
            product.deliveredAmount !== null
              ? this.validate(product.deliveredAmount)
              : true;
          const valueEnterredFloat = parseFloat(product.deliveredAmount);

          if (!valueValidated || valueEnterredFloat < 0.1) {
            this.invalidInputForm = true;
            this.$set(product, "amountError", [""]);
          } else {
            this.invalidInputForm = false;
            this.$set(product, "amountError", []);
          }
        });
      }
      if (
        article.status === orderStatuses.PARTIALLY_ARRIVED &&
        (article.deliveredAmount === null ||
          article.deliveredAmount === "" ||
          article.amountError.length > 0)
      ) {
        this.invalidInputForm = true;
        this.$set(article, "amountError", [""]);
      }
    },
    validate(s) {
      let rgx = /^[0-9]*\.?[0-9]*$/;
      return rgx.test(s);
    },
    closeDialog() {
      this.orderProducts = this.orderReceiveObject.products;
      this.$emit("onClose");
    },
    updateOrderAfterMoving() {
      const products = this.orderProducts.map((product) => {
        if (product.status === orderStatuses.PARTIALLY_ARRIVED) {
          return {
            ...product,
            status: orderStatuses.DELIVERED,
            notDeliveredReason: "",
          };
        }
        return product;
      });
      const order = {
        date: this.todayDate,
        orderNote: this.orderReceiveObject.orderNote,
        paymentMethod: this.orderReceiveObject.paymentMethod,
        articles: products,
        supplier: this.orderReceiveObject.supplier,
        receivingNote: this.orderReceiveObject.orderRecevingNote,
      };

      this.$store
        .dispatch(
          this.isCustomOrder
            ? "orders/updateCustomOrder"
            : "orders/updateOrder",
          {
            orderId: this.orderReceiveObject.id,
            order: order,
          }
        )
        .then(() => {});
    },
    resolveOrder() {
      const { moveArticlesToNew, productsDeepCopy } =
        this.processSelectedArticles();

      if (
        moveArticlesToNew.length > 0 &&
        !this.orderReceiveObject.isCustomOrder &&
        this.someItemsArriveLAter
      ) {
        //for orders which articles are moved to new and some of them arrive later (so original order is kept)
        // creating new order with articles that have to be moved
        let utcOrderDate = new moment.utc(
          this.todayDate.substr(0, 10)
        ).format();
        const order = {
          articles: moveArticlesToNew,
          date: utcOrderDate,
          orderNote: "",
          paymentMethod: "",
          supplier: this.orderReceiveObject.supplier,
        };
        this.$store
          .dispatch("orders/createNewOrder", {
            departmentID: this.orderReceiveObject.department.id,
            order: order,
            orderNote: order.orderNote,
            paymentMethod: order.paymentMethod,
          })
          .then((response) => {
            productsDeepCopy.forEach((article) => {
              // passing new order id where article was moved to
              if (article.notDeliveredReason === orderStatuses.MOVE_TO_NEW) {
                this.$set(article, "status", orderStatuses.MOVE_TO_NEW);
                this.$set(article, "move_to_order_id", response.data.id);
              }
            });
            const products = productsDeepCopy.map((product) => {
              if (product.status === orderStatuses.PARTIALLY_ARRIVED) {
                return {
                  ...product,
                  status: orderStatuses.DELIVERED,
                  notDeliveredReason: "",
                };
              }
              return product;
            });
            const order = {
              date: this.todayDate,
              orderNote: this.orderReceiveObject.orderNote,
              paymentMethod: this.orderReceiveObject.paymentMethod,
              articles: products,
              supplier: this.orderReceiveObject.supplier,
              receivingNote: this.orderReceiveObject.orderRecevingNote,
            };

            this.$store
              .dispatch(
                this.isCustomOrder
                  ? "orders/updateCustomOrder"
                  : "orders/updateOrder",
                {
                  orderId: this.orderReceiveObject.id,
                  order: order,
                }
              )
              .then(() => {
                this.$emit("allOrdersArrivedMoveToNew", {
                  newOrderId: response.data.id,
                  departmentId: this.orderReceiveObject.department.id,
                });
              });
          });
      } else if (
        moveArticlesToNew.length > 0 &&
        !this.orderReceiveObject.isCustomOrder
      ) {
        // creating new order with articles that have to be moved
        let utcOrderDate = new moment.utc(
          this.todayDate.substr(0, 10)
        ).format();
        const order = {
          articles: moveArticlesToNew,
          date: utcOrderDate,
          orderNote: "",
          paymentMethod: "",
          supplier: this.orderReceiveObject.supplier,
        };
        this.$store
          .dispatch("orders/createNewOrder", {
            departmentID: this.orderReceiveObject.department.id,
            order: order,
            orderNote: order.orderNote,
            paymentMethod: order.paymentMethod,
          })
          .then((response) => {
            productsDeepCopy.forEach((article) => {
              // passing new order id where article was moved to
              if (article.notDeliveredReason === orderStatuses.MOVE_TO_NEW) {
                this.$set(article, "status", orderStatuses.MOVE_TO_NEW);
                this.$set(article, "move_to_order_id", response.data.id);
              }
            });

            this.$store
              .dispatch("orders/completeOrder", {
                id: this.orderReceiveObject.id,
                products: productsDeepCopy,
                receivingNote: this.orderReceiveObject.orderRecevingNote,
              })
              .then(() => {
                this.updateOrderAfterMoving();

                this.$emit("allOrdersArrivedMoveToNew", {
                  newOrderId: response.data.id,
                  departmentId: this.orderReceiveObject.department.id,
                });
              });
          });
      } else if (this.someItemsArriveLAter) {
        this.updateOrder();
      } else {
        productsDeepCopy.forEach((p) => {
          if (p.status === orderStatuses.PARTIALLY_ARRIVED) {
            this.$set(p, "status", orderStatuses.DELIVERED);
            this.$set(p, "notDeliveredReason", "");
          }
          if (p.deliveredAmount === "0.00") {
            delete p.deliveredAmount;
          }
        });
        productsDeepCopy.forEach((p) => {
          if (p.status === orderStatuses.DELIVERED) {
            this.$set(p, "notDeliveredReason", "");
          }
        });
        this.$store
          .dispatch(
            this.isCustomOrder
              ? "orders/completeCustomOrder"
              : "orders/completeOrder",
            {
              id: this.orderReceiveObject.id,
              products: productsDeepCopy,
              receivingNote: this.orderReceiveObject.orderRecevingNote,
            }
          )
          .then(() => {
            this.$emit("allOrdersArrived");
          });
      }
    },
    resolutionChange(event, value) {
      this.$set(value, "orderResolutionStatus", event);
    },
    onAllArticlesArrived() {
      this.showWarningModal = true;
    },
    onConfirmWarning() {
      this.orderProducts.forEach((product) => {
        this.$set(product, "status", orderStatuses.DELIVERED);
      });
      this.resolveOrder();
    },
    onCancelWarning() {
      this.showWarningModal = false;
    },
    onResolutionSelected(event, article) {
      switch (event) {
        case orderStatuses.MOVE_TO_NEW:
          this.$set(article, "notDeliveredReason", orderStatuses.MOVE_TO_NEW);
          break;
        case "NOT_AVAILABLE":
          this.$set(article, "notDeliveredReason", "NOT_AVAILABLE");
          break;
        case "ARRIVE_LATER":
          this.$set(article, "notDeliveredReason", "ARRIVE_LATER");
          break;
      }
    },
    processSelectedArticles() {
      let moveArticlesToNew = [];
      let ignoreAndInformArticles = [];
      let arriveLaterArticles = [];
      let productsDeepCopy = _.cloneDeep(this.orderProducts);
      productsDeepCopy.forEach((product) => {
        switch (product.notDeliveredReason) {
          case orderStatuses.MOVE_TO_NEW:
            moveArticlesToNew.push(product);
            break;
          case "NOT_AVAILABLE":
            ignoreAndInformArticles.push(product);
            break;
          case "ARRIVE_LATER":
            if (product.orderResolutionStatus === orderStatuses.DELIVERED) {
              this.$set(product, "notDeliveredReason", "");
            } else {
              this.$set(product, "status", orderStatuses.INCOMPLETE);
            }

            arriveLaterArticles.push(product);
            break;
        }
      });
      return {
        moveArticlesToNew,
        ignoreAndInformArticles,
        arriveLaterArticles,
        productsDeepCopy,
      };
    },
    onCancel() {
      this.orderProducts = this.orderReceiveObject.products;
      this.closeDialog();
    },
    updateOrder() {
      const products = this.orderProducts.map((product) => {
        if (product.status === orderStatuses.PARTIALLY_ARRIVED) {
          return {
            ...product,
            status: orderStatuses.DELIVERED,
            notDeliveredReason: "",
          };
        }
        return product;
      });
      const order = {
        date: this.todayDate,
        orderNote: this.orderReceiveObject.orderNote,
        paymentMethod: this.orderReceiveObject.paymentMethod,
        articles: products,
        supplier: this.orderReceiveObject.supplier,
        receivingNote: this.orderReceiveObject.orderRecevingNote,
      };

      this.$store
        .dispatch(
          this.isCustomOrder
            ? "orders/updateCustomOrder"
            : "orders/updateOrder",
          {
            orderId: this.orderReceiveObject.id,
            order: order,
          }
        )
        .then(() => {
          this.$emit("onOrderArriveLaterUpdated");
        });
    },
    onReceivingArticleNoteChange(article) {
      this.$set(article, "showReceveNote", !article.showReceveNote);
      if (!article.showReceveNote) {
        this.$set(article, "receivingNote", "");
      }
    },
    onReceivingOrderNoteChange() {
      this.$set(
        this.orderReceiveObject,
        "showOrderRecevingNote",
        !this.orderReceiveObject.showOrderRecevingNote
      );
      if (!this.orderReceiveObject.showOrderRecevingNote) {
        this.$set(this.orderReceiveObject, "orderRecevingNote", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  z-index: 9999;
}
.dialog-style-warning {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.full-height-dialog .v-dialog__content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}
.full-height-dialog .dialog-style {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.full-height-dialog .content {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.close-icon {
  cursor: pointer;
}
.info-text {
  text-align: center;
}
.order-note-width {
  flex: 60%;
}
.amount-width {
  flex: 40%;
}
.select-width {
  max-width: 315px;
  @media screen and (max-width: 575px) {
    max-width: 60%;
  }
}
.mobile-arrange-buttons {
  @media screen and (max-width: 575px) {
    width: 100%;
    justify-content: center;
  }
}
.mobile-margin-adjust {
  @media screen and (max-width: 575px) {
    margin-top: 16px;
  }
}
.dim-content {
  opacity: 0.4;
}
.item-note-width {
  width: 100%;
}
</style>

import SupplierService from "../services/supplier.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";

const initialState = {
  addSupplierStatus: REQUEST_UNDEFINED,
  uploadSupplierDataStatus: REQUEST_UNDEFINED,
  addSupplierContactStatus: REQUEST_UNDEFINED,
  deleteContactSupplierStatus: REQUEST_UNDEFINED,
  editContactSupplierStatus: REQUEST_UNDEFINED,
  editSupplierDataStatus: REQUEST_UNDEFINED,
  addSupplierNotesStatus: REQUEST_UNDEFINED,
  getSuppliersForDepartmentStatus: REQUEST_UNDEFINED,
  departmentSuppliers: [],
  isSelectedSupplierMarket: false,
  requestNewSupplierStatus: REQUEST_UNDEFINED,
};

export const supplier = {
  namespaced: true,
  state: initialState,
  actions: {
    addSupplier({ commit }, data) {
      commit("addSupplierPending");
      return SupplierService.addSupplier(data).then(
        (response) => {
          commit("addSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("addSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    requestNewSupplier({ commit }, data) {
      commit("requestNewSupplierPending");
      return SupplierService.requestNewSupplierService(data).then(
        (response) => {
          commit("requestNewSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestNewSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    uploadSupplierData({ commit }, data) {
      commit("uploadSupplierDataPending");
      return SupplierService.uploadSupplierData(data).then(
        (response) => {
          commit("uploadSupplierDataSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("uploadSupplierDataFailure");
          return Promise.reject(error);
        }
      );
    },
    addContactSupplier({ commit }, data) {
      commit("addContactSupplierPending");
      return SupplierService.addSupplierContact(data.id, data.data).then(
        (response) => {
          commit("addContactSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("addContactSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    deleteContactSupplier({ commit }, data) {
      commit("deleteContactSupplierPending");
      return SupplierService.deleteContactSupplier(data).then(
        (response) => {
          commit("deleteContactSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteContactSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    updateContactSupplier({ commit }, data) {
      commit("editContactSupplierPending");
      return SupplierService.editContactSupplier(data).then(
        (response) => {
          commit("editContactSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("editContactSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    editSupplierData({ commit }, data) {
      commit("editSupplierDataPending");
      return SupplierService.editSupplierDataService(data).then(
        (response) => {
          commit("editSupplierDataSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("editSupplierDataFailure");
          return Promise.reject(error);
        }
      );
    },
    addSupplierNotes({ commit }, data) {
      commit("addSupplierNotesPending");
      return SupplierService.addSupplierNotesService(data).then(
        (response) => {
          commit("addSupplierNotesSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("addSupplierNotesFailure");
          return Promise.reject(error);
        }
      );
    },
    getSuppliersForDepartment({ commit }, data) {
      commit("getSuppliersForDepartmentPending");
      return SupplierService.getSuppliersForDepartment(data).then(
        (response) => {
          commit("getSuppliersForDepartmentSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getSuppliersForDepartmentFailure");
          return Promise.reject(error);
        }
      );
    },

    setIsSelectedSupplierMarket(state, payload) {
      state.isSelectedSupplierMarket = payload;
    },
    clearAllSuppliersState({ commit }) {
      commit("clearAllSuppliersState");
    },
  },
  mutations: {
    addSupplierPending(state) {
      state.addSupplierStatus = REQUEST_PENDING;
    },
    addSupplierSuccess(state) {
      state.addSupplierStatus = REQUEST_SUCCESS;
    },
    addSupplierFailure(state) {
      state.addSupplierStatus = REQUEST_FAILURE;
    },
    requestNewSupplierPending(state) {
      state.requestNewSupplierStatus = REQUEST_PENDING;
    },
    requestNewSupplierSuccess(state) {
      state.requestNewSupplierStatus = REQUEST_SUCCESS;
    },
    requestNewSupplierFailure(state) {
      state.requestNewSupplierStatus = REQUEST_FAILURE;
    },
    uploadSupplierDataPending(state) {
      state.uploadSupplierDataStatus = REQUEST_PENDING;
    },
    uploadSupplierDataSuccess(state) {
      state.uploadSupplierDataStatus = REQUEST_SUCCESS;
    },
    uploadSupplierDataFailure(state) {
      state.uploadSupplierDataStatus = REQUEST_FAILURE;
    },
    addContactSupplierPending(state) {
      state.addSupplierContactStatus = REQUEST_PENDING;
    },
    addContactSupplierSuccess(state) {
      state.addSupplierContactStatus = REQUEST_SUCCESS;
    },
    addContactSupplierFailure(state) {
      state.addSupplierContactStatus = REQUEST_FAILURE;
    },
    deleteContactSupplierPending(state) {
      state.deleteContactSupplierStatus = REQUEST_PENDING;
    },
    deleteContactSupplierSuccess(state) {
      state.deleteContactSupplierStatus = REQUEST_SUCCESS;
    },
    deleteContactSupplierFailure(state) {
      state.deleteContactSupplierStatus = REQUEST_FAILURE;
    },
    editContactSupplierPending(state) {
      state.editContactSupplierStatus = REQUEST_PENDING;
    },
    editContactSupplierSuccess(state) {
      state.editContactSupplierStatus = REQUEST_SUCCESS;
    },
    editContactSupplierFailure(state) {
      state.editContactSupplierStatus = REQUEST_FAILURE;
    },
    editSupplierDataPending(state) {
      state.editSupplierDataStatus = REQUEST_PENDING;
    },
    editSupplierDataSuccess(state) {
      state.editSupplierDataStatus = REQUEST_SUCCESS;
    },
    editSupplierDataFailure(state) {
      state.editSupplierDataStatus = REQUEST_FAILURE;
    },

    addSupplierNotesPending(state) {
      state.addSupplierNotesStatus = REQUEST_PENDING;
    },
    addSupplierNotesSuccess(state) {
      state.addSupplierNotesStatus = REQUEST_SUCCESS;
    },
    addSupplierNotesFailure(state) {
      state.addSupplierNotesStatus = REQUEST_FAILURE;
    },

    getSuppliersForDepartmentPending(state) {
      state.getSuppliersForDepartmentStatus = REQUEST_PENDING;
      state.departmentSuppliers = null;
    },
    getSuppliersForDepartmentSuccess(state, payload) {
      state.getSuppliersForDepartmentStatus = REQUEST_SUCCESS;
      state.departmentSuppliers = payload;
    },
    getSuppliersForDepartmentFailure(state) {
      state.getSuppliersForDepartmentStatus = REQUEST_FAILURE;
      state.departmentSuppliers = null;
    },
    clearAllSuppliersState(state) {
      state.departmentSuppliers = [];
    },
  },
  getters: {
    getUploadSupplierDataStatus: (state) => {
      return state.uploadSupplierDataStatus;
    },
    getEditSupplierDataStatus: (state) => {
      return state.editSupplierDataStatus;
    },
    getAddSupplierNotesStatus: (state) => {
      return state.addSupplierNotesStatus;
    },
    getDepartmentSuppliers: (state) => {
      return state.departmentSuppliers;
    },
    getGetSuppliersForDepartmentStatus: (state) => {
      return state.getSuppliersForDepartmentStatus;
    },
    getIsSelectedSupplierMarket: (state) => {
      return state.isSelectedSupplierMarket;
    },
  },
};

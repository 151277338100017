<template>
  <div class="drag-drop-area">
    <v-alert
      dense
      border="top"
      colored-border
      type="info"
      elevation="2"
      style="text-wrap: pretty"
      class="mt-4"
    >
      {{ $t("unverifiedCreateArticle.importArticlesHint") }}
      <a
        href="/supplyme_article_upload_template.xlsx"
        download="SupplyMe_article_upload_template.xlsx"
      >
        {{ $t("unverifiedCreateArticle.template") }}
      </a>
    </v-alert>
    <v-file-input
      :disabled="!selectedSupplierId"
      small-chips
      :placeholder="$t('suppliers.supportedExcelFile')"
      accept=".xlsx"
      show-size
      @change="onFileInputChange"
      :error="uploadError"
      :error-messages="
        uploadError ? [$t('unverifiedCreateArticle.importArticleError')] : []
      "
    ></v-file-input>
  </div>
</template>
<script>
import EventBus from '../../../../shared/EventBus';

export default {
  name: "ImportArticles",
  props: {
    selectedSupplierId: {},
  },
  data() {
    return {
        uploadError: false
    };
  },
  methods: {
    onFileInputChange(e) {
      this.uploadError = false;
      this.$store.dispatch("loader/setLoadingState");
      let data = { id: this.selectedSupplierId, data: e };
      if (e) {
        this.$store.dispatch("supplier/uploadSupplierData", data).then(
          () => {
            setTimeout(() => {
              this.$store.dispatch("loader/setLoadingState");
              EventBus.dispatch("showSnackbar", {
                text: this.$t("notifications.uploadedSupplierData"),
              });
            }, 2000);
          },
          () => {
            this.$store.dispatch("loader/setLoadingState");
            this.uploadError = true;
          }
        );
      } else {
        this.$store.dispatch("loader/setLoadingState");
        this.uploadError = false;
      }
    },
  },
};
</script>

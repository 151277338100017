<template>
  <div
    class="supplier-creation-wizard d-flex justify-center align-center"
    style="width: 100%"
  >
    <v-stepper
      style="box-shadow: none !important"
      v-model="step"
      vertical
      width="90%"
    >
      <v-stepper-step :complete="step > 1" step="1" :editable="step > 1">
        {{ $t("supplierCreationWizard.createStations") }}
        <p class="text-hint">
          {{ $t("supplierCreationWizard.createStationsHint") }}
        </p>
      </v-stepper-step>

      <v-stepper-content step="1">
        <wizard-step-one-create-stations
          @onStepIsValid="handleOnStepIsValid"
        ></wizard-step-one-create-stations>
        <div class="d-flex justify-end">
          <v-btn color="primary" @click="step = 2" :disabled="!validSteps[1]">
            {{ $t("common.next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2" :editable="step > 2">
        {{ $t("supplierCreationWizard.createSupplier") }}
        <p class="text-hint">
          {{ $t("supplierCreationWizard.createSupplierHint") }}
        </p>
      </v-stepper-step>

      <v-stepper-content step="2">
        <wizard-step-two-create-suppliers
          @onStepIsValid="handleOnStepIsValid"
        ></wizard-step-two-create-suppliers>
        <div class="d-flex justify-space-between">
          <v-btn text @click="goToStep(1)"> {{ $t("common.back") }} </v-btn>
          <v-btn color="primary" @click="step = 3" :disabled="!validSteps[2]">
            {{ $t("common.next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3" :editable="step > 3">
        {{ $t("supplierCreationWizard.linkSupplierWithStation") }}
        <p class="text-hint">
          {{ $t("supplierCreationWizard.linkSupplierWithStationHint") }}
        </p>
      </v-stepper-step>

      <v-stepper-content step="3">
        <wizard-step-three-assign-suppliers
          @onStepIsValid="handleOnStepIsValid"
          :step="step"
        ></wizard-step-three-assign-suppliers>
        <div class="d-flex justify-space-between">
          <v-btn text @click="goToStep(2)">{{ $t("common.back") }} </v-btn>
          <v-btn color="primary" @click="step = 4" :disabled="!validSteps[3]">
            {{ $t("common.next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4" :editable="step > 4">
        {{ $t("supplierCreationWizard.createArticlesForSupplier") }}
        <p class="text-hint">
          {{ $t("supplierCreationWizard.createArticlesForSupplierHint") }}
        </p>
      </v-stepper-step>

      <v-stepper-content step="4">
        <wizard-step-four-create-articles
          @onStepIsValid="handleOnStepIsValid"
        ></wizard-step-four-create-articles>
        <div class="d-flex justify-space-between">
          <v-btn text @click="goToStep(2)"> {{ $t("common.back") }} </v-btn>
          <v-btn color="primary" @click="step = 5" :disabled="!validSteps[4]">
            {{ $t("common.next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step step="5">
        {{ $t("supplierCreationWizard.finishWizard") }}
        <p class="text-hint">
          {{ $t("supplierCreationWizard.finishExplanation") }}
        </p>
      </v-stepper-step>
      <v-stepper-content step="5">
        <v-progress-circular
          v-if="finishOnboardingStatus === 'REQUEST_PENDING'"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn v-else color="primary" @click="completeWizard">
          {{ $t("common.complete") }}
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>
<script>
import WizardStepOneCreateStations from "./components/WizardStepOneCreateStations.vue";
import WizardStepTwoCreateSuppliers from "./components/WizardStepTwoCreateSuppliers.vue";
import WizardStepThreeAssignSuppliers from "./components/WizardStepThreeAssignSuppliers.vue";
import WizardStepFourCreateArticles from "./components/WizardStepFourCreateArticles.vue";
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  name: "SupplierCreationWizard",
  components: {
    WizardStepOneCreateStations,
    WizardStepTwoCreateSuppliers,
    WizardStepThreeAssignSuppliers,
    WizardStepFourCreateArticles,
  },
  data() {
    return {
      step: 1,
      validSteps: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    goToStep(stepNumber) {
      this.step = stepNumber;
    },
    completeWizard() {
      this.$store
        .dispatch("unverifiedSupplier/finishOnboarding")
        .then(() => window.location.reload());
    },
    handleOnStepIsValid(stepNumber, isValid) {
      this.validSteps[stepNumber] = isValid;
    },
  },
  computed: {
    ...mapGetters({
      finishOnboardingStatus: "unverifiedSupplier/getFinishOnboardingStatus",
    }),
  },
};
</script>
<style scoped>
.supplier-creation-wizard {
  @media screen and (max-width: 600px) {
    justify-content: flex-start !important;
  }
}
.text-hint {
  font-size: 0.75rem;
  font-weight: 300;
  white-space: normal;
}
</style>

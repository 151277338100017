import { render, staticRenderFns } from "./AdminRestaurant.vue?vue&type=template&id=d50bf28c&scoped=true&"
import script from "./AdminRestaurant.vue?vue&type=script&lang=js&"
export * from "./AdminRestaurant.vue?vue&type=script&lang=js&"
import style0 from "./AdminRestaurant.vue?vue&type=style&index=0&id=d50bf28c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d50bf28c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBanner,VBtn,VCard,VChip,VDivider,VImg,VProgressCircular,VSelect})

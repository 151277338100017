<template>
  <div class="station-card" :class="{ newCard: newStation }">
    <v-card
      class="elevation-0 card-wrap pa-8 cursor-pointer margin-adjust"
      @click="goToStationsAllOrders(station)"
      :class="{ newStationCard: newStation }"
    >
      <v-card-title v-if="newStation" class="pa-0">{{
        $t("dashboard.creatingNewStation")
      }}</v-card-title>
      <v-text-field
        v-if="newStation"
        name="newStationName"
        id="newStationName"
        type="text"
        color="accent"
        requred
        v-model="newStationName"
        :placeholder="$t('dashboard.enterNewStationName')"
        @change="checkStationName"
        :rules="rules.stationName"
      ></v-text-field>
      <v-card-title v-else class="pa-0 station-name-text">{{
        station.name
      }}</v-card-title>
      <div v-if="newStation" class="d-flex align-center justify-end">
        <v-btn @click="$emit('onCancelAddNew')">{{
          $t("dashboard.cancelCreateStation")
        }}</v-btn>
        <v-btn
          :disabled="!newStationName"
          class="ml-2"
          color="accent"
          @click="onCreateNewStation"
          >{{ $t("dashboard.saveStation") }}</v-btn
        >
      </div>
      <div v-else-if="!getIsMobile" class="order-blocks">
        <div
          class="block block-width"
          @click.stop="goToStationOrders('open', station)"
        >
          <span class="orders-type-text primaryA--text">{{
            $t("dashboard.activeOrders")
          }}</span>

          <div class="block-order-info">
            <div
              @click.stop="goToRegularOrders(station, 'open')"
              class="d-flex flex-column align-center num-order-block"
            >
              <span class="accent--text num-of-orders-text">{{
                station.venue.numberOfOpenOrders
              }}</span>
            </div>
          </div>
        </div>
        <div class="block" @click.stop="goToStationOrders('expected', station)">
          <span class="orders-type-text primaryA--text">{{
            $t("dashboard.expectedOrders")
          }}</span>
          <div class="block-order-info">
            <div
              @click.stop="goToRegularOrders(station, 'expected')"
              class="d-flex flex-column align-center num-order-block"
            >
              <span class="accent--text num-of-orders-text">{{
                station.venue.numberOfExpectedOrders
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-space-between" v-else-if="getIsMobile">
        <div style="text-align: center">
          <span class="orders-type-text primaryA--text">{{
            $t("dashboard.activeOrders")
          }}</span>

          <div class="block-order-info">
            <div
              @click.stop="goToRegularOrders(station, 'open')"
              class="d-flex flex-column align-center num-order-block-mobile"
            >
              <span class="accent--text num-of-orders-text">{{
                station.venue.numberOfOpenOrders
              }}</span>
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <span class="orders-type-text primaryA--text">{{
            $t("dashboard.expectedOrders")
          }}</span>
          <div class="block-order-info">
            <div
              @click.stop="goToRegularOrders(station, 'expected')"
              class="d-flex flex-column align-center num-order-block-mobile"
            >
              <span class="accent--text num-of-orders-text">{{
                station.venue.numberOfExpectedOrders
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { REQUEST_SUCCESS } from "../shared/statuses";
export default {
  name: "StationCard",
  props: {
    station: {},
    newStation: {},
    stations: {},
  },
  data() {
    return {
      newStationName: "",
      sameStationNameViolated: false,
      rules: {
        stationName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
    };
  },
  methods: {
    goToStationOrders(type, station) {
      switch (type) {
        case "open":
          this.$router.push(`/current-orders/${station.id}/orders?tab=1`);
          break;
        case "expected":
          this.$router.push(`/current-orders/${station.id}/orders?tab=2`);
          break;
      }
    },
    onCreateNewStation() {
      setTimeout(() => {
        if (!this.sameStationNameViolated) {
          this.$store.dispatch("stations/createStation", this.newStationName);
        }
      }, 1000);
    },
    checkStationName() {
      this.sameStationNameViolated =
        this.stations.filter(
          (f) => f.name.toLowerCase() === this.newStationName.toLowerCase()
        ).length > 0;
      this.rules.stationName = [this.$t("errors.sameStations")];
    },
    goToStationsAllOrders(station) {
      this.$router.push(`/current-orders/${station.id}/orders`);
    },
    goToRegularOrders(station, type) {
      if (type === "open") {
        this.$router.push(`/current-orders/${station.id}/orders?tab=1`);
      } else {
        this.$router.push(`/current-orders/${station.id}/orders?tab=2`);
      }
    },
    goToWarehouseOrders(station, type) {
      if (type === "open") {
        this.$router.push(`/current-orders/${station.id}/custom-orders?tab=1`);
      } else {
        this.$router.push(`/current-orders/${station.id}/custom-orders?tab=2`);
      }
    },
  },
  computed: {
    ...mapGetters({
      getStationsStatus: "stations/getCreateNewStationStatus",
    }),
    createStationStatus() {
      return this.getStationsStatus;
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    createStationStatus() {
      if (this.createStationStatus === REQUEST_SUCCESS) {
        this.$emit("newStationCreated");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card-wrap {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 16px;
  @media screen and (max-width: 959px) {
    padding: 24px !important;
  }
  @media screen and (max-width: 575px) {
    padding: 12px !important;
  }
}
.order-blocks {
  display: flex;
  flex-wrap: wrap;
}
.arrow-img-wrap {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #276ef1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 575px) {
    width: 24px !important;
    height: 24px !important;
  }
}
.block-width {
  width: calc(50% - 24px) !important;
  margin-right: 24px;
  @media screen and (max-width: 959px) {
    margin-right: 0 !important;
  }
}
.block {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: 144px;
  width: 50%;
  padding: 18px 18px 24px 12px;
  @media screen and (min-width: 576px) and (max-width: 959px) {
    width: 295px !important;
  }
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 6px !important;
    margin-top: 0px !important;
    padding: 12px !important;
  }
  margin-top: 24px;
}
.block-order-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  @media screen and (max-width: 575px) {
    margin-top: 10px !important;
  }
}
.station-card {
  width: calc(100% / 2 - 12px);
  min-width: 327px;
  margin-right: 24px;
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and (min-width: 906px) {
    &:nth-child(even) {
      margin-right: 0 !important;
    }
  }
  @media screen and(min-width:960px) and (max-width: 1300px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and (max-width: 959px) {
    margin: auto !important;
    &:nth-child(even) {
      margin-right: auto !important;
    }
  }
}
.newStationCard {
  min-height: 267px;
  display: flex;
  flex-direction: column;
}
.newCard {
  width: 646px;
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and(min-width:960px) and (max-width: 1300px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.num-of-orders-text {
  font-size: 2.125rem;
  @media screen and (max-width: 575px) {
    font-size: 1.5rem !important;
  }
}
.orders-type-text {
  font-size: 1rem;
  text-align: center;
  @media screen and (max-width: 575px) {
    font-size: 0.8rem !important;
  }
}
.station-name-text {
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 575px) {
    font-size: 1rem !important;
  }
}
.margin-adjust {
  margin-top: 24px !important;
  @media screen and (max-width: 575px) {
    margin-top: 8px !important;
  }
}
.vertical-divider {
  height: 100%;
  width: 1px;
  background-color: #b3b1b1;
  @media screen and (max-width: 575px) {
    display: none;
  }
}

.num-order-block {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 6px;
  width: 60%;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 15px 1px #78baf3;
  }
}
.num-order-block-mobile {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 6px;
  border-radius: 12px;
  width: 100px;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 15px 1px #78baf3;
  }
}
</style>

<template>
  <div class="padding-adjust current-orders-container">
    <div class="mobile-centering">
      <span class="text-h5 primaryA--text font-weight-bold">{{
        $t("currentOrders.currentOrders")
      }}</span>
    </div>
    <!-- <v-tabs v-if="!isBasicPlan" v-model="selectedTab" centered>
      <div class="tab-height-adjust" @click="tabSelected(0)">
        <v-tab class="item-text text-body-1 font-weight-bold">{{
          $t("currentOrders.orders")
        }}</v-tab>
      </div>
      <div class="tab-height-adjust" @click="tabSelected(1)">
        <v-tab class="item-text text-body-1 font-weight-bold">{{
          $t("currentOrders.customOrders")
        }}</v-tab>
      </div>
    </v-tabs> -->

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CurrentOrdersContainer",
  data: () => ({
    stationID: null,
    selectedTab: 0,
  }),
  created() {
    this.stationID = this.$route.params?.departmentID;
  },
  mounted() {
    this.selectedTab = this.$route.path.includes("custom-orders") ? 1 : 0;
  },
  methods: {
    tabSelected(event) {
      this.$router
        .push(
          event === 0
            ? `/current-orders/${this.stationID}/orders`
            : `/current-orders/${this.stationID}/custom-orders`
        )
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters({
      basicPlan: "subscription/isBasicPlan",
    }),
    isBasicPlan() {
      return this.basicPlan;
    },
  },
};
</script>
<style scoped lang="scss">
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
.create-button {
  text-transform: none !important;
}
.button-plus {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #276ef1;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: 1px 1px 11px 1px #276ef1;
  }
}
.mobile-centering {
  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: center;
  }
}

.tab-height-adjust {
  height: fit-content;
}
</style>

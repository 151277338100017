<template>
  <div class="wizard-step-three-assign-suppliers pt-2 pb-4">
    <!-- <v-row v-for="station in stations" :key="station.id" width="100%"> -->
    <v-expansion-panels focusable>
      <v-expansion-panel
        v-for="departmentWithSupplier in allDepartments"
        :key="
          departmentWithSupplier.department &&
          departmentWithSupplier.department.id
        "
      >
        <v-expansion-panel-header>{{
          departmentWithSupplier.department.name
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list-item>
            <v-list-item-content>
              <v-list two-line>
                <v-list-item-group multiple>
                  <template v-for="(supplier, index) in allSuppliers">
                    <v-list-item
                      :class="{
                        'supplier-selected': isSupplierSelected(
                          supplier.id,
                          departmentWithSupplier
                        ),
                        'supplier-unselected': !isSupplierSelected(
                          supplier.id,
                          departmentWithSupplier
                        ),
                      }"
                      :key="supplier.name"
                      @change="
                        onSupplierSelected(
                          supplier.id,
                          departmentWithSupplier.department.id
                        )
                      "
                    >
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="supplier.name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-icon
                            v-if="
                              !isSupplierSelected(
                                supplier.id,
                                departmentWithSupplier
                              )
                            "
                            color="grey lighten-1"
                          >
                            mdi-star-outline
                          </v-icon>

                          <v-icon v-else color="yellow darken-3">
                            mdi-star
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < supplier.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "WizardStepThreeAssignSuppliers",
  props: { step: {} },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$store.dispatch(
      "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
    );
  },
  methods: {
    onSupplierSelected(supplierId, departmentId) {
      const departmentWithSupplier = this.allDepartments.find(
        (department) => department.department.id === departmentId
      );

      if (this.isSupplierSelected(supplierId, departmentWithSupplier)) {
        this.$store
          .dispatch("unverifiedSupplier/unlinkUnverifiedSupplier", {
            supplierId: supplierId,
            departmentId: departmentId,
          })
          .then(() => {
            this.$store.dispatch(
              "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
            );
          });
        return;
      }

      this.$store
        .dispatch("unverifiedSupplier/linkUnverifiedSupplier", {
          supplierId: supplierId,
          departmentId: departmentId,
        })
        .then(() => {
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
        });
    },
    isSupplierSelected(supplierId, departmentWithSupplier) {
      return departmentWithSupplier.suppliers.some(
        (supplier) => supplier.id === supplierId
      );
    },
  },
  computed: {
    ...mapGetters({
      allDepartmentsWithSuppliers:
        "unverifiedSupplier/getAllDepartmentsWithSuppliers",
      allUnverifiedSuppliers: "unverifiedSupplier/getUnverifiedSuppliers",
    }),
    allDepartments() {
      return this.allDepartmentsWithSuppliers;
    },
    allSuppliers() {
      return this.allUnverifiedSuppliers;
    },
  },
  watch: {
    allDepartments() {
      const allDepartmentsHaveAtLeastOneSupplier = this.allDepartments.every(
        (department) => department.suppliers.length > 0
      );
      if (allDepartmentsHaveAtLeastOneSupplier) {
        this.$emit("onStepIsValid", 3, true);
      } else {
        this.$emit("onStepIsValid", 3, false);
      }
    },
    step() {
      if (this.step === 3) {
        this.$store.dispatch(
          "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
        );
      }
    },
  },
};
</script>
<style scoped>
.supplier-selected {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
  background-color: #1976d232;
}
.supplier-selected::before {
  opacity: 0;
}

.supplier-unselected {
  color: #000000 !important;
  caret-color: #000000 !important;
  background-color: unset;
}

.supplier-unselected::before {
  opacity: 0;
}
</style>

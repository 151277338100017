<template>
  <v-row justify="space-around" class="profile-icon-margins">
    <v-menu rounded offset-y>
      <template v-slot:activator="{ attrs, on }">
        <div class="profile-nav-img-wrap" v-bind="attrs" v-on="on">
          <v-img
            max-width="16"
            :src="require('../assets/img/ProfileNav.svg')"
          ></v-img>
        </div>
      </template>

      <v-list>
        <v-list-item
          v-if="item.show"
          v-for="(item, index) in items"
          :key="item.id"
          link
          :class="[
            {
              borderTop: index === items.length - 5,
              disabled: isPendingInvitation && item.id !== 7,
            },
            { 'active-link': isActive(item) },
          ]"
        >
          <v-list-item-content>
            <router-link
              class="profile-nav-link"
              v-if="!item.isLink"
              :to="
                isPendingInvitation && item.path !== '/logout' ? '#' : item.path
              "
              exact-active-class="active-link"
            >
              {{ item.name }}
            </router-link>
            <a
              class="profile-nav-link"
              v-else
              :href="item.path"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ item.name }}
            </a>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>
<script>
export default {
  name: "ProfileMenu",
  props: {
    items: [],
    isPendingInvitation: {},
  },
  data() {
    return {};
  },
  methods: {
    isActive(item) {
      return this.$route.path === item.path;
    },
  },
};
</script>
<style type="scss" scoped>
.profile-nav-img-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.5px solid #276ef1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profile-nav-img-wrap:hover {
  background: #f7f7f8;
}
.profile-icon-margins {
  margin-top: 11px;
  margin-right: 16px;
}
.borderTop {
  border-top: 1px solid #e0e0e0;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.profile-nav-link {
  color: #000000;
  text-decoration: none;
}
.active-link {
  background-color: gray;
  color: #fff;
}
</style>

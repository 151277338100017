<template>
  <div>
    <div class="pt-2">
      <div>
        <v-row
          v-for="(supplier, index) in suppliers"
          :key="supplier.id"
          width="100%"
        >
          <v-col cols="12" sm="6" md="4">
            <v-card v-if="!supplier.isEdit" class="mx-auto" elevation="5">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ supplier.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex">
                    <v-btn icon @click="onEditSupplier(supplier, suppliers)">
                      <v-icon color="primary lighten-1">mdi-pen</v-icon>
                    </v-btn>
                    <v-btn icon @click="onDeleteSupplier(supplier)">
                      <v-icon color="error lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-card>
            <div v-else>
              <v-text-field
                v-model="supplier.name"
                :label="$t('dashboard.enterSupplierName')"
                outlined
                dense
                :rules="rules.supplierName"
                type="text"
                color="accent"
                requred
                @keyup="onCheckSupplierNameUp"
                @keydown="onCheckSupplierNameDown"
              ></v-text-field>
              <v-text-field
                class="mt-2"
                v-model="supplier.pib"
                :label="$t('dashboard.enterSupplierPib')"
                filled
                dense
                :rules="getRules(index)"
                :type="getIsMobile ? 'tel' : 'number'"
                color="accent"
                hide-spin-buttons
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div v-if="supplier.isEdit">
              <v-progress-circular
                v-if="getUpdateSupplierStatus === 'REQUEST_PENDING'"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <div v-else>
                <v-btn
                  small
                  elevation="2"
                  color="primary"
                  :disabled="sameSupplierNameViolated || !supplier.name"
                  @click="updateSupplier(supplier)"
                  >{{ $t("common.save") }}</v-btn
                >
                <v-btn
                  class="ml-2"
                  small
                  elevation="2"
                  @click="cancelUpdateSupplier(supplier)"
                  >{{ $t("common.cancel") }}</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row class="align-center mt-4" v-if="suppliers.length < 4">
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="supplierName"
          :label="$t('dashboard.enterSupplierName')"
          filled
          dense
          :rules="rules.supplierName"
          type="text"
          color="accent"
          requred
          @keyup="onCheckSupplierNameUp"
          @keydown="onCheckSupplierNameDown"
        ></v-text-field>
        <v-text-field
          class="mt-2"
          v-model="supplierPib"
          :label="$t('dashboard.enterSupplierPib')"
          filled
          dense
          :rules="rules.pib"
          :type="getIsMobile ? 'tel' : 'number'"
          color="accent"
          hide-spin-buttons
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="save-button-wrap">
        <v-progress-circular
          v-if="createSupplierStatus === 'REQUEST_PENDING'"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn
          small
          v-else
          color="primary"
          :disabled="sameSupplierNameViolated || !supplierName"
          @click="createSupplier"
          >{{ $t("common.save") }}</v-btn
        >
      </v-col>
    </v-row>
    <simple-warning-modal
      :dialog="showDeleteWarningModal"
      @onClose="showDeleteWarningModal = false"
      @onConfirm="onConfirmDeleteSupplier"
      @onCancel="showDeleteWarningModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimpleWarningModal from "../../../components/SimpleWarningModal.vue";

export default {
  name: "WizardStepTwoCreateSuppliers",
  components: { SimpleWarningModal },
  data() {
    return {
      supplierName: "",
      supplierPib: "",
      sameSupplierNameViolated: false,
      sameSupplierPibViolated: false,
      rules: {
        supplierName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        pib: [
          (v) =>
            (v.length >= 9 && v.length <= 13) ||
            this.$t("common.pibConstraint"),
          (v) =>
            !this.isExistingSupplier(v) || this.$t("errors.sameSupplierPib"),
        ],
      },
      rulesForPibForUpdate: {
        validPibLength: (v) =>
          (v.length >= 9 && v.length <= 13) || this.$t("common.pibConstraint"),

        pibAlreadyExists: function (index) {
          return (v) => {
            if (v.length < 9 || v.length > 13) {
              return true;
            }

            const exists = this.isExistingSupplierWithIndex(v, index);
            return !exists || this.$t("errors.sameSupplierPib");
          };
        },
      },

      typingTimer: null,
      doneTypingInterval: 500,
      supplierToDelete: null,
      showDeleteWarningModal: false,
      warningModalTitle: this.$t(
        "adminStations.deleteSupplierWarningModalMessage"
      ),
    };
  },
  created() {},
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
    });
  },
  methods: {
    isExistingSupplierWithIndex(pib, index) {
      const found = this.suppliers.some((supplier, i) => {
        return supplier.pib === pib && i !== index;
      });
      return found;
    },
    isExistingSupplier(pib) {
      return this.suppliers.some((supplier) => supplier.pib === pib);
    },
    getRules(index) {
      return [
        this.rulesForPibForUpdate.validPibLength,
        this.rulesForPibForUpdate.pibAlreadyExists.call(this, index),
      ];
    },
    createSupplier() {
      this.$store
        .dispatch("unverifiedSupplier/createUnverifiedSupplier", {
          name: this.supplierName,
          pib: this.supplierPib,
        })
        .then(() => {
          this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
          this.rules.supplierName = [];
          this.rules.supplierPib = [];
          this.supplierName = "";
          this.supplierPib = "";
        });
    },
    onCheckSupplierNameUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkSupplierName,
        this.doneTypingInterval
      );
    },
    onCheckSupplierNameDown() {
      clearTimeout(this.typingTimer);
    },

    checkSupplierName() {
      this.sameSupplierNameViolated =
        this.suppliers &&
        this.suppliers.filter(
          (f) => f.name.toLowerCase() === this.supplierName.toLowerCase()
        ).length > 0;
    },
    updateSupplier(supplier) {
      this.$store
        .dispatch("unverifiedSupplier/updateSupplier", supplier)
        .then(() => {
          this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
          this.$set(supplier, "isEdit", false);
        });
    },
    onEditSupplier(supplier, suppliers) {
      this.$set(supplier, "isEdit", true);
      suppliers?.forEach((s) => {
        if (s.id !== supplier.id) {
          this.$set(s, "isEdit", false);
        }
      });
    },
    onDeleteSupplier(supplier) {
      this.supplierToDelete = supplier;
      this.showDeleteWarningModal = true;
    },
    onConfirmDeleteSupplier() {
      this.$store
        .dispatch("unverifiedSupplier/deleteSupplier", this.supplierToDelete.id)
        .then(() => {
          this.showDeleteWarningModal = false;
          this.supplierToDelete = null;
          this.supplierName = "";
          this.supplierPib = "";
          this.rules.supplierName = [];
          this.rules.supplierPib = [];
          this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
        });
    },
    cancelUpdateSupplier(supplier) {
      this.$set(supplier, "isEdit", false);
    },
  },
  computed: {
    ...mapGetters({
      createUnverifiedSupplierStatus:
        "unverifiedSupplier/createUnverifiedSupplierStatus",
      allUnverifiedSuppliers: "unverifiedSupplier/getUnverifiedSuppliers",
      getUpdateSupplierStatus: "unverifiedSupplier/getUpdateSupplierStatus",
    }),
    createSupplierStatus() {
      return "REQUEST_UNDEFINED";
    },
    editSupplierDataStatus() {
      return "REQUEST_UNDEFINED";
    },
    suppliers() {
      return this.allUnverifiedSuppliers;
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    sameSupplierNameViolated() {
      if (this.sameSupplierNameViolated) {
        this.rules.supplierName = [this.$t("errors.sameSupplier")];
      } else {
        this.rules.supplierName = [];
      }
    },
    sameSupplierPibViolated() {
      if (this.sameSupplierPibViolated) {
        this.rules.supplierPib = [this.$t("errors.sameSupplierPib")];
      } else {
        this.rules.supplierPib = [];
      }
    },
    suppliers() {
      if (this.suppliers.length > 0) {
        this.$emit("onStepIsValid", 2, true);
      } else {
        this.$emit("onStepIsValid", 2, false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.save-button-wrap {
  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}
</style>

<template>
  <div class="padding-adjust">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h5 font-weight-bold primaryA--text">{{
        $t("market.title")
      }}</span>
      <v-btn color="accent" outlined @click="addMarketArticle()">{{
        $t("market.addMarketArticle")
      }}</v-btn>
      <v-btn
        v-if="showBackToNewOrder"
        color="accent"
        small
        @click="backToNewOrder"
        >{{ $t("articles.backToOrder") }}</v-btn
      >
    </div>
    <div class="search-filter">
      <v-text-field
        :disabled="!selectedDepartmentFilter"
        id="search"
        name="search"
        class="search-input"
        :placeholder="$t('articles.searchArticles')"
        v-model="searchValue"
        type="text"
        solo
        prepend-inner-icon="mdi-magnify"
        clearable
        @keyup="onSearchUp"
        @keydown="onSearchDown"
        @click:clear="onSearchUp"
      ></v-text-field>
      <div class="d-flex">
        <v-select
          class="select-width"
          :items="getAllDepartments"
          :placeholder="$t('common.stations')"
          v-model="selectedDepartmentFilter"
          item-text="name"
          item-value="id"
          solo
          @change="onDepartmentSelected"
          hide-details="true"
        ></v-select>
        <v-checkbox
          class="ml-2"
          :label="$t('articles.favoriteOnly')"
          v-model="isFavoriteFilterSelected"
          :disabled="!selectedDepartmentFilter"
        ></v-checkbox>
      </div>
    </div>
    <div :class="[$isMobile ? 'mt-0' : 'mt-11']">
      <articles-data-table
        v-if="!noArticles && !$isMobile"
        :headers="headers"
        class="elevation-0"
        :hide-default-footer="false"
        :data="getAllArticles"
        :isDataLoading="isDataLoading"
        @onArticlePriceUpdate="handleArticlePriceUpdated"
        :totalCount="getTotalCount"
        @onPageChange="handlePageChange"
        @onArticleDeleteAction="handleOnArticleDelete"
        :pageSize="pageSize"
        @itemsPerPageChange="onItemsPerPageChange"
        @onArticleFavorite="handleOnFavoriteArticle"
        @onEditArticle="handleOnEditArticle"
        :departmentId="selectedDepartmentFilter"
        :isMarket="true"
      ></articles-data-table>
      <div v-else-if="$isMobile && !noArticles">
        <hr class="horizontal-line" />
        <div class="article-item-wrapper" @scroll="handleScroll">
          <article-item
            v-for="(article, index) in getAllArticles"
            :key="index"
            :article="article"
            @onArticleFavorite="handleOnFavoriteArticle"
            :departmentId="selectedDepartmentFilter"
            @onEditArticle="handleOnEditArticle"
            @onArticleDeleteAction="handleOnArticleDelete"
            :isMarket="true"
          ></article-item>
        </div>
      </div>
      <no-results-content
        v-else
        :noResultsText="$t('common.noResuls')"
        :page="'articles'"
      ></no-results-content>
    </div>
    <edit-article-modal
      :dialog="showEditArticleModal"
      @onClose="handleCloseEditArticleModal"
      @onSuccessAddedArticle="handleOnSuccessAddArticle"
      @onSuccessEditArticle="handleOnSuccessEditArticle"
      :editArticleData="articleToEdit"
      :departmentId="selectedDepartmentFilter"
    ></edit-article-modal>
    <modal-component
      v-if="showDeleteWarningDialog"
      :dialog="showDeleteWarningDialog"
      @onClose="showDeleteWarningDialog = false"
    >
      <div class="d-flex flex-column pa-4">
        <span
          >Da li ste sigurni da zelite da obrisete artikal
          <strong>{{ articleToDelete.name }}</strong> ?</span
        >
        <span class="secondaryDark2--text text-body-2"
          >Obrisani artikal je nemoguće povratiti!</span
        >
        <div class="d-flex justify-center mt-6">
          <v-btn
            :loading="deleteLoading"
            color="accent"
            @click="onConfirmDelete"
            >{{ $t("common.confirm") }}</v-btn
          >
          <v-btn class="ml-4" @click="onCancelDelete">Otkazi</v-btn>
        </div>
      </div>
    </modal-component>
  </div>
</template>
<script>
import EditArticleModal from "./modals/EditArticleModal.vue";
import NoResultsContent from "../../components/NoResultsContent.vue";
import ArticlesDataTable from "../Articles/ArticlesDataTable.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import ArticleItem from "./components/ArticleItem.vue";
//vuex
import { mapGetters } from "vuex";
import { REQUEST_PENDING } from "../../shared/statuses";
import { debounce } from "lodash";
export default {
  name: "MarketPage",
  components: {
    EditArticleModal,
    NoResultsContent,
    ArticlesDataTable,
    ModalComponent,
    ArticleItem,
  },
  props: {},
  data: () => ({
    showEditArticleModal: false,
    searchValue: "",
    typingTimer: null,
    doneTypingInterval: 1500,
    selectedDepartmentFilter: "",
    headers: [],
    isDataLoading: false,
    pageNumber: 1,
    pageSize: 10,
    showDeleteWarningDialog: false,
    articleToDelete: null,
    articleToEdit: null,
    isFavoriteFilterSelected: false,
    isScrolling: false,
    scrollTimeout: null,
    isLoadingMore: false,
    debouncedHandleScroll: {},
  }),
  mounted() {
    this.debouncedHandleScroll = debounce(this.handleScroll, 200);
  },
  created() {
    this.headers = [
      {
        text: this.$t("articles.addArticle"),
        value: "checkbox_action",
        sortable: false,
      },
      {
        text: this.$t("articles.recommendedQuantity"),
        value: "recommendedQuantity",
        sortable: false,
      },
      {
        text: this.$t("common.name"),
        value: "name",
        sortable: false,
      },
      {
        text: this.$t("common.group"),
        value: "category",
        sortable: false,
      },
      // temporarily disabled on client
      // {
      //   text: this.$t("articles.price"),
      //   value: "defaultPrice",
      //   sortable: false,
      // },
      {
        text: "",
        value: "article_action",
        sortable: false,
      },
    ];
  },
  methods: {
    handleScroll(event) {
      const element = event.target;

      if (!this.isScrolling) {
        this.isScrolling = true;
      }

      if (this.scrollTimeout !== null) {
        clearTimeout(this.scrollTimeout);
      }

      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
        this.scrollTimeout = null;
      }, 500);

      const scrollBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;
      if (
        scrollBottom <= 0 &&
        !this.isLoadingMore &&
        this.getTotalCount > this.getAllArticles.length
      ) {
        this.loadMoreArticles();
      }
    },
    loadMoreArticles() {
      this.isLoadingMore = true;
      this.pageNumber += 1;
      this.pageSize = 10;
      this.getAllArticlesSupplier(true);
    },
    addMarketArticle() {
      this.articleToEdit = null;
      this.articleToDelete = null;
      this.showEditArticleModal = true;
    },
    handleCloseEditArticleModal() {
      this.departmentId = null;
      this.articleToEdit = null;
      this.showEditArticleModal = false;
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.searchArticles,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    searchArticles() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getAllArticlesSupplier();
    },
    getAllArticlesSupplier(isAccumulating) {
      const payload = {
        departmentId: this.selectedDepartmentFilter,
        pageNum: this.pageNumber,
        searchQuery: this.searchValue || "",
        pageSize: this.pageSize,
        onlyFavorite: this.isFavoriteFilterSelected,
        isAccumulating,
      };
      this.$store.dispatch("articles/getCustomArticles", payload).then(() => {
        this.isLoadingMore = false;
      });
    },
    onDepartmentSelected() {
      this.getAllArticlesSupplier();
    },
    backToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      this.$router.push(`/${data.id}/new-order`);
      localStorage.removeItem("cameFromNewOrder");
    },
    handleSelectedArticles() {},
    handleArticlePriceUpdated() {},
    handlePageChange(event) {
      this.pageNumber = event;
      this.getAllArticlesSupplier();
    },
    handleOnSuccessAddArticle(selectedSupplierId) {
      this.selectedDepartmentFilter = selectedSupplierId;
      this.getAllArticlesSupplier();
    },
    handleOnSuccessEditArticle(selectedSupplierId) {
      this.selectedDepartmentFilter = selectedSupplierId;
      this.getAllArticlesSupplier();
    },
    handleOnArticleDelete(article) {
      this.articleToDelete = article;
      this.departmentId = this.selectedDepartmentFilter;
      this.showDeleteWarningDialog = true;
    },
    onItemsPerPageChange(event) {
      this.pageSize = event;
      this.getAllArticlesSupplier();
    },
    handleOnFavoriteArticle(item) {
      this.$store
        .dispatch("articles/toggleCustomArticleFavorite", {
          departmentId: this.selectedDepartmentFilter,
          articleId: item.id,
        })
        .then(
          () => {
            this.getAllArticlesSupplier();
          },
          (err) => {
            alert(err);
          }
        );
    },
    onConfirmDelete() {
      this.$store
        .dispatch("articles/deleteCustomArticle", {
          departmentId: this.selectedDepartmentFilter,
          articleId: this.articleToDelete.id,
        })
        .then(() => {
          this.showDeleteWarningDialog = false;
          this.getAllArticlesSupplier();
        });
    },
    onCancelDelete() {
      this.departmentId = null;
      this.articleToDelete = null;
      this.showDeleteWarningDialog = false;
    },
    handleOnEditArticle(article) {
      this.articleToEdit = article;
      this.departmentId = this.selectedDepartmentFilter;
      this.showEditArticleModal = true;
    },
  },
  computed: {
    ...mapGetters({
      getStations: "userProfile/getStations",
      getAllCustomArticles: "articles/getAllCustomArticles",
      getAllCustomArticlesStatus: "articles/getAllCustomArticlesStatus",
      getAllCustomArticlesCount: "articles/getAllCustomArticlesCount",
      getDeleteArticleStatus: "articles/getDeleteCustomArticleStatus",
      deleteCustomArticleStatus: "articles/getDeleteCustomArticleStatus",
    }),
    getAllDepartments() {
      return this.getStations;
    },
    getAllArticles() {
      return this.getAllCustomArticles;
    },
    noArticles() {
      return this.getAllArticles.length === 0 && this.isDataLoading === false;
    },
    showBackToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      return data && data.show;
    },
    getTotalCount() {
      return this.getAllCustomArticlesCount;
    },
    deleteLoading() {
      return this.deleteCustomArticleStatus === REQUEST_PENDING;
    },
  },
  watch: {
    getAllCustomArticlesStatus() {
      this.isDataLoading = this.getAllCustomArticlesStatus === REQUEST_PENDING;
    },
    getDeleteArticleStatus() {
      this.isDataLoading = this.getDeleteArticleStatus === REQUEST_PENDING;
    },
    isFavoriteFilterSelected() {
      this.getAllArticlesSupplier();
    },
  },
};
</script>
<style scoped lang="scss">
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
    margin-top: 8px;
  }
}
.search-input {
  max-width: 315px;
  height: 54px;
  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
.search-filter {
  margin-top: 24px;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}
.select-width {
  max-width: 315px;
  margin-left: 24px;
  @media screen and (max-width: 575px) {
    margin-left: 0;
    justify-content: space-between;
    max-width: 49%;
  }
}
.article-item-wrapper {
  max-height: calc(100vh - 390px);
  overflow-y: auto;
  overflow-x: hidden;
}

.horizontal-line {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>

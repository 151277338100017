<template>
  <v-data-table
    :headers="translatedHeaderOptions"
    :items="data"
    :sort-by="['name']"
    :sort-desc="[false, true]"
    class="elevation-1"
    :loading="isDataLoading"
    :loading-text="$t('common.loadingTableText')"
    mobile-breakpoint="400"
    :footer-props="{
      itemsPerPageAllText: $t('common.all'),
      itemsPerPageText: $t('common.itemsPerPage'),
      disableItemsPerPage: pageSize ? false : true,
      itemsPerPageOptions: pageSize ? [10, 20, 30] : [30, 30, 30],
    }"
    :options.sync="options"
    :server-items-length="totalOptions"
    @update:page="handlePageChange"
    :items-per-page="pageSize || 30"
    @update:items-per-page="handleItemsPerPageChange"
  >
    <template v-slot:item.deadline="{ item }">
      <span class="text-body-1 accent--text order-deadline">{{
        getFormattedDate(item.deadline)
      }}</span>
    </template>

    <template v-slot:item.statusText="{ item }">
      <span
        v-if="item.status === 'OPEN'"
        class="tag-style text-body-1 negative--text"
        >{{ item.statusText }}</span
      >
      <span v-else class="tag-style text-body-1 accent--text">{{
        item.statusText
      }}</span>
    </template>
    <!-- current orders -->
    <template v-slot:item.supplierOrders="{ item }">
      <span>
        {{ item && item.supplier && item.supplier.name }}
      </span>
    </template>
    <template v-slot:item.options="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(opt, i) in optionsItems" :key="i">
            <v-list-item-title
              :class="{
                disabled:
                  (item.status === 'COMPLETED' && opt.value === 'edit') ||
                  (item.status !== 'IN_PROGRESS' && opt.value === 'receive'),
              }"
              class="option-item"
              @click="handleOptionsSelection(opt.value, item)"
              >{{ opt.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- current orders -->
    <!-- articles list data table -->
    <template v-slot:item.name="{ item }">
      <span>{{ item && item.name }}</span>
    </template>
    <template v-slot:item.recommendedQuantity="{ item }">
      <div class="d-flex align-center">
        <input
          class="recommmended-quantity-input"
          type="text"
          v-model="item.recommendedQuantity"
          @input="validateInput(item)"
          :placeholder="$t('articles.recommendedQuantity')"
          @paste.prevent
          inputmode="decimal"
          pattern="[0-9]*\.?[0-9]*"
          :disabled="!isAdmin"
        />
      </div>
    </template>
    <template v-slot:item.__category__="{ item }">
      <span>{{ item && item.category && item.category.name }}</span>
    </template>
    <template v-slot:item.supplierName="{ item }">
      <span>{{ item && item.supplier && item.supplier.name }}</span>
    </template>
    <template v-slot:item.checkbox_action="{ item }">
      <v-btn
        icon
        :color="item.isFavorite ? 'accent' : 'secondaryDark'"
        @click="handleArticleFavorite(item)"
      >
        <v-icon>mdi-star</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.article_action="{ item }">
      <div>
        <v-btn
          class="mx-2"
          fab
          dark
          color="accent"
          height="35px"
          width="35px"
          @click="() => onEditArticle(item)"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          dark
          color="negative"
          height="35px"
          width="35px"
          @click="removeCustomArticle(item)"
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>
      </div>
    </template>
    <!-- temporarily disabled on client -->
    <!-- <template v-slot:item.defaultPrice="{ item }">
      <v-text-field
        class="price-input"
        :placeholder="$t('articles.enterPrice')"
        v-model.lazy="item.defaultPrice"
        type="number"
        solo
        @keyup="onPriceChangedUp($event, item)"
        @keydown="onPriceChangedDown($event, item)"
      ></v-text-field>
    </template> -->
    <!-- articles list data table -->
    <!-- suppliers data table -->
    <template v-slot:item.supplersName="{ item }">
      <span>{{ item.name }}</span>
    </template>
    <template v-slot:item.add_contact="{ item }">
      <div>
        <v-btn
          class="mx-2"
          fab
          dark
          color="accent"
          height="35px"
          width="35px"
          @click="addContact(item)"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-btn
          v-if="item && item.contacts && item.contacts.length > 0"
          class="mx-2"
          fab
          dark
          color="warning"
          height="35px"
          width="35px"
          @click="previewContacts(item)"
        >
          <v-icon dark> mdi-format-list-bulleted-square </v-icon>
        </v-btn>
      </div>
    </template>
    <!-- suppliers data table -->
  </v-data-table>
</template>
<script>
//services
import EventBus from "../../shared/EventBus";
import { formatDate } from "../../helpers/helper";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    headers: {},
    data: {},
    isDataLoading: {},
    archive: {},
    pageType: {},
    totalCount: {},
    pageSize: {},
    isPrimaryArticle: {},
    departmentId: {},
    isMarket: {},
  },
  name: "ArticlesDataTable",
  data: () => ({
    loader: null,
    selectedSupplierId: null,
    optionsItems: [],
    typingTimer: null,
    doneTypingInterval: 1500,
    totalOptions: 0,
    options: {},
  }),
  created() {
    this.debouncedApiCall = _.debounce(this.saveRecommendedQuantity, 700);
    this.optionsItems = [
      {
        id: 0,
        title: this.$t("currentOrders.edit"),
        value: "edit",
      },
      {
        id: 1,
        title: this.$t("currentOrders.delete"),
        value: "delete",
      },
      {
        id: 2,
        title: this.$t("currentOrders.view"),
        value: "view",
      },
      {
        id: 3,
        title: this.$t("currentOrders.receiveOrder"),
        value: "receive",
      },
    ];
  },

  methods: {
    validateInput(item) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (
        (!regex.test(item.recommendedQuantity) ||
          item.recommendedQuantity.startsWith(".") ||
          (item.recommendedQuantity.match(/\./g) || []).length > 1) &&
        item.recommendedQuantity !== ""
      ) {
        item.recommendedQuantity = item.recommendedQuantity.slice(0, -1);
      } else {
        this.debouncedApiCall(item);
      }
    },
    saveRecommendedQuantity(item) {
      if (item.recommendedQuantity.slice(-1) === ".") return;
      this.$store
        .dispatch(
          this.isMarket
            ? "articles/updateCustomArticleSettings"
            : "articles/updateArticleSettings",
          {
            articleId: item.id,
            departmentId: this.departmentId,
            recommendedQuantity:
              item.recommendedQuantity === "" ? 0 : item.recommendedQuantity,
          }
        )
        .then(
          () => {
            this.$set(item, "isEditing", false);
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.updatedRecommendedQuantity"),
            });
          },
          (error) => {
            this.$set(item, "isEditing", false);
            console.log("error", error);
          }
        );
    },
    onCancelEditRecommendedQuantity(item) {
      this.$set(item, "isEditing", false);
    },
    receiveOrder(item) {
      this.$emit("onReceiveOrder", item);
    },
    selectRowId(item) {
      this.selectedSupplierId = item.id;
    },
    onFileInputChange(e) {
      let data = { id: this.selectedSupplierId, data: e };
      this.$store.dispatch("supplier/uploadSupplierData", data).then(
        () => {
          EventBus.dispatch("showSnackbar", {
            text: this.$t("notifications.uploadedSupplierData"),
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
        (error) => {
          console.log("upload error", error);
        }
      );
    },

    handleOptionsSelection(option, value) {
      switch (option) {
        case "edit":
          this.$router.push(
            `/${this.$route.params.departmentID}/edit-order/${value.id}`
          );
          break;
        case "delete":
          this.$emit("onDeleteOrder", value.id);
          break;
        case "view":
          this.$emit("onPreviewOrderItem", value);
          break;
        case "receive":
          this.receiveOrder(value);
          break;
      }
    },
    addContact(supplier) {
      this.$emit("onAddContact", supplier);
    },
    previewContacts(supplier) {
      this.$emit("onPreviewContacts", supplier);
    },
    onPriceChangedUp(event, item) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        () => this.doneTyping(event, item),
        this.doneTypingInterval
      );
    },
    onPriceChangedDown() {
      clearTimeout(this.typingTimer);
    },
    doneTyping(event, item) {
      this.$emit("onArticlePriceUpdate", {
        newPrice: event.target.value,
        item,
      });
    },
    getFormattedDate(value) {
      return formatDate(value);
    },
    handlePageChange(event) {
      this.$emit("onPageChange", event);
    },
    removeCustomArticle(item) {
      this.$emit("onArticleDeleteAction", item);
    },
    handleItemsPerPageChange(event) {
      this.$emit("itemsPerPageChange", event);
    },
    handleArticleFavorite(item) {
      this.$emit("onArticleFavorite", item);
      this.$set(item, "isFavorite", !item.isFavorite);
    },
    onEditArticle(item) {
      this.$emit("onEditArticle", item);
    },
  },
  computed: {
    ...mapGetters({
      isUserAdmin: "auth/isUserAdmin",
    }),
    isAdmin() {
      return this.isUserAdmin;
    },
    translatedHeaderOptions() {
      return this.headers.map((header) => ({
        ...header,
        text: header.text ? this.$t(header.text) : "",
      }));
    },
  },
  watch: {
    data() {
      this.totalOptions = this.totalCount;
    },
  },
};
</script>
<style lang="scss" scoped>
.tag-style {
  background: #f7f7f8;
  padding: 8px 4px;
  text-transform: uppercase;
  @media screen and (max-width: 959px) {
    font-size: 14px !important;
  }
}
.remove-uppercase {
  span {
    text-decoration: none !important;
  }
}
.price-input {
  max-width: 130px;
  min-width: 60px;
  position: relative;
  top: 15px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.option-item {
  cursor: pointer;
}
.order-deadline {
  @media screen and (max-width: 959px) {
    font-size: 14px !important;
  }
}
.uploadedYes {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #35d2bc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadedNo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fd4a34;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.recommended-quantity {
  cursor: pointer;
  display: flex;
  width: 100%;
}
.recommmended-quantity-input {
  width: 50px;
  height: 30px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0 5px;
  font-size: 14px;
  text-align: center;
  // margin: 0 auto;
  display: block;
  &:focus {
    outline: none;
  }

  @media screen and (min-width: 959px) {
    width: 120px;
  }
}
</style>

<template>
  <div class="padding-adjust">
    <div class="text-h5 primaryA--text font-weight-bold">
      {{ $t("profilePage.title") }}
    </div>
    <v-layout align-center justify-center class="mt-84 pt-0 pb-0">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-0 ma-auto" width="358px">
          <v-toolbar-title
            class="text-h5 text-sm-h6 text-md-h6 primaryA--text mb-5"
          >
            {{ $t("profilePage.basicConfig") }}
          </v-toolbar-title>
          <div class="locale-changer">
            <v-select
              v-model="selectedLanguage"
              :items="langs"
              :label="$t('profilePage.chooseLanguage')"
              dense
              item-text="label"
              item-value="key"
              @change="handleChangeLanguage"
            >
            </v-select>
          </div>
        </v-card>
        <v-card class="elevation-0 ma-auto" width="358px">
          <v-toolbar-title class="text-h5 text-sm-h6 text-md-h6 primaryA--text">
            {{ $t("profilePage.basicInfo") }}
          </v-toolbar-title>
          <v-card-text class="pa-0 mt-6">
            <v-form ref="form">
              <v-text-field
                name="username"
                :label="$t('common.nameSurname')"
                id="username"
                type="text"
                :placeholder="$t('registerForm.typeYourUsername')"
                outlined
                color="accent"
                required
                :rules="rules.username"
                v-model="form.username"
              ></v-text-field>
              <v-text-field
                name="phone"
                :label="$t('common.phone')"
                id="phone"
                type="text"
                :placeholder="$t('registerForm.phone')"
                outlined
                color="accent"
                required
                :rules="rules.phone"
                v-model="form.phone"
                :prefix="$t('common.phoneCountryPrefix')"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-0 d-flex align-center justify-center mb-32">
            <v-btn color="tertiary" type="submit" @click="cancelEditProfile">{{
              $t("profilePage.cancel")
            }}</v-btn>
            <v-btn color="accent" type="submit" @click="updateUserProfile">{{
              $t("profilePage.saveChanges")
            }}</v-btn>
          </v-card-actions>
          <div class="d-flex justify-center align-center">
            <v-btn color="tertiaryDark" @click="openChangePassword">{{
              $t("profilePage.changePassword")
            }}</v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <change-password-modal
      :dialog="showChangePasswordModal"
      @onClose="closeDialog"
      @onUpdatePassword="handleOnUpdatePassword"
    >
      ></change-password-modal
    >
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//components
import ChangePasswordModal from "./ChangePasswordModal.vue";
export default {
  name: "ProfilePage",
  components: { ChangePasswordModal },
  data() {
    const defaultForm = Object.freeze({
      username: "",
      phone: "",
      theme: "light",
    });
    return {
      form: Object.assign({}, defaultForm),
      rules: {
        username: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        phone: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      timeout: null,
      enableChangePassword: true,
      langs: [
        { id: 0, key: "en", label: this.$t("languages.english") },
        { id: 1, key: "ser", label: this.$t("languages.serbian") },
      ],
      selectedLanguage: "",
      showChangePasswordModal: false,
    };
  },
  created() {
    this.$store.dispatch("userProfile/getUserProfile");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    updateUserProfile() {
      this.form.selectedLanguage =
        typeof this.selectedLanguage === "object"
          ? this.selectedLanguage.key
          : this.selectedLanguage;
      this.$store.dispatch("userProfile/updateUserProfile", this.form).then(
        () => {
          this.$store.dispatch("loader/setLoadingState");
          this.$router.push("/");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
        (error) => {
          this.$store.dispatch("loader/setLoadingState");
          console.log(error);
        }
      );
    },

    handleChangeLanguage(event) {
      this.selectedLanguage = event;
      this.langs = [
        { id: 0, key: "en", label: this.$t("languages.english") },
        { id: 1, key: "ser", label: this.$t("languages.serbian") },
      ];
    },
    openChangePassword() {
      this.showChangePasswordModal = true;
    },
    closeDialog() {
      this.oldPassword = "";
      this.newPassword = "";
      this.repeatNewPassword = "";
      this.rules = [];
      this.showChangePasswordModal = false;
    },
    handleOnUpdatePassword(data) {
      this.$store.dispatch("userProfile/updateUserPassword", data).then(
        () => {
          this.closeDialog();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    cancelEditProfile() {
      this.$router.push("/");
    },
  },
  computed: {
    formIsValid() {
      return this.form.username !== "" && this.form.phone !== "";
    },
    ...mapGetters({
      userProfile: "userProfile/getUserProfile",
    }),
    profile() {
      return this.userProfile;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    isSaveButtonDisabled() {
      return (
        !this.oldPassword ||
        !this.newPassword ||
        !this.repeatNewPassword ||
        this.newPassword !== this.repeatNewPassword
      );
    },
  },
  watch: {
    profile() {
      if (this.profile) {
        // let phone =
        //   this.profile.phone && this.profile.phone[0] === "0"
        //     ? this.profile.phone
        //     : "0" + this.profile.phone;
        this.$set(this.form, "username", this.profile.name);

        // this.$set(this.form, "phone", phone);
        this.$set(this.form, "phone", this.profile.phone);
        this.selectedLanguage = this.langs.filter(
          (f) => f.key === this.profile.language
        )[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 959px) {
  .mt-160 {
    margin-top: 0 !important;
  }
}

.forgot-pass-link {
  cursor: pointer;
}
.text-align-center {
  text-align: center;
}
.forgot-pass-link:hover {
  text-decoration: underline;
}
.register-here-link {
  cursor: pointer;
}
.register-here-link:hover {
  text-decoration: underline;
}
.noAccountTextWrapper {
  position: relative;
}
.noAccountTextWrapper:before {
  position: absolute;
  content: "";
  left: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}
.noAccountTextWrapper:after {
  position: absolute;
  content: "";
  right: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}

.password-fields-warning {
  color: #fd4a34;
  width: 100%;
  text-align: center;
  min-height: 24px;
}
.mt-24 {
  margin-bottom: 24px;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-34 {
  margin-top: 34px;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-84 {
  margin-top: 84px;
}
.mt-160 {
  margin-top: 160px;
}
.mb-118 {
  margin-bottom: 118px !important;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-64 {
  margin-bottom: 64px;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.back-to-login-mobile {
  align-items: center;
  margin-bottom: 42px !important;
  cursor: pointer;
}
.arrow-left-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 40px;
}
.disableChips {
  pointer-events: none;
}
.padding-adjust {
  padding: 0 32px 32px 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
</style>

<template>
  <div class="d-flex flex-column">
    <div>
      <v-alert
        dense
        border="top"
        colored-border
        type="info"
        elevation="2"
        class="alert-info"
      >
        {{ $t("unverifiedCreateArticle.createArticleModalAlertInfo") }}
      </v-alert>
    </div>
    <div class="d-flex align-center flex-wrap">
      <v-card
        v-for="supplier in suppliers"
        :key="supplier.name"
        elevation="2"
        outlined
        class="supplier-card"
      >
        <div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold"
                v-text="supplier.name"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <div>
            <div class="articles-list">
              <v-list-item
                v-for="article in supplier.articles"
                :key="article.id"
                class="d-flex"
              >
                <div
                  v-if="!article.isEdit"
                  class="d-flex justify-between align-center width-100"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="article.articleName"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="d-flex">
                      <v-btn icon @click="onEditArticle(supplier, article)">
                        <v-icon color="primary lighten-1">mdi-pen</v-icon>
                      </v-btn>
                      <v-btn icon @click="onDeleteArticle(supplier, article)">
                        <v-icon color="error lighten-1">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </div>
                <div v-else class="edit-article-row">
                  <div class="d-flex flex-column width-100">
                    <v-text-field
                      full-width
                      class="mr-2"
                      v-model="article.updatedArticleName"
                      :label="$t('dashboard.addArticleName')"
                      dense
                      :rules="rules.articleNameUpdate"
                      type="text"
                      color="accent"
                      requred
                      @keyup="onCheckUpdatedArticleNameUp(supplier, article)"
                      @keydown="onCheckUpdatedArticleNameDown"
                    ></v-text-field>
                    <v-select
                      full-width
                      :label="$t('common.category')"
                      v-model="article.category"
                      :items="allArticlesCategories"
                      item-text="category"
                      item-value="id"
                      dense
                      color="accent"
                      required
                      @change="onArticleCategorySelected(null, article)"
                    ></v-select>
                  </div>
                  <div class="ml-2">
                    <div class="d-flex flex-column align-center">
                      <v-progress-circular
                        v-if="createArticleStatus === 'REQUEST_PENDING'"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-btn
                        v-else
                        color="primary"
                        :disabled="
                          sameArticleNameViolatedForUpdate ||
                          !article.articleName ||
                          !article.category
                        "
                        @click="updateArticle(article, supplier)"
                        >{{ $t("common.save") }}</v-btn
                      >
                      <v-btn
                        class="mt-2"
                        small
                        elevation="2"
                        @click="onCancelUpdateArticle(supplier, article)"
                        >{{ $t("common.cancel") }}</v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-list-item>
            </div>
            <div class="add-new-article-row">
              <div class="d-flex flex-column width-100">
                <v-text-field
                  full-width
                  class="mr-2"
                  v-model="supplier.articleName"
                  :label="$t('dashboard.addArticleName')"
                  dense
                  :rules="rules.articleName"
                  type="text"
                  color="accent"
                  requred
                  @change="onChangeArticleName(supplier)"
                  @keyup="onCheckArticleNameUp(supplier)"
                  @keydown="onCheckArticleNameDown"
                ></v-text-field>
                <v-select
                  full-width
                  :label="$t('common.category')"
                  v-model="supplier.articleSelectedCategory"
                  :items="allArticlesCategories"
                  item-text="category"
                  item-value="id"
                  :rules="rules.articleCategory"
                  dense
                  color="accent"
                  required
                  @change="onArticleCategorySelected(supplier, null)"
                ></v-select>
                <div v-if="$isMobile" class="ml-2">
                  <v-progress-circular
                    v-if="createArticleStatus === 'REQUEST_PENDING'"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn
                    v-else
                    small
                    color="primary"
                    @click="saveSupplerArticle(supplier)"
                    :disabled="
                      sameArticleNameViolated ||
                      !supplier.articleName ||
                      !supplier.articleSelectedCategory
                    "
                    >{{ $t("common.save") }}</v-btn
                  >
                </div>
              </div>
              <div v-if="!$isMobile" class="ml-2 hideOnMobile">
                <v-progress-circular
                  v-if="createArticleStatus === 'REQUEST_PENDING'"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn
                  v-else
                  small
                  color="primary"
                  @click="saveSupplerArticle(supplier)"
                  :disabled="
                    sameArticleNameViolated ||
                    !supplier.articleName ||
                    !supplier.articleSelectedCategory
                  "
                  >{{ $t("common.save") }}</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <simple-warning-modal
      :dialog="showDeleteWarningModal"
      @onClose="showDeleteWarningModal = false"
      @onConfirm="onConfirmDeleteArticle"
      @onCancel="showDeleteWarningModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimpleWarningModal from "../../../components/SimpleWarningModal.vue";

export default {
  name: "WizardStepFourCreateArticles",
  components: {
    SimpleWarningModal,
  },
  data() {
    return {
      typingTimer: null,
      doneTypingInterval: 500,
      rules: {
        articleName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        articleNameUpdate: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        articleCategory: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      articleToDelete: null,
      supplierArticleToDelete: null,
      showDeleteWarningModal: false,
      warningModalTitle: this.$t(
        "adminStations.deleteArticleWarningModalMessage"
      ),
      sameArticleNameViolated: false,
      sameArticleNameViolatedForUpdate: false,
    };
  },
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
      this.$store.dispatch("unverifiedSupplier/getAllArticleCategories");
      this.$store.dispatch(
        "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
      );
    });
    this.$emit("onStepIsValid", 4, true);
  },
  created() {},
  methods: {
    onCheckArticleNameUp(supplier) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkArticleName(supplier),
        this.doneTypingInterval
      );
    },
    onCheckArticleNameDown() {
      clearTimeout(this.typingTimer);
    },
    checkArticleName(supplier) {
      this.sameArticleNameViolated = supplier.articles.some(
        (supplierArticle) => {
          return (
            supplierArticle.articleName.toLowerCase() ===
            supplier.articleName.toLowerCase()
          );
        }
      );
    },
    onCheckUpdatedArticleNameUp(supplier, article) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkUpdatedArticleName(supplier, article),
        this.doneTypingInterval
      );
    },
    onCheckUpdatedArticleNameDown() {
      clearTimeout(this.typingTimer);
    },
    checkUpdatedArticleName(supplier, article) {
      this.sameArticleNameViolatedForUpdate = supplier.articles.some(
        (supplierArticle) => {
          return (
            supplierArticle.articleName.toLowerCase() ===
            article.updatedArticleName.toLowerCase()
          );
        }
      );
    },
    saveSupplerArticle(supplier) {
      const category = this.allArticlesCategories.find(
        (category) => category.id === supplier.articleSelectedCategory
      );

      this.$store
        .dispatch("unverifiedSupplier/createArticleForSupplier", {
          supplierId: supplier.id,
          articleName: supplier.articleName,
          articleCategory: category,
        })
        .then(() => {
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
          this.$set(supplier, "articleName", "");
          this.$set(supplier, "articleSelectedCategory", null);
          this.rules.articleName = [];
          this.rules.articleCategory = [];
        });
    },
    onEditArticle(supplier, article) {
      this.$set(article, "isEdit", true);
      this.$set(article, "fallBackName", article.articleName);
      this.$set(article, "updatedArticleName", article.articleName);
      supplier.articles.forEach((supplierArticle) => {
        if (supplierArticle.id !== article.id) {
          this.$set(supplierArticle, "isEdit", false);
        }
      });
    },
    onCancelUpdateArticle(supplier, article) {
      this.$set(article, "articleName", article.fallBackName);
      this.$set(article, "isEdit", false);
    },
    onDeleteArticle(supplier, article) {
      this.articleToDelete = article;
      this.supplierArticleToDelete = supplier;
      this.showDeleteWarningModal = true;
    },
    onArticleCategorySelected(supplier, article) {
      console.log("Selected category supplier: ", supplier);
      console.log("Selected category article: ", article);
    },
    updateArticle(article, supplier) {
      const category = this.allArticlesCategories.find(
        (category) => category.id === article.category.id
      );
      this.$store
        .dispatch("unverifiedSupplier/updateArticleForSupplier", {
          supplierId: supplier.id,
          articleId: article.id,
          name: article.updatedArticleName,
          category: category,
        })
        .then(() => {
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
          this.$set(article, "isEdit", false);
        });
    },
    isAddNewArticleInputValid(supplier) {
      return supplier.articleName && supplier.articleSelectedCategory;
    },
    onChangeArticleName(supplier) {
      this.$set(supplier, "articleName", supplier.articleName);
    },
    onConfirmDeleteArticle() {
      this.$store
        .dispatch("unverifiedSupplier/deleteSupplierArticle", {
          supplierId: this.supplierArticleToDelete.id,
          articleId: this.articleToDelete.id,
        })
        .then(() => {
          this.showDeleteWarningModal = false;
          this.articleToDelete = null;
          this.supplierArticleToDelete = null;
          this.$store.dispatch(
            "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
          );
        });
    },
  },
  computed: {
    ...mapGetters({
      allUnverifiedSuppliers: "unverifiedSupplier/getUnverifiedSuppliers",
      getAllCategories: "unverifiedSupplier/getAllCategories",
      createArticleStatus:
        "unverifiedSupplier/getCreateArticleForSupplierStatus",
      allDepartmentsWithSuppliers:
        "unverifiedSupplier/getAllDepartmentsWithSuppliers",
    }),
    suppliers() {
      const allSuppliers = this.allDepartmentsWithSuppliers.flatMap(
        (department) => department.suppliers
      );
      const uniqueSuppliersMap = new Map();
      allSuppliers.forEach((supplier) => {
        if (!uniqueSuppliersMap.has(supplier.id)) {
          uniqueSuppliersMap.set(supplier.id, supplier);
        }
      });
      return Array.from(uniqueSuppliersMap.values()).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },

    allArticlesCategories() {
      return this.getAllCategories;
    },
  },
  watch: {
    sameArticleNameViolated() {
      if (this.sameArticleNameViolated) {
        this.rules.articleName = [this.$t("errors.sameArticle")];
      } else {
        this.rules.articleName = [];
      }
    },
    sameArticleNameViolatedForUpdate() {
      if (this.sameArticleNameViolatedForUpdate) {
        this.rules.articleNameUpdate = [this.$t("errors.sameArticle")];
      } else {
        this.rules.articleNameUpdate = [];
      }
    },
  },
};
</script>
<style scoped>
.supplier-card {
  width: 33vw;
  margin-bottom: 24px;
  margin-right: 12px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.add-new-article-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #8b8b8bd0;
  border-radius: 8px;
  margin: 12px;
  padding: 12px;
}

.edit-article-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px;
  width: 100%;
  /* padding: 12px; */
}

.width-100 {
  width: 100%;
}

.articles-list {
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
}

.alert-info {
  white-space: pre-wrap;
  max-width: 33vw;
  @media screen and (max-width: 600px) {
    max-width: 95%;
  }
}
</style>

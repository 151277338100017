import UnverifiedSupplierService from "../services/unverifiedSupplier.service";
import { REQUEST_UNDEFINED } from "../shared/statuses";

const initialState = {
  createUnverifiedSupplierStatus: REQUEST_UNDEFINED,
  createUnverifiedSupplierError: null,

  unverifiedSuppliers: [],
  getUnverifiedSuppliersStatus: REQUEST_UNDEFINED,
  getUnverifiedSuppliersError: null,

  allDepartmentsWithSuppliers: [],
  getUnverifiedSuppliersWithDepartmentsStatus: REQUEST_UNDEFINED,
  getUnverifiedSuppliersWithDepartmentsError: null,

  linkUnverifiedSupplierStatus: REQUEST_UNDEFINED,
  linkUnverifiedSupplierError: null,

  unlinkUnverifiedSupplierStatus: REQUEST_UNDEFINED,
  unlinkUnverifiedSupplierError: null,

  deleteSupplierStatus: REQUEST_UNDEFINED,
  deleteSupplierError: null,

  createArticleForSupplierStatus: REQUEST_UNDEFINED,
  createArticleForSupplierError: null,

  updateArticleForSupplierStatus: REQUEST_UNDEFINED,
  updateArticleForSupplierError: null,

  getAllArticleCategoriesStatus: REQUEST_UNDEFINED,
  getAllArticleCategoriesError: null,
  allArticleCategories: [],

  deleteSupplierArticleStatus: REQUEST_UNDEFINED,
  deleteSupplierArticleError: null,

  finishOnboardingStatus: REQUEST_UNDEFINED,
  finishOnboardingError: null,

  updateSupplierStatus: REQUEST_UNDEFINED,
  updateSupplierError: null,
};

export const unverifiedSupplier = {
  namespaced: true,
  state: initialState,
  actions: {
    createUnverifiedSupplier({ commit }, supplier) {
      commit("createUnverifiedSupplierPending");
      return UnverifiedSupplierService.createUnverifiedSupplier(supplier).then(
        (response) => {
          commit("createUnverifiedSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("createUnverifiedSupplierFailure", error);
          return Promise.reject(error);
        }
      );
    },
    getUnverifiedSuppliers({ commit }) {
      commit("getUnverifiedSuppliersPending");
      return UnverifiedSupplierService.getUnverifiedSuppliers().then(
        (response) => {
          commit("getUnverifiedSuppliersSuccess", response);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getUnverifiedSuppliersFailure", error);
          return Promise.reject(error);
        }
      );
    },
    getUnverifiedSuppliersWithDepartments({ commit }) {
      commit("getUnverifiedSuppliersWithDepartmentsPending");
      return UnverifiedSupplierService.getUnverifiedSuppliersWithDepartments().then(
        (response) => {
          commit("getUnverifiedSuppliersWithDepartmentsSuccess", response);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getUnverifiedSuppliersWithDepartmentsFailure", error);
          return Promise.reject(error);
        }
      );
    },
    linkUnverifiedSupplier({ commit }, payload) {
      commit("linkUnverifiedSupplierPending");
      return UnverifiedSupplierService.linkUnverifiedSupplier(payload).then(
        (response) => {
          commit("linkUnverifiedSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("linkUnverifiedSupplierFailure", error);
          return Promise.reject(error);
        }
      );
    },
    unlinkUnverifiedSupplier({ commit }, payload) {
      commit("unlinkUnverifiedSupplierPending");
      return UnverifiedSupplierService.unlinkUnverifiedSupplier(payload).then(
        (response) => {
          commit("unlinkUnverifiedSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("unlinkUnverifiedSupplierFailure", error);
          return Promise.reject(error);
        }
      );
    },
    deleteSupplier({ commit }, payload) {
      commit("deleteSupplierPending");
      return UnverifiedSupplierService.deleteSupplier(payload).then(
        (response) => {
          commit("deleteSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteSupplierFailure", error);
          return Promise.reject(error);
        }
      );
    },
    createArticleForSupplier({ commit }, payload) {
      commit("createArticleForSupplierPending");
      return UnverifiedSupplierService.createArticleForSupplier(payload).then(
        (response) => {
          commit("createArticleForSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("createArticleForSupplierFailure", error);
          return Promise.reject(error);
        }
      );
    },
    updateArticleForSupplier({ commit }, payload) {
      commit("updateArticleForSupplierPending");
      return UnverifiedSupplierService.updateArticleForSupplier(payload).then(
        (response) => {
          commit("updateArticleForSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateArticleForSupplierFailure", error);
          return Promise.reject(error);
        }
      );
    },
    getAllArticleCategories({ commit }, payload) {
      commit("getAllArticleCategoriesPending");
      return UnverifiedSupplierService.getAllArticleCategories(payload).then(
        (response) => {
          commit("getAllArticleCategoriesSuccess", response);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllArticleCategoriesFailure", error);
          return Promise.reject(error);
        }
      );
    },
    deleteSupplierArticle({ commit }, payload) {
      commit("deleteSupplierArticlePending");
      return UnverifiedSupplierService.deleteSupplierArticle(payload).then(
        (response) => {
          commit("deleteSupplierArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteSupplierArticleFailure", error.response.data.key);
          return Promise.reject(error);
        }
      );
    },
    finishOnboarding({ commit }) {
      commit("finishOnboardingPending");
      return UnverifiedSupplierService.finishOnboarding().then(
        (response) => {
          commit("finishOnboardingSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("finishOnboardingFailure", error);
          return Promise.reject(error);
        }
      );
    },
    updateSupplier({ commit }, payload) {
      commit("updateSupplierPending");
      return UnverifiedSupplierService.updateSupplier(payload).then(
        (response) => {
          commit("updateSupplierSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateSupplierFailure", error);
          return Promise.reject(error);
        }
      );
    },
    clearDeleteArticleError({ commit }) {
      commit("clearDeleteArticleErrorState");
    },
  },
  mutations: {
    createUnverifiedSupplierPending(state) {
      state.createUnverifiedSupplierStatus = "PENDING";
      state.createUnverifiedSupplierError = null;
    },
    createUnverifiedSupplierSuccess(state) {
      state.createUnverifiedSupplierStatus = "SUCCESS";
      state.createUnverifiedSupplierError = null;
    },
    createUnverifiedSupplierFailure(state, error) {
      state.createUnverifiedSupplierStatus = "FAILURE";
      state.createUnverifiedSupplierError = error;
    },

    getUnverifiedSuppliersPending(state) {
      state.getUnverifiedSuppliersStatus = "PENDING";
      state.getUnverifiedSuppliersError = null;
    },
    getUnverifiedSuppliersSuccess(state, payload) {
      state.getUnverifiedSuppliersStatus = "SUCCESS";
      state.getUnverifiedSuppliersError = null;
      state.unverifiedSuppliers = payload.data;
    },
    getUnverifiedSuppliersFailure(state, error) {
      state.getUnverifiedSuppliersStatus = "FAILURE";
      state.getUnverifiedSuppliersError = error;
    },

    linkUnverifiedSupplierPending(state) {
      state.linkUnverifiedSupplierStatus = "PENDING";
      state.linkUnverifiedSupplierError = null;
    },
    linkUnverifiedSupplierSuccess(state) {
      state.linkUnverifiedSupplierStatus = "SUCCESS";
      state.linkUnverifiedSupplierError = null;
    },
    linkUnverifiedSupplierFailure(state, error) {
      state.linkUnverifiedSupplierStatus = "FAILURE";
      state.linkUnverifiedSupplierError = error;
    },

    unlinkUnverifiedSupplierPending(state) {
      state.unlinkUnverifiedSupplierStatus = "PENDING";
      state.unlinkUnverifiedSupplierError = null;
    },
    unlinkUnverifiedSupplierSuccess(state) {
      state.unlinkUnverifiedSupplierStatus = "SUCCESS";
      state.unlinkUnverifiedSupplierError = null;
    },
    unlinkUnverifiedSupplierFailure(state, error) {
      state.unlinkUnverifiedSupplierStatus = "FAILURE";
      state.unlinkUnverifiedSupplierError = error;
    },

    getUnverifiedSuppliersWithDepartmentsPending(state) {
      state.getUnverifiedSuppliersWithDepartmentsStatus = "PENDING";
      state.getUnverifiedSuppliersWithDepartmentsError = null;
    },
    getUnverifiedSuppliersWithDepartmentsSuccess(state, payload) {
      state.getUnverifiedSuppliersWithDepartmentsStatus = "SUCCESS";
      state.getUnverifiedSuppliersWithDepartmentsError = null;
      state.allDepartmentsWithSuppliers = payload.data;
    },
    getUnverifiedSuppliersWithDepartmentsFailure(state, error) {
      state.getUnverifiedSuppliersWithDepartmentsStatus = "FAILURE";
      state.getUnverifiedSuppliersWithDepartmentsError = error;
    },

    deleteSupplierPending(state) {
      state.deleteSupplierStatus = "PENDING";
      state.deleteSupplierError = null;
    },
    deleteSupplierSuccess(state) {
      state.deleteSupplierStatus = "SUCCESS";
      state.deleteSupplierError = null;
    },
    deleteSupplierFailure(state, error) {
      state.deleteSupplierStatus = "FAILURE";
      state.deleteSupplierError = error;
    },

    createArticleForSupplierPending(state) {
      state.createArticleForSupplierStatus = "PENDING";
      state.createArticleForSupplierError = null;
    },
    createArticleForSupplierSuccess(state) {
      state.createArticleForSupplierStatus = "SUCCESS";
      state.createArticleForSupplierError = null;
    },
    createArticleForSupplierFailure(state, error) {
      state.createArticleForSupplierStatus = "FAILURE";
      state.createArticleForSupplierError = error;
    },

    updateArticleForSupplierPending(state) {
      state.updateArticleForSupplierStatus = "PENDING";
      state.updateArticleForSupplierError = null;
    },
    updateArticleForSupplierSuccess(state) {
      state.updateArticleForSupplierStatus = "SUCCESS";
      state.updateArticleForSupplierError = null;
    },
    updateArticleForSupplierFailure(state, error) {
      state.updateArticleForSupplierStatus = "FAILURE";
      state.updateArticleForSupplierError = error;
    },

    getAllArticleCategoriesPending(state) {
      state.getAllArticleCategoriesStatus = "PENDING";
      state.getAllArticleCategoriesError = null;
    },
    getAllArticleCategoriesSuccess(state, payload) {
      state.getAllArticleCategoriesStatus = "SUCCESS";
      state.getAllArticleCategoriesError = null;
      state.allArticleCategories = payload.data;
    },
    getAllArticleCategoriesFailure(state, error) {
      state.getAllArticleCategoriesStatus = "FAILURE";
      state.getAllArticleCategoriesError = error;
    },

    deleteSupplierArticlePending(state) {
      state.deleteSupplierArticleStatus = "PENDING";
      state.deleteSupplierArticleError = null;
    },
    deleteSupplierArticleSuccess(state) {
      state.deleteSupplierArticleStatus = "SUCCESS";
      state.deleteSupplierArticleError = null;
    },
    deleteSupplierArticleFailure(state, error) {
      state.deleteSupplierArticleStatus = "FAILURE";
      state.deleteSupplierArticleError = error;
    },

    finishOnboardingPending(state) {
      state.finishOnboardingStatus = "PENDING";
      state.finishOnboardingError = null;
    },
    finishOnboardingSuccess(state) {
      state.finishOnboardingStatus = "SUCCESS";
      state.finishOnboardingError = null;
    },
    finishOnboardingFailure(state, error) {
      state.finishOnboardingStatus = "FAILURE";
      state.finishOnboardingError = error;
    },

    updateSupplierPending(state) {
      state.updateSupplierStatus = "PENDING";
      state.updateSupplierError = null;
    },
    updateSupplierSuccess(state) {
      state.updateSupplierStatus = "SUCCESS";
      state.updateSupplierError = null;
    },
    updateSupplierFailure(state, error) {
      state.updateSupplierStatus = "FAILURE";
      state.updateSupplierError = error;
    },

    clearDeleteArticleErrorState(state) {
      state.deleteSupplierArticleError = null;
    },
  },
  getters: {
    getUnverifiedSuppliers: (state) => {
      return state.unverifiedSuppliers;
    },
    getUnverifiedSuppliersStatus: (state) => {
      return state.getUnverifiedSuppliersStatus;
    },
    createUnverifiedSupplierStatus: (state) => {
      return state.createUnverifiedSupplierStatus;
    },
    linkUnverifiedSupplierStatus: (state) => {
      return state.linkUnverifiedSupplierStatus;
    },
    unlinkUnverifiedSupplierStatus: (state) => {
      return state.unlinkUnverifiedSupplierStatus;
    },
    getDeleteSupplierStatus: (state) => {
      return state.deleteSupplierStatus;
    },
    getAllDepartmentsWithSuppliers: (state) => {
      return state.allDepartmentsWithSuppliers;
    },
    getCreateArticleForSupplierStatus: (state) => {
      return state.createArticleForSupplierStatus;
    },
    getUpdateArticleForSupplierStatus: (state) => {
      return state.updateArticleForSupplierStatus;
    },
    getAllCategories: (state) => {
      return state.allArticleCategories;
    },
    getDeleteSupplierArticleStatus: (state) => {
      return state.deleteSupplierArticleStatus;
    },
    getFinishOnboardingStatus: (state) => {
      return state.finishOnboardingStatus;
    },
    getUpdateSupplierStatus: (state) => {
      return state.updateSupplierStatus;
    },
    getDeleteSupplierArticleError: (state) => {
      return state.deleteSupplierArticleError;
    },
  },
};

<template>
  <div>
    <v-dialog
      v-model="showDialog"
      :width="modalWidth || '526px'"
      class="modal-dialog-style"
      :class="{ 'full-height-dialog': getIsMobile }"
      :fullscreen="getIsMobile"
      persistent
    >
      <div class="dialog-style">
        <div v-if="!hideClosing" class="header">
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
        <slot />
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ModalComponent",
  props: {
    dialog: {},
    warninModalType: {},
    modalWidth: {},
    hideClosing: {},
    adjustHeight: {},
  },
  data: () => ({
    showDialog: false,
  }),
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
  },
  created() {
    this.showDialog = this.dialog;
  },
  methods: {
    closeDialog() {
      this.$emit("onClose");
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  z-index: 9999;
}
.modal-dialog-style {
  background-color: #ffffff;
  padding: 40px;
  height: 100%;
}
.full-height-dialog .v-dialog__content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}
.full-height-dialog .dialog-style {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.full-height-dialog .content {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.info-text {
  text-align: center;
}
.header {
  position: relative;
  width: 100%;
}
</style>

import { render, staticRenderFns } from "./SimpleWarningModal.vue?vue&type=template&id=cbe6597c&scoped=true&"
import script from "./SimpleWarningModal.vue?vue&type=script&lang=js&"
export * from "./SimpleWarningModal.vue?vue&type=script&lang=js&"
import style0 from "./SimpleWarningModal.vue?vue&type=style&index=0&id=cbe6597c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbe6597c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VDialog,VImg})

<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="626"
      class="dialog-style"
      persistent
      :fullscreen="getIsMobile"
    >
      <div class="dialog-style">
        <div class="header mb-6">
          <div class="d-flex justify-space-between align-center">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("editSupplierModal.title", {
                name: selectedSupplier && selectedSupplier.name,
              })
            }}</span>
            <v-img
              @click="closeDialog"
              class="close-icon"
              max-width="20px"
              :src="require('../assets/img/CloseIcon.svg')"
            >
            </v-img>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <div class="pt-6">
            <v-text-field
              v-model="supplierName"
              :label="$t('editSupplierModal.name')"
              filled
              dense
              :rules="rules.supplierName"
              type="text"
              color="accent"
              requred
              @keyup="onCheckSupplierNameUp"
              @keydown="onCheckSupplierNameDown"
            ></v-text-field>
            <v-text-field
              class="mt-2"
              v-model="supplierPib"
              :label="$t('editSupplierModal.pib')"
              filled
              dense
              :rules="rules.supplierPib"
              :type="getIsMobile ? 'tel' : 'number'"
              color="accent"
              hide-spin-buttons
            ></v-text-field>
            <!-- <v-text-field
              name="supplierAddress"
              id="supplierAddress"
              type="text"
              :placeholder="$t('editSupplierModal.address')"
              outlined
              color="accent"
              required
              v-model="supplierAddress"
            ></v-text-field> -->
            <v-divider></v-divider>
          </div>
        </div>
        <div class="d-flex justify-center mt-6">
          <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
          <v-btn
            :disabled="!isFormValid"
            class="ml-3"
            @click="updateSupplier"
            color="accent"
            >{{ $t("common.save") }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "EditSupplierModal",
  props: { dialog: {}, selectedSupplier: {}, suppliers: {} },
  data() {
    return {
      showDialog: false,
      supplierName: "",
      supplierPib: "",
      supplierAddress: "",
      sameSupplierNameViolated: false,
      //   sameSupplierPibViolated: false,
      rules: {
        supplierName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        supplierPib: [
          (v) =>
            v === "" ||
            (v.length >= 9 && v.length <= 13) ||
            this.$t("common.pibConstraint"),
          (v) =>
            v === "" ||
            !this.isExistingSupplier(v) ||
            this.$t("errors.sameSupplierPib"),
        ],
      },
      typingTimer: null,
      doneTypingInterval: 500,
    };
  },
  created() {
    console.log("jel se zove ovo");
    this.showDialog = this.dialog;
    this.supplierName = this.selectedSupplier.name;
    this.supplierPib = this.selectedSupplier.pib;
    this.supplierAddress = this.selectedSupplier.address;
  },
  mounted() {},
  methods: {
    isExistingSupplier(pib) {
      return this.suppliers.some(
        (supplier) =>
          supplier.pib === pib && supplier.id !== this.selectedSupplier.id
      );
    },
    updateSupplier() {
      const supplier = {
        id: this.selectedSupplier.id,
        name: this.supplierName,
        pib: this.supplierPib,
        address: this.supplierAddress,
      };
      this.$store
        .dispatch("unverifiedSupplier/updateSupplier", supplier)
        .then(() => {
          this.supplierName = "";
          this.supplierPib = "";
          this.supplierAddress = "";
          this.$emit("onClose");
        });
    },
    closeDialog() {
      this.$emit("onClose");
    },
    onCheckSupplierNameUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkSupplierName,
        this.doneTypingInterval
      );
    },
    onCheckSupplierNameDown() {
      clearTimeout(this.typingTimer);
    },

    checkSupplierName() {
      this.sameSupplierNameViolated =
        this.suppliers &&
        this.suppliers.filter(
          (f) => f.name.toLowerCase() === this.supplierName.toLowerCase()
        ).length > 0;
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
    isFormValid() {
      return (
        this.supplierName.length > 0 &&
        this.supplierPib.length >= 9 &&
        this.supplierPib.length <= 13 &&
        !this.sameSupplierNameViolated
        // &&
        // !this.sameSupplierPibViolated
      );
    },
  },
  watch: {
    sameSupplierNameViolated() {
      if (this.sameSupplierNameViolated) {
        this.rules.supplierName = [this.$t("errors.sameSupplier")];
      } else {
        this.rules.supplierName = [];
      }
    },
    sameSupplierPibViolated() {
      if (this.sameSupplierPibViolated) {
        this.rules.supplierPib = [this.$t("errors.sameSupplierPib")];
      } else {
        this.rules.supplierPib = [];
      }
    },
  },
};
</script>
<style scoped>
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
}
.info-text {
  text-align: center;
}
</style>

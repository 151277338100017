<template>
  <div>
    <div v-if="isPendingInvitation" class="d-flex flex-column align-center">
      <span class="text-h5 primaryDark--text">{{
        $t("dashboard.requestNotApproved")
      }}</span>
      <span class="text-subtitle-1 secondaryDark--text">{{
        $t("dashboard.contactYourAdmin")
      }}</span>
    </div>
    <div
      v-else-if="noVenue && getProfileStatus !== 'REQUEST_PENDING'"
      class="pa-8"
    >
      <div class="no-venue-wrap">
        <div class="text-h4 font-weight-bold primaryA--text">
          {{
            venueExists
              ? $t("dashboard.registeredVenueExists")
              : $t("dashboard.noVenue")
          }}
        </div>
        <div class="text-h6 secondaryDark--text">
          {{
            venueExists
              ? $t("dashboard.youCanRequestAccess")
              : $t("dashboard.registerVenue")
          }}
        </div>
        <v-card class="elevation-0 ma-auto" width="358px">
          <v-card-text class="pa-0 mt-6">
            <v-form ref="form">
              <v-text-field
                name="pib"
                :label="$t('common.pib')"
                id="pib"
                type="number"
                :placeholder="$t('registerForm.pib')"
                outlined
                color="accent"
                required
                :rules="rules.pib"
                v-model="form.pib"
                @change="checkIfPibExists"
                :disabled="venueExists"
                hide-spin-buttons
              ></v-text-field>
              <v-text-field
                name="restaurantBusinessName"
                :label="$t('common.restaurantBusinessName')"
                id="restaurantBusinessName"
                type="text"
                :placeholder="$t('registerForm.restaurantBusinessName')"
                outlined
                color="accent"
                required
                :rules="rules.restaurantBusinessName"
                v-model="form.restaurantBusinessName"
                :disabled="venueExists"
              ></v-text-field>
              <v-text-field
                name="restaurantName"
                :label="$t('common.restaurantName')"
                id="restaurantName"
                type="text"
                :placeholder="$t('registerForm.restaurantName')"
                outlined
                color="accent"
                required
                :rules="rules.restaurantName"
                v-model="form.restaurantName"
                :disabled="venueExists"
              ></v-text-field>
              <v-text-field
                name="restaurantAddress"
                :label="$t('common.restaurantAddress')"
                id="restaurantAddress"
                type="text"
                :placeholder="$t('registerForm.restaurantAddress')"
                outlined
                color="accent"
                required
                :rules="rules.restaurantAddress"
                v-model="form.restaurantAddress"
                :disabled="venueExists"
              ></v-text-field>
              <v-text-field
                name="email"
                :label="$t('common.email')"
                id="email"
                type="text"
                :placeholder="$t('registerForm.typeYourEmail')"
                outlined
                color="accent"
                required
                :rules="rules.email"
                v-model="form.email"
                :disabled="venueExists"
              ></v-text-field>
              <v-text-field
                name="phone"
                :label="$t('common.phone')"
                id="phone"
                type="number"
                :placeholder="$t('registerForm.phone')"
                outlined
                color="accent"
                required
                :rules="rules.phone"
                v-model="form.phone"
                :disabled="venueExists"
                :prefix="$t('common.phoneCountryPrefix')"
              ></v-text-field>
              <!-- <v-text-field
                name="supplersWorkingWith"
                :label="$t('registerForm.supplersWorkingWith')"
                id="supplersWorkingWith"
                type="text"
                :placeholder="$t('registerForm.supplersWorkingWith')"
                outlined
                color="accent"
                required
                v-model="form.supplersWorkingWith"
                :hint="$t('registerForm.hintSuppliersWorkingWith')"
              ></v-text-field> -->
            </v-form>

            <v-card-text
              v-if="!venueExists"
              class="font-weight-bold text-h5 primaryA--text pa-0 mt-34 mt-24"
              >{{ $t("registerForm.chooseRestaurantGroup") }}</v-card-text
            >
            <v-chip-group
              v-if="!venueExists"
              class="mb-64"
              active-class="accent--text"
              center-active
              column
              mandatory
              light
              multiple
              v-model="venueIfExists.venueCategories"
              @change="groupSelected"
            >
              <v-chip
                v-for="tag in allCategories"
                :key="tag.id"
                color="secondaryLight"
              >
                <v-card-text class="text-body-2 font-weight-bold">{{
                  tag.name
                }}</v-card-text>
              </v-chip>
            </v-chip-group>
          </v-card-text>
          <v-card-actions class="pa-0 d-block text-align-center mb-32 mt-4">
            <v-btn
              v-if="!venueExists"
              :disabled="!formIsValid"
              color="accent"
              width="100%"
              type="submit"
              @click="registerVenue"
              >{{ $t("dashboard.registerVenueAction") }}</v-btn
            >
            <div v-else class="d-flex justify-center align-center">
              <v-btn color="accent" type="submit" @click="requestAccess">{{
                $t("dashboard.requestAccess")
              }}</v-btn>
              <v-btn class="ml-3" type="submit" @click="cancelRequest">{{
                $t("dashboard.cancelRequest")
              }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <div v-else class="position-relative">
      <div class="restaurant-name-wrap">
        <span class="restaurant-name">{{
          $t("dashboard.restaurant", { name: getVenue && getVenue.name })
        }}</span>
      </div>
      <div class="restaurant-name-wrap-mobile">
        <span class="restaurant-name-mobile">{{
          $t("dashboard.restaurant", { name: getVenue && getVenue.name })
        }}</span>
      </div>
      <div
        v-if="
          stations &&
          stations.length > 0 &&
          (isWizardComplete || !this.getIsUserAdmin)
        "
        class="d-flex pl-8 mt-8 on-mobile"
      >
        <div class="select-wrapper">
          <span class="text-h6 primaryDark--text create-new-order-text"
            >{{ $t("currentOrders.createNewOrder") }}:</span
          >
          <v-select
            class="select-width"
            :items="stationsOptions"
            item-value="id"
            item-text="name"
            outlined
            filled
            color="accent"
            :label="$t('dashboard.chooseStation')"
            persistent-hint
            dense
            @change="onStationSelected"
          ></v-select>
        </div>
      </div>
      <div>
        <div class="mt-64 dashboard-title d-flex align-center">
          <div
            style="width: 100%"
            v-if="
              noStationsCreated || (!isWizardComplete && this.getIsUserAdmin)
            "
          >
            <div v-if="!getIsUserAdmin" class="d-flex flex-column">
              <span
                class="text-h4 font-weight-bold primaryA--text ml-8 small-screen-margin"
                >{{ $t("dashboard.noStations") }}</span
              >
              <span class="text-h5 primaryA--text ml-8 small-screen-margin">{{
                $t("dashboard.contactYourAdmin")
              }}</span>
            </div>
            <div v-else>
              <supplier-creation-wizard></supplier-creation-wizard>
            </div>
          </div>
          <span
            v-else
            class="text-h4 font-weight-bold primaryA--text ml-8 small-screen-margin"
            >{{ $t("dashboard.categories") }}</span
          >
        </div>
        <v-layout v-if="isWizardComplete || !this.getIsUserAdmin">
          <loader-component
            v-if="
              getDepartmentsStatus === 'REQUEST_PENDING' ||
              profileStatus === 'REQUEST_PENDING'
            "
          ></loader-component>
          <div
            v-else
            class="d-flex flex-wrap pl-8 pr-8 p-bottom-80 width-100 padding-mobile"
          >
            <station-card
              v-for="station in stations"
              :station="station"
              :key="station.id"
            ></station-card>
          </div>
        </v-layout>
      </div>
    </div>
    <free-trial-expired-modal
      :dialog="trialPeriodEnded && isFreeSubscription"
      :userProfile="userProfile"
    ></free-trial-expired-modal>
  </div>
</template>

<script>
//vuex
import { mapGetters } from "vuex";
//Components
import StationCard from "../components/StationCard.vue";
import LoaderComponent from "../components/LoaderComponent.vue";
import FreeTrialExpiredModal from "../components/FreeTrialExpiredModal.vue";
import SupplierCreationWizard from "./SupplierCreationWizard/SupplierCreationWizard.vue";
//constants
import { REQUEST_FAILURE, REQUEST_SUCCESS } from "../shared/statuses";
import emailValidator from "../shared/Shared";
//services
import LocalStorageService from "../services/local-storage.service";

export default {
  name: "DashboardPage",
  components: {
    StationCard,
    LoaderComponent,
    FreeTrialExpiredModal,
    SupplierCreationWizard,
  },
  data() {
    const defaultForm = Object.freeze({
      restaurantBusinessName: "",
      pib: "",
      restaurantName: "",
      restaurantAddress: "",
      email: "",
      phone: "",
      restaurantGroups: [],
      supplersWorkingWith: "",
    });
    return {
      form: Object.assign({}, defaultForm),
      rules: {
        restaurantBusinessName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        pib: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        restaurantName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        restaurantAddress: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        email: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        phone: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        restaurantGroups: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      showAddNewStation: false,
      yesterdayDate: null,
      showPassDueWarningModal: false,
      ordersDue: [],
      // showReceiveOrderModal: false,
      stationsOptions: [],
      timeout: null,
      venueExists: false,
      orderToRepeat: null,
      noStationsCreated: false,
      invalidEmail: false,
      invalidPib: false,
    };
  },
  metaInfo() {
    return {
      title: "Dashboard Page",
    };
  },
  mounted() {
    // this.$analytics.logEvent("page_view", { page_title: "Dashboard Page" });
  },
  created() {
    this.setYesterdayDate();
    this.$store.dispatch("auth/getAllCategories");
    this.$store.dispatch("userProfile/getDepartments");
  },
  computed: {
    ...mapGetters({
      userProfile: "userProfile/getUserProfile",
      getStations: "userProfile/getStations",
      getVenue: "userProfile/getVenue",
      categories: "auth/getCategories",
      registerVenueStatus: "auth/getRegisterVenueStatus",
      profileStatus: "userProfile/getUserProfileStatus",
      loadingState: "loader/isLoading",
      getCheckIfPibExistsState: "userProfile/getCheckIfPibExistsState",
      getCheckIfPibExistsStatus: "userProfile/getCheckIfPibExistsStatus",
      getRequestVenueAccessStatus: "userProfile/getRequestVenueAccessStatus",
      isUserAdmin: "auth/isUserAdmin",
      getDepartmentsStatus: "userProfile/getVenueDepartmentsStatus",
      isTrialEnded: "subscription/trialPlanEnded",
      isFreePlan: "subscription/isFreePlan",
      getIsUserProfileAdmin: "userProfile/getIsUserProfileAdmin",
    }),
    profile() {
      return this.userProfile;
    },
    stations() {
      return this.getStations;
    },
    noVenue() {
      return (
        (this.getVenue && Object.keys(this.getVenue).length === 0) ||
        !this.getVenue
      );
    },
    noStations() {
      return (this.stations && this.stations.length === 0) || !this.stations;
    },
    formIsValid() {
      return (
        this.form.restaurantBusinessName &&
        this.form.pib &&
        this.form.restaurantName &&
        this.form.restaurantAddress &&
        this.form.restaurantGroups &&
        this.form.email &&
        this.form.phone &&
        !this.invalidPib &&
        this.invalidEmail
      );
    },
    allCategories() {
      return this.categories;
    },
    addVenueStatus() {
      return this.registerVenueStatus;
    },
    getVenueData() {
      return this.getVenue;
    },
    emailValue() {
      return this.form.email;
    },
    getPibValue() {
      return this.form.pib;
    },
    getProfileStatus() {
      return this.profileStatus;
    },
    getLoadingState() {
      return this.loadingState;
    },
    venueIfExists() {
      return this.getCheckIfPibExistsState;
    },
    venueIfExistsStatus() {
      return this.getCheckIfPibExistsStatus;
    },
    venueAccessRequestStatus() {
      return this.getRequestVenueAccessStatus;
    },
    isPendingInvitation() {
      return (
        this.profile &&
        this.profile.pending_invites &&
        this.profile.pending_invites.length > 0
      );
    },
    getIsUserAdmin() {
      return this.getIsUserProfileAdmin;
    },
    loadingDepartmentsStatus() {
      return this.getDepartmentsStatus;
    },
    trialPeriodEnded() {
      return this.isTrialEnded;
    },
    isFreeSubscription() {
      return this.isFreePlan;
    },
    isWizardComplete() {
      return this.userProfile && this.userProfile.finishedOnboarding;
    },
  },
  methods: {
    // track() {
    //   page("/dashboardPage");
    // },
    setYesterdayDate() {
      const timestamp = new Date().getTime();
      const yesterdayTimeStamp = timestamp - 24 * 60 * 60 * 1000;
      this.yesterdayDate = new Date(yesterdayTimeStamp).toISOString();
    },
    groupSelected(e) {
      let temp = [];
      e.forEach((val) => temp.push(this.allCategories[val]));
      this.form.restaurantGroups = temp;
    },
    registerVenue() {
      this.$store.dispatch("auth/registerVenue", this.form);
    },
    addNewStation() {
      this.showAddNewStation = true;
    },
    handleCancelAddNew() {
      this.showAddNewStation = false;
    },
    handleNewStationCreated() {
      this.showAddNewStation = false;
      window.location.reload();
    },

    onStationSelected(event) {
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      LocalStorageService.deleteLocalStorageItem("orderChanged");
      this.$router.push(`/${event}/new-order`);
    },
    checkIfPibExists() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$store.dispatch(
          "userProfile/checkIfVenuePibExists",
          this.form.pib
        );
      }, 1000);
    },
    requestAccess() {
      this.$store.dispatch("userProfile/requestVenueAccess", this.form.pib);
    },
    cancelRequest() {
      window.location.reload();
    },
    goToStationsCreate() {
      this.$router.push(`/admin/${this.getVenueData.id}/stations`);
    },
  },
  watch: {
    addVenueStatus() {
      if (this.addVenueStatus === REQUEST_SUCCESS) {
        window.location.reload();
      }
    },
    emailValue() {
      this.invalidEmail = emailValidator(this.emailValue);
      this.rules.email =
        this.emailValue.length > 0 && this.invalidEmail
          ? []
          : this.emailValue.length > 0 && !this.invalidEmail
          ? [this.$t("common.invalidEmailInput")]
          : [this.$t("common.requiredField")];
    },
    getPibValue() {
      if (
        this.getPibValue &&
        (this.getPibValue.length < 9 || this.getPibValue.length > 13)
      ) {
        this.rules.pib = [this.$t("common.pibConstraint")];
        this.invalidPib = true;
      } else {
        this.rules.pib = [];
        this.invalidPib = false;
      }
    },
    stations() {
      if (this.stations && this.stations.length > 0) {
        this.stations.filter((f) => this.stationsOptions.push(f));
      }
    },
    venueIfExistsStatus() {
      if (
        this.venueIfExistsStatus === REQUEST_SUCCESS &&
        this.venueIfExists &&
        this.venueIfExists.exists
      ) {
        this.venueExists = true;
        this.form.restaurantBusinessName = this.venueIfExists.bussiness_name;
        this.form.pib = this.venueIfExists.pib;
        this.form.restaurantName = this.venueIfExists.name;
        this.form.restaurantAddress = this.venueIfExists.address;
        this.form.email = this.venueIfExists.email;
        this.form.phone = this.venueIfExists.phoneNumber;
        this.form.restaurantGroups = this.venueIfExists.venueCategories;
      }
    },
    venueAccessRequestStatus() {
      this.venueAccessRequestStatus === REQUEST_SUCCESS &&
        window.location.reload();
    },
    getProfileStatus() {
      if (this.getProfileStatus === REQUEST_FAILURE) {
        this.$store.dispatch("auth/logout");
      }
    },
    getDepartmentsStatus() {
      if (this.getDepartmentsStatus === "REQUEST_SUCCESS") {
        this.noStationsCreated = this.getStations.length === 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-64 {
  margin-top: 64px;
  @media screen and (max-width: 960px) {
    margin-top: 0 !important;
  }
}
.v-calendar-daily__body {
  display: none !important;
}
.calendar-wrap {
  background: #fafafa;
  padding: 32px;
  @media screen and (max-width: 960px) {
    padding: 8px !important;
  }
}
.p-bottom-80 {
  padding-bottom: 80px;
}
.dashboard-title {
  white-space: nowrap;
  margin-left: 14px;
  @media screen and (min-width: 960px) {
    margin-left: 0 !important;
  }
  @media screen and (max-width: 959px) {
    margin-left: 0 !important;
  }
}
.small-screen-margin {
  @media screen and (max-width: 960px) {
    margin-left: 8px !important;
    font-size: 18px !important;
  }
}
.no-venue-wrap {
  text-align: center;
  @media screen and(min-width:1023px) {
    margin: 0 auto;
    padding: 0 10vw;
  }
}
.width-100 {
  width: 100%;
}
.add-new-btn {
  background: #276ef1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.restaurant-name-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 4px;
  @media screen and (max-width: 1023px) {
    display: none;
  }
}
.restaurant-name {
  position: absolute;
  right: 32px;
  font-size: 32px;
  font-weight: 700;
  color: #565961;
  opacity: 0.5;
  @media screen and (max-width: 575px) {
    left: 32px;
  }
}
.restaurant-name-wrap-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}
.restaurant-name-mobile {
  // position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: #565961;
  opacity: 0.7;
  // top: 0;
  // left: 16px;
}
.padding-mobile {
  @media screen and (max-width: 959px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
.select-width {
  max-width: 315px;
  @media screen and (max-width: 575px) {
    max-width: 60%;
  }
}
.logo-cart-img {
  opacity: 0.7;
}
.logo-img-wrapper {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 10px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 11px 4px #616b77b8;
}
.padding-adjust {
  padding: 32px 0 !important;
  @media screen and (max-width: 959px) {
    padding: 64px 0 !important;
  }
}
.select-wrapper {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.on-mobile {
  @media screen and (max-width: 959px) {
    padding-left: 8px !important;
    margin-top: 8px !important;
  }
}
.create-new-order-text {
  font-size: 1.25rem;
  @media screen and (max-width: 575px) {
    font-size: 1.1rem !important;
  }
}
</style>

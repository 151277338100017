import { render, staticRenderFns } from "./ReceiveOrderDialog.vue?vue&type=template&id=2f0b860e&scoped=true&"
import script from "./ReceiveOrderDialog.vue?vue&type=script&lang=js&"
export * from "./ReceiveOrderDialog.vue?vue&type=script&lang=js&"
import style0 from "./ReceiveOrderDialog.vue?vue&type=style&index=0&id=2f0b860e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0b860e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCheckbox,VDialog,VDivider,VIcon,VImg,VRadio,VRadioGroup,VSelect,VTextField,VTextarea})

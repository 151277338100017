import {
  createArticleForSupplierURL,
  createUnverifiedSupplierURL,
  deleteSupplierArticleURL,
  deleteSupplierURL,
  FINISH_ONBOARDING_URL,
  getAllUnverifiedArticleCategoriesURL,
  getUnverifiedSuppliersURL,
  getUnverifiedSuppliersWithDepartmentsURL,
  linkUnverifiedSupplierURL,
  unlinkUnverifiedSupplierURL,
  updateArticleForSupplierURL,
  updateSupplierURL,
} from "../shared/endpoints";
import api from "./api";

class UnverifiedSupplierService {
  createUnverifiedSupplier(supplier) {
    const data = {
      name: supplier.name,
      pib: supplier.pib,
    };
    return api.post(createUnverifiedSupplierURL(), JSON.stringify(data));
  }

  getUnverifiedSuppliers() {
    return api.get(getUnverifiedSuppliersURL());
  }
  getUnverifiedSuppliersWithDepartments() {
    return api.get(getUnverifiedSuppliersWithDepartmentsURL());
  }

  linkUnverifiedSupplier(payload) {
    return api.post(
      linkUnverifiedSupplierURL(payload.supplierId, payload.departmentId)
    );
  }

  unlinkUnverifiedSupplier(payload) {
    return api.post(
      unlinkUnverifiedSupplierURL(payload.supplierId, payload.departmentId)
    );
  }

  deleteSupplier(supplierId) {
    return api.delete(deleteSupplierURL(supplierId));
  }

  createArticleForSupplier(payload) {
    const data = {
      articleName: payload.articleName,
      category: payload.articleCategory,
    };
    return api.post(
      createArticleForSupplierURL(payload.supplierId),
      JSON.stringify(data)
    );
  }
  updateArticleForSupplier(payload) {
    const data = {
      articleName: payload.name,
      category: payload.category,
      id: payload.articleId,
    };

    return api.post(
      updateArticleForSupplierURL(payload.supplierId),
      JSON.stringify(data)
    );
  }
  getAllArticleCategories() {
    return api.get(getAllUnverifiedArticleCategoriesURL());
  }
  finishOnboarding() {
    return api.post(FINISH_ONBOARDING_URL);
  }
  deleteSupplierArticle(payload) {
    return api.delete(deleteSupplierArticleURL(payload.articleId));
  }
  updateSupplier(payload) {
    const data = {
      id: payload.id,
      name: payload.name,
      pib: payload.pib,
    };
    return api.post(updateSupplierURL(), JSON.stringify(data));
  }
}
export default new UnverifiedSupplierService();

<template>
  <v-card class="mb-4 pa-2" elevation="4" outlined>
    <div class="d-flex justify-space-between align-center" style="height: 20px">
      <span class="text-subtitle-2">{{ article && article.name }}</span>
      <v-btn
        icon
        :color="article.isFavorite ? 'accent' : 'secondaryDark'"
        @click="handleArticleFavorite()"
      >
        <v-icon>mdi-star</v-icon>
      </v-btn>
    </div>
    <div>
      <span class="secondaryDark--text">{{ getArticleCategory(article) }}</span>
    </div>
    <div
      class="d-flex justify-space-between align-center"
      :class="[isMarket ? 'mt-4' : 'mt-0']"
    >
      <div class="d-flex align-center">
        <input
          class="recommmended-quantity-input"
          type="text"
          v-model="article.recommendedQuantity"
          @input="validateInput(article)"
          :placeholder="$t('articles.recommendedQuantity')"
          @paste.prevent
          inputmode="decimal"
          pattern="[0-9]*\.?[0-9]*"
          :disabled="!isAdmin"
        />
      </div>

      <div>
        <v-btn
          class="mx-2"
          fab
          dark
          color="accent"
          height="35px"
          width="35px"
          @click="onEditArticle()"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          dark
          color="negative"
          height="35px"
          width="35px"
          @click="removeCustomArticle()"
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex/dist/vuex.common.js";
import _ from "lodash";
import EventBus from "../../../shared/EventBus";

export default {
  name: "ArticleItem",
  props: {
    article: {},
    isMarket: {},
    departmentId: {},
  },
  data() {
    return {};
  },
  created() {
    this.debouncedApiCall = _.debounce(this.saveRecommendedQuantity, 700);
  },
  mounted() {},
  methods: {
    handleArticleFavorite() {
      this.$emit("onArticleFavorite", this.article);
      this.$set(this.article, "isFavorite", !this.article.isFavorite);
    },
    validateInput(item) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (
        (!regex.test(item.recommendedQuantity) ||
          item.recommendedQuantity.startsWith(".") ||
          (item.recommendedQuantity.match(/\./g) || []).length > 1) &&
        item.recommendedQuantity !== ""
      ) {
        item.recommendedQuantity = item.recommendedQuantity.slice(0, -1);
      } else {
        this.debouncedApiCall(item);
      }
    },
    saveRecommendedQuantity(item) {
      if (item.recommendedQuantity.slice(-1) === ".") return;
      this.$store
        .dispatch(
          this.isMarket
            ? "articles/updateCustomArticleSettings"
            : "articles/updateArticleSettings",
          {
            articleId: item.id,
            departmentId: this.departmentId,
            recommendedQuantity:
              item.recommendedQuantity === "" ? 0 : item.recommendedQuantity,
          }
        )
        .then(
          () => {
            this.$set(item, "isEditing", false);
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.updatedRecommendedQuantity"),
            });
          },
          () => {
            this.$set(item, "isEditing", false);
          }
        );
    },
    onEditArticle() {
      this.$emit("onEditArticle", this.article);
    },
    removeCustomArticle() {
      this.$emit("onArticleDeleteAction", this.article);
    },
    getArticleCategory(article) {
      if (!article) return "";
      return article.category && article.category.name
        ? article.category.name
        : article.category;
    },
  },
  computed: {
    ...mapGetters({
      isUserAdmin: "auth/isUserAdmin",
    }),
    isAdmin() {
      return this.isUserAdmin;
    },
  },
};
</script>
<style scoped lang="scss">
.recommmended-quantity-input {
  width: 120px;
  height: 30px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0 5px;
  font-size: 14px;
  text-align: center;
  // margin: 0 auto;
  display: block;
  &:focus {
    outline: none;
  }

  @media screen and (min-width: 959px) {
    width: 120px;
  }
}
</style>

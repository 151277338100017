import { render, staticRenderFns } from "./SupplierCreationWizard.vue?vue&type=template&id=2490897c&scoped=true&"
import script from "./SupplierCreationWizard.vue?vue&type=script&lang=js&"
export * from "./SupplierCreationWizard.vue?vue&type=script&lang=js&"
import style0 from "./SupplierCreationWizard.vue?vue&type=style&index=0&id=2490897c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2490897c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VProgressCircular,VStepper,VStepperContent,VStepperStep})

<template>
  <div class="station-card" :class="{ newCard: newStation }">
    <v-card
      class="elevation-0 card-wrap pa-8 mt-6"
      :class="{ newStationCard: newStation }"
    >
      <div v-if="newStation">
        <v-card-title class="pa-0">{{
          $t("dashboard.creatingNewStation")
        }}</v-card-title>
        <v-text-field
          name="newStationName"
          id="newStationName"
          type="text"
          color="accent"
          requred
          v-model="newStationName"
          :placeholder="$t('dashboard.enterNewStationName')"
          @keyup="onCheckStationNameUp"
          @keydown="onCheckStationNameDown"
          :rules="rules.stationName"
        ></v-text-field>
        <div class="d-flex align-center justify-end">
          <v-btn @click="$emit('onCancelAddNew')">{{
            $t("dashboard.cancelCreateStation")
          }}</v-btn>
          <v-progress-circular
            v-if="createStationStatus === 'REQUEST_PENDING'"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            :disabled="!newStationName || sameStationNameViolated"
            class="ml-2"
            color="accent"
            @click="onCreateNewStation"
            >{{ $t("dashboard.saveStation") }}</v-btn
          >
        </div>
      </div>
      <div class="" v-else-if="editStation">
        <v-text-field
          name="editStationName"
          id="editStationName"
          type="text"
          color="accent"
          requred
          v-model="editStationName"
          @keyup="onCheckEditStationNameUp"
          @keydown="onCheckEditStationNameDown"
          :rules="rules.stationName"
          :label="$t('adminStations.enterNewStationName')"
        ></v-text-field>
        <div class="d-flex justify-end">
          <v-btn @click="onCancelEditStationName">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn
            @click="onUpdateStation"
            color="accent"
            class="ml-3"
            :disabled="!editStationName || sameStationNameViolated"
            >{{ $t("common.save") }}</v-btn
          >
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-center justify-space-between">
          <v-card-title v-if="!editStation" class="pa-0">{{
            station.department.name
          }}</v-card-title>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(opt, i) in optionsItems" :key="i">
                <v-list-item-title
                  class="option-item"
                  @click="handleOptionsSelection(opt.value)"
                  >{{ opt.title }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="order-blocks">
          <div>
            <div>
              <span class="secondaryDark2--text subtitle-1">{{
                $t("adminStations.linkStationAndUser")
              }}</span>

              <v-tooltip
                v-model="showUserInfoAddTooltip"
                top
                open-on-click
                open-on-hover
                max-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="grey lighten-1">
                      mdi-information-slab-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("adminStations.addUsersInfo") }}</span>
              </v-tooltip>
            </div>
            <!-- //users -->
            <v-select
              class="mt-3 mr-3"
              v-model="usersOfStation"
              :items="allUsers"
              :label="$t('adminStations.addedUsers')"
              multiple
              dense
              filled
              chips
              color="accent"
              item-text="name"
              item-value="id"
              @change="addUserToStation"
            ></v-select>
          </div>
          <div>
            <span class="secondaryDark2--text subtitle-1">{{
              $t("adminStations.linkStationAndSupplier")
            }}</span>
            <v-tooltip
              v-model="showAddSupplierInfoTooltip"
              top
              open-on-click
              open-on-hover
              max-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1">
                    mdi-information-slab-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("adminStations.addSuppliersInfo") }}</span>
            </v-tooltip>
            <v-select
              class="mt-3 min-height-auto-complete"
              v-model="suppliersOfStation"
              :items="allVenueSuppliers"
              :label="$t('adminStations.addedSuppliers')"
              multiple
              dense
              filled
              chips
              color="accent"
              item-text="name"
              item-value="id"
              @change="addSupplierToDepartment"
            >
            </v-select>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { REQUEST_SUCCESS } from "../../../shared/statuses";
export default {
  name: "StationCardAdmin",
  props: {
    station: {},
    newStation: {},
    venue: {},
    allUsers: {},
    allVenueSuppliers: {},
  },
  data() {
    return {
      newStationName: "",
      editStationName: "",
      sameStationNameViolated: false,
      rules: {
        stationName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      usersOfStation: [],
      isUpdating: false,
      addedUsers: [],
      optionsItems: [],
      editStation: false,
      typingTimer: null,
      doneTypingInterval: 500,
      addedSuppliersToStation: [],
      departmentId: null,
      suppliersOfStation: [],
      suppliersOfStationCopy: [],
      showUserInfoAddTooltip: false,
      showAddSupplierInfoTooltip: false,
    };
  },
  created() {
    this.optionsItems = [
      {
        id: 0,
        title: this.$t("currentOrders.edit"),
        value: "edit",
      },
      // {
      //   id: 1,
      //   title: this.$t("currentOrders.delete"),
      //   value: "delete",
      // },
    ];
    //   TODO: Api here to get station's users
    // addedUsers
    this.addedUsers = JSON.parse(
      JSON.stringify(this.station && this.station.users)
    );
  },
  mounted() {
    this.editStationName = this.station && this.station.department.name;
    this.departmentId = this.station.department.id;
    this.$store
      .dispatch("supplier/getSuppliersForDepartment", {
        departmentId: this.departmentId,
      })
      .then((response) => {
        const alterResponse = response.data.filter((supplier) => supplier.id);
        this.suppliersOfStation = alterResponse;
        this.suppliersOfStationCopy = JSON.parse(JSON.stringify(alterResponse));
      });
  },
  methods: {
    onCheckStationNameUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkStationNameNew,
        this.doneTypingInterval
      );
    },
    onCheckStationNameDown() {
      clearTimeout(this.typingTimer);
    },
    onCheckEditStationNameUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkStationNameEdit,
        this.doneTypingInterval
      );
    },
    onCheckEditStationNameDown() {
      clearTimeout(this.typingTimer);
    },
    onCreateNewStation() {
      setTimeout(() => {
        if (!this.sameStationNameViolated) {
          this.$emit("onStationCreated", {
            venueID: this.venue && this.venue.id,
            stationName: this.newStationName,
          });
        }
      }, 1000);
    },
    checkStationNameNew() {
      this.sameStationNameViolated =
        this.stations &&
        this.stations.filter(
          (f) =>
            f.department.name.toLowerCase() ===
            this.newStationName.toLowerCase()
        ).length > 0;
    },
    checkStationNameEdit() {
      this.sameStationNameViolated =
        this.stations &&
        this.stations.filter(
          (f) =>
            f.department.name.toLowerCase() ===
            this.editStationName.toLowerCase()
        ).length > 0;
    },
    removeUserFromStation(item) {
      this.usersOfStation = this.usersOfStation.filter((f) => f.id !== item.id);
      this.$emit("onUnlinkUserAndDepartment", {
        userId: item.id,
        departmentId: this.station.department.id,
      });
    },
    addUserToStation(event) {
      const selectedUserId = event[event.length - 1];
      const isUserAdded = this.station.users.some(
        (user) => user.id === selectedUserId
      );
      if (selectedUserId === undefined || isUserAdded) {
        const allUsersIds = this.station.users.map((supplier) => supplier.id);
        let idDifference = allUsersIds.filter((id) => !event.includes(id));
        this.$emit("onUnlinkUserAndDepartment", {
          userId: idDifference[0],
          departmentId: this.station.department.id,
        });
        idDifference = [];
      } else {
        this.$emit("onLinkUserAndDepartment", {
          userId: selectedUserId,
          departmentId: this.departmentId,
        });
      }
    },
    addSupplierToDepartment(event) {
      const selectedSupplierId = event[event.length - 1];
      const isSupplierAdded = this.suppliersOfStationCopy.some(
        (user) => user.id === selectedSupplierId
      );
      if (!selectedSupplierId || isSupplierAdded) {
        const allSupplierIds = this.suppliersOfStationCopy.map(
          (supplier) => supplier.id
        );
        let idDifference = allSupplierIds.filter((id) => !event.includes(id));
        this.$store
          .dispatch("admin/unLinkSupplierAndDepartment", {
            supplierId: idDifference[0],
            departmentId: this.departmentId,
          })
          .then(() => {
            this.$store
              .dispatch("supplier/getSuppliersForDepartment", {
                departmentId: this.departmentId,
              })
              .then((response) => {
                const alterResponse = response.data.filter(
                  (supplier) => supplier.id
                );
                this.suppliersOfStation = alterResponse;
                this.suppliersOfStationCopy = JSON.parse(
                  JSON.stringify(alterResponse)
                );
              });
          });
        idDifference = [];
      } else {
        this.$store
          .dispatch("admin/linkSupplierAndDepartment", {
            supplierId: selectedSupplierId,
            departmentId: this.departmentId,
          })
          .then(() => {
            this.$store
              .dispatch("supplier/getSuppliersForDepartment", {
                departmentId: this.departmentId,
              })
              .then((response) => {
                const alterResponse = response.data.filter(
                  (supplier) => supplier.id
                );
                this.suppliersOfStation = alterResponse;
                this.suppliersOfStationCopy = JSON.parse(
                  JSON.stringify(alterResponse)
                );
              });
          });
      }
    },
    handleOptionsSelection(option) {
      switch (option) {
        case "edit":
          this.editStation = true;
          break;
        case "delete":
          this.$emit("onDeleteStation", this.station.department);
          break;
      }
    },
    onCancelEditStationName() {
      this.editStation = false;
    },
    onUpdateStation() {
      this.$emit("onStationUpdated", {
        departmentId: this.station.department.id,
        editStationName: this.editStationName,
      });
      this.editStation = false;
    },
  },
  computed: {
    ...mapGetters({
      getStationsStatus: "stations/getCreateNewStationStatus",
      allVenueStations: "stations/getAllVenueStations",
      getSuppliers: "supplier/getDepartmentSuppliers",
    }),
    stations() {
      return this.allVenueStations;
    },
    createStationStatus() {
      return this.getStationsStatus;
    },
    suppliers() {
      return this.getSuppliers;
    },
    stationId() {
      return this.station?.id;
    },
  },
  watch: {
    createStationStatus() {
      if (this.createStationStatus === REQUEST_SUCCESS) {
        this.$emit("newStationCreated");
      }
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    addedUsers() {
      this.usersOfStation = this.addedUsers;
    },
    sameStationNameViolated() {
      if (this.sameStationNameViolated) {
        this.rules.stationName = [this.$t("errors.sameStations")];
      } else {
        this.rules.stationName = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card-wrap {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  @media screen and (max-width: 959px) {
    padding: 24px !important;
  }
}
.order-blocks {
  display: flex;
  flex-wrap: wrap;
}
.arrow-img-wrap {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #276ef1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-width {
  width: calc(50% - 24px) !important;
  margin-right: 24px;
  @media screen and (max-width: 959px) {
    margin-right: 0 !important;
  }
}
.block {
  display: flex;
  flex-direction: column;
  background: #f6f7f8;
  border-radius: 4px;
  height: 144px;
  width: 50%;
  padding: 18px 18px 24px 12px;
  @media screen and (min-width: 576px) and (max-width: 959px) {
    width: 295px !important;
  }
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  margin-top: 24px;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 15px 1px #e0e0e0;
  }
}
.block-order-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
}
.station-card {
  width: calc(100% / 2 - 12px);
  min-width: 327px;
  margin-right: 24px;
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and (min-width: 906px) {
    &:nth-child(even) {
      margin-right: 0 !important;
    }
  }
  @media screen and(min-width:960px) and (max-width: 1300px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and (max-width: 959px) {
    margin: auto !important;
    &:nth-child(even) {
      margin-right: auto !important;
    }
  }
}
.newStationCard {
  // min-height: 267px;
  display: flex;
  flex-direction: column;
}
.newCard {
  width: 646px;
  @media screen and (max-width: 575px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media screen and(min-width:960px) and (max-width: 1300px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
.avatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #276ef1;
  color: #ffffff;
  border-radius: 50%;
}
.option-item {
  cursor: pointer;
}
.min-height-auto-complete.v-input__slot {
  min-height: 50px;
}
</style>

<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">{{
            $t("addArticle.title2")
          }}</span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="article-list-wrapper pt-6">
        <v-select
          class="select-width"
          :items="getAllSuppliers"
          :placeholder="$t('unverifiedCreateArticle.firstChooseSupplier')"
          v-model="selectedSupplierId"
          item-text="name"
          item-value="id"
          solo
          required
        ></v-select>

        <div class="d-flex justify-center width-100">
          <v-tabs centered @change="onTabChange" height="30px">
            <v-tab>{{ $t("unverifiedCreateArticle.createArticles") }}</v-tab>
            <v-tab>{{ $t("unverifiedCreateArticle.importArticles") }}</v-tab>
          </v-tabs>
        </div>

        <!-- START Creating of articles -->
        <div v-if="selectedTab === 0">
          <v-alert
            dense
            border="top"
            colored-border
            type="info"
            elevation="2"
            style="text-wrap: pretty"
            class="mt-4"
          >
            {{ $t("unverifiedCreateArticle.createArticleModalAlertInfo") }}
          </v-alert>
          <div v-if="newlyAddedArticles.length" class="new-articles-list">
            <v-data-table
              mobile-breakpoint="400"
              hide-default-footer
              :headers="headers"
              :items="newlyAddedArticles"
              class="elevation-5"
              no-data-text=""
            >
              <template v-slot:item.article_name="{ item }">
                <span v-if="!item.isEdit">{{ item.articleName }}</span>
                <v-text-field
                  v-else
                  dense
                  full-width
                  name="name"
                  id="name"
                  type="text"
                  :placeholder="$t('editArticle.name')"
                  :label="$t('editArticle.name')"
                  color="accent"
                  required
                  :rules="rules.articleName"
                  v-model="item.updatedArticleName"
                  @keyup="onCheckUpdatedArticleNameUp(item)"
                  @keydown="onCheckUpdatedArticleNameDown"
                ></v-text-field>
              </template>
              <template v-slot:item.article_category="{ item }">
                <span v-if="!item.isEdit">
                  {{ item.category && item.category.name }}
                </span>
                <v-select
                  v-else
                  full-width
                  :label="$t('common.category')"
                  v-model="item.category.id"
                  :items="allArticlesCategories"
                  :rules="rules.articleCategory"
                  item-text="category"
                  item-value="id"
                  dense
                  color="accent"
                  required
                ></v-select>
              </template>

              <template v-slot:item.article_actions="{ item }">
                <div v-if="!item.isEdit">
                  <v-btn icon @click="onEditArticle(item)">
                    <v-icon color="primary lighten-1">mdi-pen</v-icon>
                  </v-btn>
                  <v-btn icon @click="onDeleteArticle(item)">
                    <v-icon color="error lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex" v-else>
                  <v-btn icon @click="updateArticle(item)">
                    <v-icon color="primary lighten-1">mdi-check</v-icon>
                  </v-btn>
                  <v-btn icon @click="onCancelUpdateArticle(item)">
                    <v-icon color="error lighten-1">mdi-close</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
          <div class="mt-4 d-flex justify-between new-article-input-wrap">
            <v-text-field
              dense
              name="name"
              id="name"
              type="text"
              :placeholder="$t('editArticle.name')"
              :label="$t('editArticle.name')"
              color="accent"
              required
              :rules="rules.articleName"
              v-model="articleName"
              class="mr-4"
              :disabled="!selectedSupplierId"
              @keyup="onCheckArticleNameUp()"
            ></v-text-field>
            <v-select
              :label="$t('common.category')"
              v-model="selectedCategory"
              :items="allArticlesCategories"
              :rules="rules.articleCategory"
              item-text="category"
              item-value="id"
              dense
              color="accent"
              required
              :disabled="!selectedSupplierId"
            ></v-select>
            <v-btn
              icon
              @click="saveSupplerArticle"
              :disabled="
                !articleName || !selectedCategory || sameArticleNameViolated
              "
            >
              <v-icon color="primary lighten-1">mdi-plus-box</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- END Creating of articles -->

        <!-- START Importing of articles -->
        <import-articles
          v-if="selectedTab === 1"
          :selectedSupplierId="selectedSupplierId"
        ></import-articles>
        <!-- END Importing of articles -->
      </div>

      <div class="d-flex justify-center mt-6">
        <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
      </div>
    </div>
    <simple-warning-modal
      :dialog="showDeleteWarningModal"
      @onClose="showDeleteWarningModal = false"
      @onConfirm="onConfirmDeleteArticle"
      @onCancel="showDeleteWarningModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import SimpleWarningModal from "../../../components/SimpleWarningModal.vue";
import ImportArticles from "./components/ImportArticles.vue";

export default {
  name: "CreateArticleUnverifiedModal",
  components: {
    SimpleWarningModal,
    ImportArticles,
  },
  props: {
    dialog: {},
    supplierId: {},
  },
  data() {
    return {
      showDialog: false,
      rules: {
        articleName: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        articleNameUpdate: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        articleCategory: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      selectedSupplierId: null,
      articleName: "",
      selectedCategory: null,
      newlyAddedArticles: [],
      articleToDelete: null,
      showDeleteWarningModal: false,
      warningModalTitle: this.$t(
        "adminStations.deleteArticleWarningModalMessage"
      ),
      typingTimer: null,
      doneTypingInterval: 500,
      sameArticleNameViolated: false,
      sameArticleNameViolatedForUpdate: false,
      selectedTab: 0,
      headers: [
        {
          text: this.$t("articles.name"),
          value: "article_name",
          sortable: false,
        },
        {
          text: this.$t("articles.category"),
          value: "article_category",
          sortable: false,
        },
        {
          text: this.$t("common.actions"),
          value: "article_actions",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.$store.dispatch("unverifiedSupplier/getUnverifiedSuppliers");
      this.$store.dispatch("unverifiedSupplier/getAllArticleCategories");
      this.$store.dispatch(
        "unverifiedSupplier/getUnverifiedSuppliersWithDepartments"
      );
      this.selectedSupplierId = this.supplierId;
    });
  },
  created() {
    this.showDialog = this.dialog;
  },
  methods: {
    isEditArticleActionDisabled(article) {
      return (
        !article.updatedArticleName ||
        !article.category ||
        this.sameArticleNameViolatedForUpdate
      );
    },
    resetForm() {
      this.selectedSupplierId = null;
      this.selectedCategory = null;
      this.articleName = "";
    },
    closeDialog() {
      this.resetForm();
      this.newlyAddedArticles = [];
      this.$emit("onClose");
    },
    saveSupplerArticle() {
      const category = this.allArticlesCategories.find(
        (category) => category.id === this.selectedCategory
      );
      this.$store
        .dispatch("unverifiedSupplier/createArticleForSupplier", {
          supplierId: this.selectedSupplierId,
          articleName: this.articleName,
          articleCategory: category,
        })
        .then((response) => {
          const { data } = response;
          this.newlyAddedArticles.push(data);
          this.selectedCategory = null;
          this.articleName = "";
          this.rules.articleName = [];
          this.rules.articleCategory = [];
        });
    },
    onEditArticle(article) {
      this.$set(article, "isEdit", true);
      this.$set(article, "fallBackName", article.articleName);
      this.$set(article, "updatedArticleName", article.articleName);
    },
    onDeleteArticle(article) {
      this.articleToDelete = article;
      this.showDeleteWarningModal = true;
    },
    onConfirmDeleteArticle() {
      this.$store
        .dispatch("unverifiedSupplier/deleteSupplierArticle", {
          supplierId: this.selectedSupplierId,
          articleId: this.articleToDelete.id,
        })
        .then(() => {
          this.showDeleteWarningModal = false;

          this.newlyAddedArticles = this.newlyAddedArticles.filter(
            (article) => article.id !== this.articleToDelete.id
          );
          this.articleToDelete = null;
        });
    },
    onCancelUpdateArticle(article) {
      this.$set(article, "isEdit", false);
      this.$set(article, "articleName", article.fallBackName);
    },
    updateArticle(article) {
      const category = this.allArticlesCategories.find(
        (category) => category.id === article.category.id
      );
      this.$store
        .dispatch("unverifiedSupplier/updateArticleForSupplier", {
          supplierId: this.selectedSupplierId,
          articleId: article.id,
          name: article.updatedArticleName,
          category: category,
        })
        .then((response) => {
          const { data } = response;
          const index = this.newlyAddedArticles.findIndex(
            (article) => article.id === data.id
          );
          this.$set(this.newlyAddedArticles, index, data);
          this.$set(article, "isEdit", false);
        });
    },
    onCheckArticleNameUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkArticleName(),
        this.doneTypingInterval
      );
    },
    onCheckArticleNameDown() {
      clearTimeout(this.typingTimer);
    },
    checkArticleName() {
      const supplier = this.suppliersFromDepartmentsUnique.find(
        (supplier) => supplier.id === this.selectedSupplierId
      );
      this.sameArticleNameViolated = supplier?.articles.some(
        (supplierArticle) => {
          return (
            supplierArticle.articleName.toLowerCase() ===
            this.articleName.toLowerCase()
          );
        }
      );
    },
    onCheckUpdatedArticleNameUp(article) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkUpdatedArticleName(article),
        this.doneTypingInterval
      );
    },
    onCheckUpdatedArticleNameDown() {
      clearTimeout(this.typingTimer);
    },
    checkUpdatedArticleName(article) {
      const supplier = this.suppliersFromDepartmentsUnique.find(
        (supplier) => supplier.id === this.selectedSupplierId
      );
      this.sameArticleNameViolatedForUpdate = supplier?.articles.some(
        (supplierArticle) => {
          return (
            supplierArticle.articleName.toLowerCase() ===
            article.updatedArticleName.toLowerCase()
          );
        }
      );
    },
    onTabChange(event) {
      this.selectedTab = event;
    },
  },
  computed: {
    ...mapGetters({
      allUnverifiedSuppliers: "unverifiedSupplier/getUnverifiedSuppliers",
      getAllCategories: "unverifiedSupplier/getAllCategories",
      createArticleStatus:
        "unverifiedSupplier/getCreateArticleForSupplierStatus",
      allDepartmentsWithSuppliers:
        "unverifiedSupplier/getAllDepartmentsWithSuppliers",
    }),
    getAllSuppliers() {
      return [...this.allUnverifiedSuppliers].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    allArticlesCategories() {
      return [...this.getAllCategories].sort((a, b) =>
        a.category.localeCompare(b.category)
      );
    },
    getIsMobile() {
      return this.$isMobile;
    },
    isFormValid() {
      return (
        this.articleName && this.selectedSupplierId && !!this.selectedCategory
      );
    },
    suppliersFromDepartmentsUnique() {
      const allSuppliers = this.allDepartmentsWithSuppliers.flatMap(
        (department) => department.suppliers
      );
      const uniqueSuppliersMap = new Map();
      allSuppliers.forEach((supplier) => {
        if (!uniqueSuppliersMap.has(supplier.id)) {
          uniqueSuppliersMap.set(supplier.id, supplier);
        }
      });
      return Array.from(uniqueSuppliersMap.values()).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    sameArticleNameViolated() {
      if (this.sameArticleNameViolated) {
        this.rules.articleName = [this.$t("errors.sameArticle")];
      } else {
        this.rules.articleName = [];
      }
    },
    sameArticleNameViolatedForUpdate() {
      if (this.sameArticleNameViolatedForUpdate) {
        this.rules.articleNameUpdate = [this.$t("errors.sameArticle")];
      } else {
        this.rules.articleNameUpdate = [];
      }
    },
    supplierId() {
      this.selectedSupplierId = this.supplierId;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.close-icon {
  cursor: pointer;
}
.price-input {
  max-width: 200px;
  min-width: 60px;
  position: relative;
}
.article-list-wrapper {
  min-height: 300px;
}
.new-article-input-wrap {
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 8px;
  margin-top: 12px;
}
.width-100 {
  width: 100%;
}
</style>

<template>
  <v-card class="mb-4 pa-2 pt-4" elevation="4" outlined>
    <div class="d-flex justify-space-between align-center">
      <div style="position: relative" class="d-flex mb-2">
        <!-- <span class="article-name">{{ article.name }}</span> -->
        <div
          style="font-size: 14px; cursor: pointer"
          class="primaryA--text font-mobile"
          :class="[article.isFavorite ? 'font-weight-bold' : '']"
        >
          {{ article.name }}
        </div>
      </div>
      <div class="d-flex align-center">
        <div
          v-if="!article.noteActive"
          class="d-flex flex-column justify-center align-center"
        >
          <input
            solo
            dense
            class="amount-text-field"
            type="text"
            v-model="article.amount"
            @input="validateInput(article)"
            @paste.prevent
            inputmode="decimal"
            pattern="[0-9]*\.?[0-9]*"
            :placeholder="$t('common.qty')"
          />
          <span class="recommended-qty-hint">{{
            article.recommendedQuantity > 0 && isRecommendedQuantityVisible
              ? "*" + article.recommendedQuantity
              : ""
          }}</span>
        </div>
        <v-btn
          v-if="!article.noteActive"
          @click="showItemNote"
          elevation="4"
          icon
          color="primary"
          class="ml-2"
          ><v-icon v-if="article.note" dark> mdi-note-check </v-icon
          ><v-icon v-else dark> mdi-note-text </v-icon></v-btn
        >
        <div v-else style="width: 100%">
          <v-text-field
            solo
            :id="`note-${article.id}`"
            name="note"
            type="text"
            v-model="article.note"
            :label="$t('newOrder.noteLabel')"
            dense
            :append-outer-icon="
              article.note ? 'mdi-content-save' : 'mdi-content-save'
            "
            :append-icon="article.note ? 'mdi-close' : ''"
            @click:append-outer="onCloseEditNote(article)"
            @click:append="onClearItemNote(article)"
            @keyup="onItemNoteChangeUp($event, article)"
            @keydown="onItemNoteChangeDown"
          ></v-text-field>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex justify-space-between align-center">
      
    </div> -->
  </v-card>
</template>
<script>
export default {
  name: "ArticleItem",
  props: {
    article: {},
    isRecommendedQuantityVisible: {},
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    validateInput(article) {
      this.$emit("validateInput", article);
    },
    onClearItemNote(article) {
      this.$emit("onClearItemNote", article);
    },
    onItemNoteChangeUp(event, article) {
      this.$emit("onItemNoteChangeUp", event, article);
    },
    onItemNoteChangeDown() {
      this.$emit("onItemNoteChangeDown");
    },
    showItemNote() {
      this.$emit("onShowItemNote", this.article);
    },
    onCloseEditNote(article) {
      this.$set(article, "noteActive", false);
    },
  },
};
</script>
<style scoped lang="scss">
.amount-text-field {
  outline: none;
  width: 80px;
  border: 1px solid #b2b2b2;
  border-radius: 6px;
  padding: 2px;
  text-align: center;
}

.favorite-star {
  position: absolute;
  right: -15px;
  top: 0;
}

.article-name {
  font-size: 13px;
  line-height: normal;
}
</style>

<template>
  <v-card width="100%" elevation="2" class="pa-6 mt-10 mb-10">
    <div class="">
      <div class="d-flex align-center mb-6">
        <span class="text-h4">{{ $t("adminRestaurant.users") }}</span>
        <div class="add-new-btn ml-4" @click="onAddUser">
          <v-img
            max-width="12"
            :src="require('../../..//assets/img/Plus.svg')"
          ></v-img>
        </div>
      </div>
      <v-data-table
        :headers="translatedHeaderOptions"
        :items="getAllUsers"
        :sort-by="['name']"
        :sort-desc="[false, true]"
        :items-per-page="5"
        class="elevation-1"
        :footer-props="{
          itemsPerPageAllText: $t('common.all'),
          itemsPerPageText: $t('common.itemsPerPage'),
        }"
        :mobile-breakpoint="800"
      >
        <template v-slot:item.phone="{ item }">
          {{
            item.phone && item.phone[0] === "0" ? item.phone : "0" + item.phone
          }}
        </template>
        <template v-slot:item.role="{ item }">
          {{ getUserRoleText(item.role) }}
        </template>
        <template v-slot:item.verified="{ item }">
          {{ item.verified ? $t("common.yes") : $t("common.no") }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div
            class="d-flex align-start flex-column pt-3 pb-3"
            v-if="isSuperAdmin || isAdmin"
          >
            <v-btn
              v-if="
                isRegularUser(item) &&
                item.id !== getCurrentUser.id &&
                !item.disabled
              "
              small
              color="accent"
              @click="onMakeAdminRole(item)"
              >{{ $t("adminRestaurant.makeAdmin") }}</v-btn
            >
            <v-btn
              v-if="
                isUserAdmin(item) &&
                item.id !== getCurrentUser.id &&
                !item.disabled
              "
              small
              color="error"
              @click="onRemoveAdminRole(item)"
              >{{ $t("adminRestaurant.removeAdmin") }}</v-btn
            >
            <v-btn
              v-if="!item.disabled && item.id !== getCurrentUser.id"
              class="ml-3 mt-3"
              small
              @click="onDisableUser(item)"
              >{{ $t("adminRestaurant.disableUser") }}</v-btn
            >
            <v-btn
              v-if="!item.verified && item.id !== getCurrentUser.id"
              class="ml-3 mt-3"
              small
              @click="onVerifyUser(item)"
              >{{ $t("adminRestaurant.verify") }}</v-btn
            >
            <v-btn
              v-if="item.disabled && item.id !== getCurrentUser.id"
              class="ml-3 mt-3"
              small
              @click="onDisableUser(item)"
              >{{ $t("adminRestaurant.enableUser") }}</v-btn
            >
          </div>
        </template>
        <template v-slot:item.emailNotificationsOn="{ item }">
          <div class="d-flex justify-start">
            <v-switch
              class="mt-0"
              :loading="userDisableNotificationsIsLoading"
              v-model="item.emailNotificationsOn"
              @change="onEmailNotificationsChange(item)"
            ></v-switch>
          </div>
        </template>
      </v-data-table>
      <modal-component :dialog="showWarningModal" @onClose="closeWarningModal">
        <div
          v-if="warningtype === 'verify'"
          class="mt-10 d-flex flex-column align-center"
        >
          <span class="text-h6">{{
            $t("adminUsers.verifyConfirm", {
              user: getUserToVerify && getUserToVerify.name,
            })
          }}</span>

          <v-btn class="mt-7" color="accent" @click="handleOnVerify">{{
            $t("adminUsers.confirm")
          }}</v-btn>
        </div>
        <div
          v-if="warningtype === 'make_admin'"
          class="mt-10 d-flex flex-column align-center"
        >
          <span class="text-h6">{{
            $t("adminUsers.makeAdminConfirm", {
              user: getUserToMakeAdmin && getUserToMakeAdmin.name,
              restaurantName: restaurantName,
            })
          }}</span>

          <v-btn class="mt-7" color="accent" @click="handleMakeAdmin">{{
            $t("adminUsers.confirm")
          }}</v-btn>
        </div>
        <div
          v-if="warningtype === 'remove_admin'"
          class="mt-10 d-flex flex-column align-center"
        >
          <span class="text-h6">{{
            $t("adminUsers.removeAdminConfirm", {
              user: getUserToRemoveMakeAdmin && getUserToRemoveMakeAdmin.name,
              restaurantName: restaurantName,
            })
          }}</span>

          <v-btn class="mt-7" color="accent" @click="handleRemoveAdmin">{{
            $t("adminUsers.confirm")
          }}</v-btn>
        </div>
        <div
          v-if="warningtype === 'disable'"
          class="mt-10 d-flex flex-column align-center"
        >
          <span v-if="!getUserToDisable.disabled" class="text-h6">{{
            $t("adminUsers.disableUserConfirm", {
              user: getUserToDisable && getUserToDisable.name,
              restaurantName: restaurantName,
            })
          }}</span>
          <span v-else class="text-h6">{{
            $t("adminUsers.enableUserConfirm", {
              user: getUserToDisable && getUserToDisable.name,
              restaurantName: restaurantName,
            })
          }}</span>

          <v-btn class="mt-7" color="accent" @click="handleDisableUser">{{
            $t("adminUsers.confirm")
          }}</v-btn>
        </div>
        <div v-if="warningtype === 'invite_user'" class="mt-5">
          <span class="text-h6">{{ $t("adminUsers.enterEmailToInvite") }}</span>
          <div>
            <v-text-field
              name="userEmail"
              id="userEmail"
              type="text"
              :label="$t('adminUsers.inviteUserEmail')"
              color="accent"
              v-model="userToInviteEmail"
              @change="handleEmailInviteInput"
              @keyup="onSearchUp"
              @keydown="onSearchDown"
              :rules="rules.email"
            ></v-text-field>
            <v-btn :disabled="!validEmailInvite" @click="sendInvite">{{
              $t("adminUsers.inviteUser")
            }}</v-btn>
          </div>
        </div>
      </modal-component>
    </div>
  </v-card>
</template>
<script>
import ModalComponent from "../../../components/ModalComponent.vue";
//vuex
import { mapGetters } from "vuex";
import { REQUEST_PENDING } from "../../../shared/statuses";

export default {
  name: "UsersCard",
  components: { ModalComponent },
  props: {
    allUsers: {},
    width: {},
    isSuperAdmin: {},
    restaurantName: {},
    isAdmin: {},
    restaurantId: {},
    user: {},
  },
  data: () => ({
    showWarningModal: false,
    userToMakeAdmin: null,
    userToDisable: null,
    userToVerify: null,
    warningtype: "",
    userToInviteEmail: "",
    validEmailInvite: false,
    // eslint-disable-next-line no-useless-escape
    emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    rules: {},
    typingTimer: null,
    doneTypingInterval: 1500,
    headers: [],
    userDisableNotificationsIsLoading: false,
  }),
  mounted() {
    this.rules = {
      email: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
    this.headers = [
      {
        text: "common.roleUser",
        value: "name",
        sortable: false,
      },
      { text: "common.email", value: "email", sortable: false },
      // {
      //   text: this.$t("common.phoneShorter"),
      //   value: "phone",
      //   sortable: false,
      // },
      { text: "common.role", value: "role", sortable: false },
      // { text: this.$t("common.verified"), value: "verified", sortable: false },
      { text: "common.actions", value: "actions", sortable: false },
      {
        text: "adminDashboard.emailNotify",
        value: "emailNotificationsOn",
        sortable: false,
      },
    ];
  },
  created() {},
  methods: {
    onApproveRequestUser() {},
    onMakeAdminRole(user) {
      this.userToMakeAdmin = user;
      this.warningtype = "make_admin";
      this.showWarningModal = true;
    },
    onRemoveAdminRole(user) {
      this.userToRemoveAdmin = user;
      this.warningtype = "remove_admin";
      this.showWarningModal = true;
    },
    onDisableUser(user) {
      this.userToDisable = user;
      this.warningtype = "disable";
      this.showWarningModal = true;
    },
    onVerifyUser(user) {
      this.userToVerify = user;
      this.warningtype = "verify";
      this.showWarningModal = true;
    },
    getUserRoleText(role) {
      switch (role) {
        case "ROLE_USER":
          return this.$t("common.roleUser");
        case "ROLE_SUPERADMIN":
          return this.$t("common.roleSuperAdmin");
        case "ROLE_ADMIN":
          return this.$t("common.roleAdmin");
      }
    },
    closeWarningModal() {
      this.userToMakeAdmin = null;
      this.showWarningModal = false;
    },
    handleMakeAdmin() {
      this.$emit("onMakeAdmin", this.userToMakeAdmin);
      this.showWarningModal = false;
    },
    handleRemoveAdmin() {
      this.$emit("onRemoveAdmin", this.userToRemoveAdmin);
      this.showWarningModal = false;
    },
    handleOnVerify() {
      this.$emit("onVerify", this.userToVerify);
      this.showWarningModal = false;
    },
    handleDisableUser() {
      this.$emit("onDisableUser", this.userToDisable);
      this.showWarningModal = false;
    },
    isRegularUser(user) {
      return user && user.role === "ROLE_USER";
    },
    isUserAdmin(user) {
      return user && user.role === "ROLE_ADMIN";
    },
    onAddUser() {
      this.warningtype = "invite_user";
      this.showWarningModal = true;
    },
    handleEmailInviteInput(event) {
      if (this.emailRegex.test(event)) {
        this.validEmailInvite = true;
      } else {
        this.validEmailInvite = false;
      }
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.handleEmailInviteInput(this.userToInviteEmail),
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    sendInvite() {
      this.$store
        .dispatch("invites/inviteUserToRestaurant", {
          email: this.userToInviteEmail,
          restaurantId: this.restaurantId,
          userId: this.user.id,
        })
        .then(
          () => {
            this.showWarningModal = false;
            this.warningtype = null;
            this.$emit("userInvited");
          },
          (error) => {
            console.log(error);
          }
        );
    },
    onEmailNotificationsChange(user) {
      console.log("user", user);
      this.$store.dispatch("admin/disableEmailNotificationsForUser", user.id);
    },
  },
  computed: {
    ...mapGetters({
      getUser: "auth/getUser",
      userInvitedError: "invites/getInviteUserError",
      disableUserNotificationsStatus:
        "admin/getDisableEmailNotificationsForUserStatus",
    }),
    getAllUsers() {
      let users =
        this.allUsers &&
        this.allUsers.filter((user) => user.role !== "ROLE_SUPERADMIN");
      return users;
    },
    getUserToMakeAdmin() {
      return this.userToMakeAdmin;
    },
    getUserToRemoveMakeAdmin() {
      return this.userToRemoveAdmin;
    },
    getUserToDisable() {
      return this.userToDisable;
    },
    getUserToVerify() {
      return this.userToVerify;
    },
    getCurrentUser() {
      return this.getUser;
    },
    inviteFailStatus() {
      return this.userInvitedError;
    },
    translatedHeaderOptions() {
      return this.headers.map((header) => ({
        ...header,
        text: header.text ? this.$t(header.text) : "",
      }));
    },
  },
  watch: {
    disableUserNotificationsStatus() {
      this.userDisableNotificationsIsLoading =
        this.disableUserNotificationsStatus === REQUEST_PENDING;
    },
    inviteFailStatus() {
      if (this.inviteFailStatus) {
        switch (this.inviteFailStatus.key) {
          case "user_with_that_email_exists":
            this.rules.email = [this.$t("errors." + this.inviteFailStatus.key)];
            break;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.add-new-btn {
  background: #276ef1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
